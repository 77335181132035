import { createContext, useCallback, useMemo, useState } from "react";
import { ConfigProvider as AntdConfigProvider, theme } from "antd";

import { config as baseConfig } from "@/config";
import { useAgent } from "@/hooks/app.hooks";

export type Config = typeof baseConfig;

export interface ConfigContext {
  config: Config;
  setConfig: (config: Partial<Config>) => void;
  isFeatureEnabled: (feature: keyof Config["featureFlags"]) => boolean;
}

export const ConfigContext = createContext<ConfigContext | null>(null);

interface ConfigProviderProps {
  children: React.ReactNode;
}

export function ConfigProvider({ children }: ConfigProviderProps) {
  const configOverride = localStorage.getItem("appConfigOverride");
  const override = configOverride ? JSON.parse(configOverride) : {};
  const breakpoints = useAgent();

  const [config, setConfig] = useState<Config>({
    ...baseConfig,
    ...override,
  });

  const setConfigCb = useCallback(
    (newConfig: Partial<Config>) =>
      setConfig((config) => ({ ...config, ...newConfig })),
    [setConfig]
  );

  const value = useMemo(
    () => ({
      config: config,
      setConfig: setConfigCb,
      isFeatureEnabled: (feature: keyof Config["featureFlags"]) =>
        config.featureFlags[feature] === true,
    }),
    [config, setConfigCb]
  );

  const darkMode = config.theme === "dark";

  return (
    <ConfigContext.Provider value={value}>
      <AntdConfigProvider
        theme={{
          algorithm: [
            darkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
            ...(breakpoints.isMobile ? [theme.compactAlgorithm] : []),
          ],
          components: {
            Layout: {
              headerBg: "var(--ant-color-bg-container)",
              headerHeight: breakpoints.isMobile ? "unset" : 64,
            },
            Drawer: {
              size: config.drawerWidth,
            },
            Card: {
              headerHeight: breakpoints.isMobile ? 60 : 56,
            },
            Spin: {
              dotSize: 100,
              dotSizeSM: 20,
              dotSizeLG: 200,
              colorBgMask: "transparent",
            },
          },
          cssVar: true,
          token: {
            ...(config.brandColor ? { colorPrimary: config.brandColor } : {}),
            controlHeight: breakpoints.isMobile ? 38 : 32,
          },
        }}
        form={{
          requiredMark: "optional",
        }}
      >
        {children}
      </AntdConfigProvider>
    </ConfigContext.Provider>
  );
}
