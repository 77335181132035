import { useContext } from "react";

import { GlobalNotificationContext } from "./GlobalNotificationProvider";

export function useGlobalNotification(): GlobalNotificationContext {
  const globalNotificationContext =
    useContext<GlobalNotificationContext | null>(GlobalNotificationContext);

  if (
    globalNotificationContext === undefined ||
    globalNotificationContext === null
  ) {
    throw new Error(
      "useGlobalNotification must be inside a GlobalNotificationProvider"
    );
  }

  return globalNotificationContext;
}
