import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import duration from "dayjs/plugin/duration";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isToday from "dayjs/plugin/isToday";
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";

import "./app.less";

import { FooterProvider } from "../../../providers/FooterProvider/FooterProvider";
import { ApolloProvider } from "../common/ApolloProvider/ApolloProvider";
import { AppContainer } from "../common/AppContainer/AppContainer";
import { AppUpdateNotification } from "../common/AppUpdateNotification/AppUpdateNotification";
import { GlobalModalProvider } from "../common/GlobalModalProvider/GlobalModalProvider";
import { GlobalNotificationProvider } from "../common/GlobalNotificationProvider/GlobalNotificationProvider";
import { SessionProvider } from "../common/SessionProvider/SessionProvider";
import { TenantProvider } from "../common/TenantProvider/TenantProvider";

import { Router } from "./Router";

import "react-quill/dist/quill.snow.css";

dayjs.extend(isSameOrAfter);
dayjs.extend(weekday);
dayjs.extend(isToday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(localizedFormat);
dayjs.extend(utc);

export const App = () => {
  return (
    <AppContainer>
      <AppUpdateNotification />
      <FooterProvider>
        <GlobalNotificationProvider>
          <GlobalModalProvider>
            <SessionProvider>
              <ApolloProvider>
                <TenantProvider>
                  <Router />
                </TenantProvider>
              </ApolloProvider>
            </SessionProvider>
          </GlobalModalProvider>
        </GlobalNotificationProvider>
      </FooterProvider>
    </AppContainer>
  );
};
