import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { type ButtonProps } from "antd";

import { ResponsiveButton } from "../../ResponsiveButton/ResponsiveButton";
import { useSmartFormInstance } from "../SmartForm/smart-form.hooks";
import type { SmartFormInstance } from "../SmartForm/SmartForm";
import { SmartForm } from "../SmartForm/SmartForm";

export interface SubmitButtonProps extends Omit<ButtonProps, "form"> {
  submittedText?: string | React.ReactNode;
  form?: SmartFormInstance;
}

const getButtonIcon = (form: SmartFormInstance) => {
  if (form.hasSubmitted) {
    return <CheckOutlined />;
  }

  if (form.hasSubmitFailed) {
    return <CloseOutlined />;
  }
};

const getButtonStyle = (form: SmartFormInstance) => {
  if (form.hasSubmitted) {
    return {
      color: "var(--ant-color-success-border)",
      backgroundColor: "var(--ant-color-success-bg)",
      borderColor: "var(--ant-color-success-border)",
    };
  }

  if (form.hasSubmitFailed) {
    return {
      color: "var(--ant-color-error-border)",
      backgroundColor: "var(--ant-color-error-bg)",
      borderColor: "var(--ant-color-error-border)",
    };
  }
};

export const SubmitButton = ({
  children,
  submittedText,
  form,
  ...props
}: SubmitButtonProps) => {
  const instanceForm = useSmartFormInstance();
  const [internalForm] = SmartForm.useForm(form ?? instanceForm ?? undefined);

  const isDirty = internalForm.isDirty;
  const hasErrors = internalForm.hasErrors;

  return (
    <ResponsiveButton
      type="primary"
      htmlType="submit"
      loading={internalForm.isSubmitting}
      disabled={!internalForm.allowPristine && (!isDirty || hasErrors)}
      icon={getButtonIcon(internalForm)}
      style={getButtonStyle(internalForm)}
      onClick={() => internalForm.submit()}
      {...props}
    >
      <span>
        {internalForm.hasSubmitted && submittedText ? submittedText : children}
      </span>
    </ResponsiveButton>
  );
};
