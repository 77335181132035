import type { MouseEventHandler } from "react";
import { Fragment, useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import type { LinkProps, NavigateOptions } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import type { ButtonProps, ModalFuncProps, TooltipProps } from "antd";
import { App, Button } from "antd";

import { useAgent } from "@/hooks/app.hooks";
import { isStringOrReactNode } from "@/utils/types";

import { FitTooltip } from "../FitTooltip/FitTooltip";

export interface FitButtonProps extends ButtonProps {
  navigate?: string | number | (NavigateOptions & { to: string });
  responsive?: boolean;
  confirm?: string | boolean | ModalFuncProps;
  tooltip?: TooltipProps["title"] | TooltipProps;
}

export const FitButton = ({
  navigate,
  responsive,
  confirm,
  tooltip,
  loading,
  ...props
}: FitButtonProps) => {
  const { modal } = App.useApp();
  const location = useLocation();
  const [internalLoading, setInternalLoading] = useState(loading);
  const { isMobile } = useAgent();

  const block = props.block ?? (responsive && isMobile);

  const callback = useCallback<MouseEventHandler<HTMLElement>>(
    async (...args) => {
      setInternalLoading(true);
      await props.onClick?.(...args);
      setInternalLoading(false);
    },
    [props]
  );

  const onClick = useCallback<MouseEventHandler<HTMLElement>>(
    async (...args) => {
      if (confirm) {
        const confirmProps = isStringOrReactNode(confirm)
          ? { title: confirm }
          : typeof confirm === "boolean"
          ? {
              title: <FormattedMessage defaultMessage="Are you sure?" />,
              content: (
                <FormattedMessage defaultMessage="This action cannot be undone." />
              ),
              onOk: undefined,
            }
          : confirm;
        const { onOk, ...modalProps }: ModalFuncProps = confirmProps;
        modal.confirm({
          ...modalProps,
          onOk: async (...confirmArgs) => {
            if (onOk) {
              await onOk(...confirmArgs);
            }
            return callback(...args);
          },
        });
      } else {
        callback(...args);
      }
    },
    [callback, confirm, modal]
  );

  const TooltipComponent = tooltip ? FitTooltip : Fragment;

  let navigateProps: LinkProps | undefined;
  if (typeof navigate === "string") {
    navigateProps = {
      to: navigate.startsWith("/")
        ? navigate
        : `${location.pathname}/${navigate}`,
    };
  } else if (typeof navigate === "number") {
    navigateProps = {
      // @ts-expect-error
      to: navigate,
    };
  } else if (typeof navigate === "object" && navigate.to) {
    navigateProps = navigate;
  }

  const button = (
    <Button
      {...props}
      onClick={onClick}
      loading={internalLoading || loading}
      block={block}
    />
  );

  return (
    <TooltipComponent
      {...(isStringOrReactNode(tooltip) ? { title: tooltip } : tooltip)}
    >
      {navigateProps ? (
        <Link
          {...navigateProps}
          style={{
            lineHeight: 1,
            width: block ? "100%" : undefined,
          }}
        >
          {button}
        </Link>
      ) : (
        button
      )}
    </TooltipComponent>
  );
};
