import { Flex, Spin } from "antd";
import { App as AntdApp } from "antd";

import { Loading } from "@/components/common/Loading/Loading";
import { PreviewBanner } from "@/components/PreviewBanner/PreviewBanner";

import { ConfigProvider } from "../ConfigProvider/ConfigProvider";
import { I18nProvider } from "../I18nProvider/I18nProvider";

import "antd/dist/reset.css";

Spin.setDefaultIndicator(
  <Flex justify="center" align="center">
    <Loading />
  </Flex>
);

export const AppContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <PreviewBanner />
      <ConfigProvider>
        <I18nProvider>
          <AntdApp>{children}</AntdApp>
        </I18nProvider>
      </ConfigProvider>
    </>
  );
};
