import type { ComponentProps } from "react";
import { useEffect, useMemo, useState } from "react";
import { IntlProvider, useIntl } from "react-intl";
import { ConfigProvider } from "antd";
import dayjs from "dayjs";

import "./messages";

import { useConfig } from "../ConfigProvider/hooks";

interface I18nProviderProps {
  children: React.ReactNode;
}

const AntdLocaleProvider = ({ children }: I18nProviderProps) => {
  const intl = useIntl();
  const { config } = useConfig();
  const [antdLocale, setAntdLocale] =
    useState<ComponentProps<typeof ConfigProvider>["locale"]>();

  useEffect(() => {
    async function loadMessages() {
      switch (config.language) {
        case "pt":
          import("antd/lib/locale/pt_PT").then((locale) =>
            setAntdLocale(() => locale.default)
          );
          break;
        default:
          import("antd/lib/locale/en_US").then((locale) =>
            setAntdLocale(() => locale.default)
          );
          break;
      }
    }
    loadMessages();
  }, [config.language]);

  const locale = useMemo(
    () => ({
      ...antdLocale!,
      Form: {
        ...antdLocale?.Form,
        defaultValidateMessages: {
          ...antdLocale?.Form?.defaultValidateMessages,
          required: intl.formatMessage({
            defaultMessage: "This field is required",
          }),
        },
      },
    }),
    [antdLocale, intl]
  );

  return <ConfigProvider locale={locale}>{children}</ConfigProvider>;
};

export const I18nProvider = ({ children }: I18nProviderProps) => {
  const { config } = useConfig();
  const [messages, setMessages] =
    useState<ComponentProps<typeof IntlProvider>["messages"]>();

  useEffect(() => {
    async function loadMessages() {
      switch (config.language) {
        case "pt":
          dayjs.locale("pt");
          setMessages((await import("@/lang/pt_PT.json")).default);
          break;
        default:
          dayjs.locale("en");
          setMessages((await import("@/lang/en.json")).default);
          break;
      }
    }
    loadMessages();
  }, [config.language, config.timezone]);

  return (
    <IntlProvider
      messages={messages}
      locale={config.locale}
      defaultLocale="en"
      onError={(err) => console.debug(err)}
      timeZone={config.timezone}
    >
      <AntdLocaleProvider>{children}</AntdLocaleProvider>
    </IntlProvider>
  );
};
