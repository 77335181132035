import { useContext } from "react";

import { UserContext } from "./UserProvider";

export function useUser(): UserContext {
  const userContext = useContext<UserContext | null>(UserContext);

  if (userContext === null) {
    throw new Error("useUser must be inside a UserProvider");
  }

  return userContext;
}
