import { FormattedMessage } from "react-intl";
import { Result } from "antd";

export const ForbiddenError = () => {
  return (
    <Result
      status="403"
      title={
        <FormattedMessage defaultMessage="You are not authorized to see this page" />
      }
    />
  );
};
