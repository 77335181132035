import { Grid } from "antd";

export const useAgent = () => {
  const screens = Grid.useBreakpoint();

  const isAndroid = /Android/i.test(navigator.userAgent);
  const isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

  const isApp =
    // @ts-expect-error
    navigator.standalone ||
    window.matchMedia("(display-mode: standalone)").matches;

  return {
    ...screens,
    isMobile: !screens.lg,
    isDesktop: screens.lg,
    isAndroid,
    isiOS,
    isApp,
  };
};
