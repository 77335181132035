import { useContext } from "react";
import type { FormInstance } from "antd";
import { Form } from "antd";

import type { SmartFormInstance } from "./SmartForm";
import { SmartFormContext } from "./SmartFormContext";

export const useSmartFormInstance = (): SmartFormContext | null => {
  const smartFormContext = useContext<SmartFormContext | null>(
    SmartFormContext
  );

  return smartFormContext;
};

export const useSmartForm = Form.useForm as (
  form?: SmartFormInstance | FormInstance
) => [SmartFormInstance];
