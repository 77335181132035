import { FaChalkboardTeacher, FaDumbbell } from "react-icons/fa";
import { FaBookBookmark, FaUsersViewfinder } from "react-icons/fa6";
import { MdOutlinePriceChange } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import type { RouteObject } from "react-router-dom";
import { useMatches } from "react-router-dom";
import {
  CodeOutlined,
  EuroOutlined,
  LayoutOutlined,
  ProductOutlined,
  ScheduleOutlined,
  SettingOutlined,
  StarOutlined,
  TeamOutlined,
  TrophyOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";

import { Action, SubjectType } from "@/__generated__/graphql";
import { useAgent } from "@/hooks/app.hooks";
import {
  canClassAdmin,
  canMembersAdmin,
  canWorkoutAdmin,
} from "@/utils/abilities";

import { useTenant } from "../apps/common/TenantProvider/hooks";
import { useUser } from "../apps/common/UserProvider/hooks";
import { FitDrawer } from "../common/FitDrawer/FitDrawer";
import { DevTools } from "../DevTools/DevTools";

import { DesktopMenu } from "./DesktopMenu";
import type { MenuEntry } from "./menu.utils";
import { MobileMenu } from "./MobileMenu";

interface MenuProps {
  onSelect?: () => void;
}

export const Menu = ({ onSelect = () => undefined }: MenuProps) => {
  const { ability } = useUser();
  const { isMobile } = useAgent();
  const { tenant } = useTenant();
  const matches: RouteObject[] = useMatches();
  const selectedKeys = matches.map((match) => match.handle?.selectedMenuKey);

  const menu: MenuEntry[] = [];

  if (ability.can(Action.Read, SubjectType.Class)) {
    menu.push({
      key: "classes",
      link: "/classes",
      icon: <FaChalkboardTeacher />,
      label: <FormattedMessage defaultMessage="Classes" />,
      color: "var(--ant-green-8)",
    });
  }

  if (tenant.hasBookings && ability.can(Action.Read, SubjectType.Space)) {
    menu.push({
      key: "bookings",
      icon: <FaBookBookmark />,
      link: "/bookings",
      label: <FormattedMessage defaultMessage="Bookings" />,
      color: "var(--ant-purple-8)",
    });
  }

  if (ability.can(Action.Read, SubjectType.Workout)) {
    menu.push({
      key: "workouts",
      icon: <FaDumbbell />,
      link: "/workouts",
      label: <FormattedMessage defaultMessage="Workouts" />,
      color: "var(--ant-red-8)",
    });
  }

  if (ability.can(Action.Read, SubjectType.WorkoutScore)) {
    menu.push({
      key: "records",
      icon: <TrophyOutlined />,
      link: "/records",
      label: <FormattedMessage defaultMessage="Records" />,
      color: "var(--ant-orange-8)",
    });
  }

  const adminMenu = [];

  if (canMembersAdmin(ability)) {
    adminMenu.push({
      label: <FormattedMessage defaultMessage="Members" />,
      key: "admin/members",
      icon: <TeamOutlined />,
      color: "var(--ant-geekblue)",
      children: [
        {
          label: <FormattedMessage defaultMessage="List" />,
          key: "admin/members/list",
          icon: <UnorderedListOutlined />,
          link: "admin/members",
        },
        {
          label: <FormattedMessage defaultMessage="Invites" />,
          key: "admin/members/invites",
          icon: <UsergroupAddOutlined />,
          link: "admin/members/invites",
        },
        {
          label: <FormattedMessage defaultMessage="Groups" />,
          key: "admin/members/groups",
          icon: <FaUsersViewfinder />,
          link: "admin/members/groups",
        },
        {
          label: <FormattedMessage defaultMessage="Plans" />,
          key: "admin/plans",
          icon: <MdOutlinePriceChange />,
          link: "admin/plans",
        },
        {
          label: <FormattedMessage defaultMessage="Settings" />,
          key: "admin/members/settings",
          icon: <SettingOutlined />,
          link: "admin/members/settings",
        },
      ],
    });
  }

  if (canClassAdmin(ability)) {
    adminMenu.push({
      label: <FormattedMessage defaultMessage="Classes" />,
      key: "admin/classes",
      icon: <FaChalkboardTeacher />,
      children: [
        {
          label: <FormattedMessage defaultMessage="Schedule" />,
          key: "admin/classes/schedule",
          icon: <ScheduleOutlined />,
          link: "admin/classes/schedule",
        },
        {
          label: <FormattedMessage defaultMessage="Class types" />,
          key: "admin/classes/types",
          icon: <ProductOutlined />,
          link: "admin/classes/types",
        },
        {
          label: <FormattedMessage defaultMessage="Settings" />,
          key: "admin/classes/settings",
          icon: <SettingOutlined />,
          link: "admin/classes/settings",
        },
      ],
    });
  }

  if (canWorkoutAdmin(ability)) {
    adminMenu.push({
      label: <FormattedMessage defaultMessage="Workouts" />,
      key: "admin/workouts",
      icon: <FaDumbbell />,
      children: [
        {
          label: <FormattedMessage defaultMessage="Planning" />,
          key: "admin/workouts/planning",
          icon: <ScheduleOutlined />,
          link: "admin/workouts/planning",
        },
        {
          label: <FormattedMessage defaultMessage="Featured" />,
          key: "admin/workouts/featured",
          icon: <StarOutlined />,
          link: "admin/workouts/featured",
        },
        {
          label: <FormattedMessage defaultMessage="Categories" />,
          key: "admin/workouts/categories",
          icon: <UnorderedListOutlined />,
          link: "admin/workouts/categories",
        },
        {
          label: <FormattedMessage defaultMessage="Score categories" />,
          key: "admin/workouts/score-categories",
          icon: <TrophyOutlined />,
          link: "admin/workouts/score-categories",
        },
        {
          label: <FormattedMessage defaultMessage="Settings" />,
          key: "admin/workouts/settings",
          icon: <SettingOutlined />,
          link: "admin/workouts/settings",
        },
      ],
    });
  }

  if (ability.can(Action.Manage, SubjectType.Space)) {
    adminMenu.push({
      label: <FormattedMessage defaultMessage="Spaces" />,
      key: "admin/spaces",
      icon: <LayoutOutlined />,
      link: "admin/spaces",
    });
  }

  if (ability.can(Action.Manage, SubjectType.Payment)) {
    adminMenu.push({
      label: <FormattedMessage defaultMessage="Finance" />,
      key: "admin/finance",
      icon: <EuroOutlined />,
      children: [
        {
          label: <FormattedMessage defaultMessage="Payments" />,
          key: "admin/finance/payments",
          link: "admin/finance/payments",
        },
        {
          label: <FormattedMessage defaultMessage="Expenses" />,
          key: "admin/finance/expenses",
          link: "admin/finance/expenses",
        },
        {
          label: <FormattedMessage defaultMessage="Direct debits" />,
          key: "admin/finance/direct-debits",
          link: "admin/finance/direct-debits",
        },
        {
          label: <FormattedMessage defaultMessage="Settings" />,
          key: "admin/finance/settings",
          link: "admin/finance/settings",
        },
      ],
    });
  }

  if (ability.can(Action.Manage, SubjectType.Tenant)) {
    adminMenu.push({
      label: <FormattedMessage defaultMessage="Configuration" />,
      key: "admin/tenant",
      icon: <SettingOutlined />,
      children: [
        {
          label: <FormattedMessage defaultMessage="Settings" />,
          key: "admin/tenant/settings",
          link: "admin/tenant/settings",
        },
        {
          label: <FormattedMessage defaultMessage="Forms" />,
          key: "admin/tenant/forms",
          link: "admin/forms",
        },
        {
          disabled: true,
          label: <FormattedMessage defaultMessage="Invoicing" />,
          key: "admin/tenant/invoicing",
        },
        {
          label: <FormattedMessage defaultMessage="Content packs" />,
          key: "admin/content-packs",
          link: "admin/content-packs",
        },
        {
          label: <FormattedMessage defaultMessage="Tenants" />,
          key: "admin/tenants",
          link: "admin/tenants",
        },
      ],
    });
  }

  if (ability.can(Action.Read, SubjectType.Devtools)) {
    adminMenu.push({
      key: "devtools",
      icon: <CodeOutlined />,
      label: (
        <FitDrawer trigger={<FormattedMessage defaultMessage="Devtools" />}>
          <DevTools />
        </FitDrawer>
      ),
      color: "var(--ant-cyan-8)",
    });
  }

  const Component = isMobile ? MobileMenu : DesktopMenu;

  return (
    <Component
      appMenu={menu}
      adminMenu={adminMenu}
      onSelect={onSelect}
      selectedKeys={selectedKeys}
    />
  );
};
