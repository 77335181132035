import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { useInViewport, useUpdate } from "ahooks";

import { useAgent } from "@/hooks/app.hooks";
import { useFooter } from "@/providers/FooterProvider/hooks";

interface FitFooterProps {
  children: React.ReactNode;
  responsive?: boolean;
}

export const FitFooter = ({ children, responsive = true }: FitFooterProps) => {
  const update = useUpdate();
  const ref = useRef<HTMLDivElement>(null);
  const { footerRef, hasFooter, setHasFooter } = useFooter();
  const [inViewport] = useInViewport(ref);
  const { isMobile } = useAgent();

  const isSticky = isMobile || !responsive || !inViewport;

  useEffect(() => {
    setHasFooter(isSticky && Boolean(children));
    return () => setHasFooter(false);
  }, [children, isSticky, setHasFooter]);

  useEffect(() => {
    update();
  }, [hasFooter, update]);

  return (
    <>
      {(!isSticky || !isMobile) && <div ref={ref}>{children}</div>}
      {isSticky &&
        footerRef.current &&
        createPortal(children, footerRef.current, "footer")}
    </>
  );
};
