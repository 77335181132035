import { useContext } from "react";

import { SessionContext } from "./SessionProvider";

export function useSession(): SessionContext {
  const sessionContext = useContext<SessionContext | null>(SessionContext);

  if (sessionContext === null) {
    throw new Error("useSession must be inside a SessionProvider");
  }

  return sessionContext;
}
