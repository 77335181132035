import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Button, notification } from "antd";
import { useRegisterSW } from "virtual:pwa-register/react";

export const AppUpdateNotification = () => {
  const [api, contextHolder] = notification.useNotification();
  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered(r) {
      console.log("SW Registered: " + r);
      r &&
        setInterval(() => {
          r.update();
        }, 1000 * 60 * 60);
    },
    onRegisterError(error) {
      console.log("SW registration error", error);
    },
  });

  useEffect(() => {
    if (needRefresh) {
      api.info({
        message: <FormattedMessage defaultMessage="New update available" />,
        description: (
          <FormattedMessage defaultMessage="Get a better experience with this new app version" />
        ),
        duration: 0,
        btn: (
          <Button type="primary" onClick={() => updateServiceWorker()}>
            <FormattedMessage defaultMessage="Update" />
          </Button>
        ),
        closeIcon: <></>,
      });
      setNeedRefresh(false);
    }
  });

  return contextHolder;
};
