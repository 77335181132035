declare global {
  interface Document {
    APP_INITIALIZED: boolean;
  }
}

import React from "react";
import ReactDOM from "react-dom/client";

import "./datadog";

import { App } from "./components/apps/main/App";

document.APP_INITIALIZED = true;

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
