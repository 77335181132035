import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { BellOutlined, HomeOutlined } from "@ant-design/icons";
import { useLocalStorageState } from "ahooks";
import type { MenuProps } from "antd";
import { Badge, Flex, Image, Menu } from "antd";
import Avatar from "antd/es/avatar/avatar";

import { useTenant } from "../apps/common/TenantProvider/hooks";
import { useUser } from "../apps/common/UserProvider/hooks";
import { FitButton } from "../common/FitButton/FitButton";
import { usePushNotification } from "../common/NotificationProvider/hooks";
import { UserAvatar } from "../common/UserAvatar/UserAvatar";

import { convertMenuEntriesToMenuItems, type MenuEntry } from "./menu.utils";

interface DesktopMenuProps {
  appMenu: MenuEntry[];
  adminMenu: MenuEntry[];
  selectedKeys?: string[];
  onSelect?: () => void;
}

export const DesktopMenu = ({
  appMenu,
  adminMenu,
  onSelect,
  selectedKeys,
}: DesktopMenuProps) => {
  const { user } = useUser();
  const { tenant } = useTenant();
  const { unread } = usePushNotification();
  const [openKeys, setOpenKeys] = useLocalStorageState<string[]>(
    "menu-open-keys",
    {
      defaultValue: [],
    }
  );

  const items: MenuProps["items"] = [
    {
      key: "home",
      label: (
        <Link to={"/"} onClick={onSelect}>
          <Flex align="center">
            <Flex flex={1}>{tenant.name}</Flex>
            {tenant.logoUrl && <Avatar src={tenant.logoUrl} />}
          </Flex>
        </Link>
      ),
      icon: <HomeOutlined />,
    },
    {
      key: "notifications",
      itemIcon: <Badge count={unread} />,
      label: (
        <Link
          to="/notifications"
          style={{ width: "100%", display: "inline-block" }}
        >
          <FormattedMessage defaultMessage="Notifications" />
        </Link>
      ),
      icon: <BellOutlined />,
    },
    {
      type: "divider",
    },
  ];

  items.push(...convertMenuEntriesToMenuItems(appMenu));

  if (adminMenu.length) {
    items.push({
      type: "divider",
    });

    items.push({
      label: <FormattedMessage defaultMessage="Admin" />,
      key: "admin",
      type: "group",
      children: convertMenuEntriesToMenuItems(adminMenu),
    });
  }

  return (
    <Flex
      vertical
      gap="large"
      align="stretch"
      justify="stretch"
      style={{
        height: "100%",
        overflowY: "auto",
        overflowX: "hidden",
        padding: "var(--ant-padding-md) 0",
      }}
    >
      <Image
        src="/logo.png"
        preview={false}
        style={{
          padding: "0 var(--ant-padding-md)",
        }}
      />
      <Flex flex={1}>
        <Menu
          mode="inline"
          defaultOpenKeys={openKeys}
          selectedKeys={selectedKeys}
          items={items}
          onSelect={onSelect}
          onOpenChange={setOpenKeys}
          style={{ borderBottom: 0 }}
        />
      </Flex>
      <Flex
        vertical
        gap="small"
        style={
          {
            //backgroundColor: "var(--ant-color-fill-content)",
          }
        }
      >
        <FitButton
          block
          type="text"
          navigate="/profile"
          style={{
            height: "unset",
            paddingTop: "var(--ant-padding-xs)",
            paddingBottom: "var(--ant-padding-xs)",
            justifyContent: "flex-start",
          }}
        >
          <UserAvatar image={user.avatarUrl} name={user?.name} showName />
        </FitButton>
      </Flex>
    </Flex>
  );
};
