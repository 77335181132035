import { FormattedMessage } from "react-intl";
import { Result } from "antd";

import { FitButton } from "@/components/common/FitButton/FitButton";

export const NotFoundError = () => {
  return (
    <Result
      status="404"
      title={
        <FormattedMessage defaultMessage="The page you tried to open doesn't exist" />
      }
      extra={
        <FitButton type="primary" navigate="/">
          <FormattedMessage defaultMessage="Go to Home" />
        </FitButton>
      }
    />
  );
};
