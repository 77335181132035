import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Flex } from "antd";

import { useAgent } from "@/hooks/app.hooks";

import { FitFooter } from "../../FitFooter/FitFooter";
import { CancelButton } from "../CancelButton/CancelButton";
import { SubmitButton } from "../SubmitButton/SubmitButton";

import type { SmartFormInstance } from "./SmartForm";

export interface SmartFormActionsProps {
  submitText?: string | React.ReactNode;
  submittedText?: string | React.ReactNode;
  cancelText?: string | React.ReactNode;
  submitProps?: React.ComponentProps<typeof SubmitButton>;
  backText?: string | React.ReactNode;
  cancelProps?: React.ComponentProps<typeof CancelButton>;
  backUrl?: string;
  onCancel?: () => void;
  style?: React.CSSProperties;
  form?: SmartFormInstance;
}

export const SmartFormActions = ({
  submitText,
  submittedText,
  submitProps,
  cancelText,
  backText,
  cancelProps,
  backUrl,
  onCancel,
  style,
  form,
}: SmartFormActionsProps) => {
  const breakpoints = useAgent();
  const navigate = useNavigate();

  return (
    <FitFooter>
      <Flex gap="small" vertical={breakpoints.isMobile} style={style}>
        <SubmitButton
          submittedText={
            submittedText ?? <FormattedMessage defaultMessage="Saved" />
          }
          form={form}
          {...submitProps}
        >
          {submitText ?? <FormattedMessage defaultMessage="Save" />}
        </SubmitButton>
        <CancelButton
          submittedText={
            backText ?? <FormattedMessage defaultMessage="Go back" />
          }
          onClick={
            onCancel ? onCancel : backUrl ? () => navigate(backUrl) : undefined
          }
          form={form}
          {...cancelProps}
        >
          {cancelText ?? <FormattedMessage defaultMessage="Cancel" />}
        </CancelButton>
      </Flex>
    </FitFooter>
  );
};
