import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { BellOutlined, FolderOpenOutlined } from "@ant-design/icons";
import { Avatar, Badge, Flex, Image, Menu } from "antd";

import { FitButton } from "../common/FitButton/FitButton";
import { FitDrawer } from "../common/FitDrawer/FitDrawer";
import { usePushNotification } from "../common/NotificationProvider/hooks";

import type { MenuEntry } from "./menu.utils";
import { convertMenuEntriesToMenuItems } from "./menu.utils";

interface MobileMenuProps {
  appMenu: MenuEntry[];
  adminMenu: MenuEntry[];
  selectedKeys: string[];
  onSelect: () => void;
}

export const MobileMenu = ({
  appMenu = [],
  adminMenu = [],
  selectedKeys = [],
  onSelect,
}: MobileMenuProps) => {
  const [isAdminOpen, setIsAdminOpen] = useState(false);
  const { unread } = usePushNotification();

  return (
    <div
      style={{
        position: "relative",
        padding: "var(--ant-padding-md) var(--ant-margin-md)",
      }}
    >
      <Flex
        justify="center"
        style={{ marginBottom: "var(--ant-control-height-lg)" }}
      >
        <Image src="/logo.png" width={150} preview={false} />
      </Flex>
      <div
        style={{
          position: "absolute",
          right: "var(--ant-margin-md)",
          top: "var(--ant-margin-md)",
        }}
      >
        <Badge count={unread} offset={[-5, 5]}>
          <FitButton navigate="/notifications" onClick={onSelect}>
            <BellOutlined style={{ fontSize: 18 }} />
          </FitButton>
        </Badge>
      </div>
      <Flex
        wrap
        justify="space-evenly"
        align="stretch"
        gap={"var(--ant-margin-md)"}
        style={{
          width: "100%",
        }}
      >
        {appMenu.map((item) =>
          item.link ? (
            <Link
              to={item.link}
              onClick={onSelect}
              key={item.key}
              style={{ color: "var(--ant-color-text)" }}
            >
              <Flex vertical align="center" gap="middle">
                <Avatar
                  size="large"
                  icon={item.icon}
                  style={{
                    backgroundColor: item.color,
                    color: "var(--ant-color-bg-base)",
                  }}
                />
                {item.label}
              </Flex>
            </Link>
          ) : (
            <Flex
              vertical
              align="center"
              gap="small"
              onClick={onSelect}
              key={item.key}
            >
              <Avatar
                size="large"
                icon={item.icon}
                style={{
                  backgroundColor: item.color,
                  color: "var(--ant-color-bg-base)",
                }}
              />
              {item.label}
            </Flex>
          )
        )}
        {adminMenu.length > 0 && (
          <>
            <Flex
              vertical
              align="center"
              gap="small"
              onClick={() => setIsAdminOpen(true)}
              style={{ color: "var(--ant-color-text)" }}
            >
              <Avatar
                size="large"
                icon={<FolderOpenOutlined />}
                style={{
                  backgroundColor: "var(--ant-yellow-8)",
                  color: "var(--ant-color-bg-base)",
                }}
              />
              <FormattedMessage defaultMessage="Admin" />
            </Flex>
            <FitDrawer
              title={<FormattedMessage defaultMessage="Admin" />}
              open={isAdminOpen}
              onClose={() => setIsAdminOpen(false)}
              styles={{
                body: { padding: 0 },
              }}
            >
              <Menu
                mode="inline"
                selectedKeys={selectedKeys}
                defaultOpenKeys={adminMenu.map((item) => item.key)}
                items={convertMenuEntriesToMenuItems(adminMenu)}
                onSelect={() => {
                  setIsAdminOpen(false);
                  onSelect();
                }}
              />
            </FitDrawer>
          </>
        )}
      </Flex>
    </div>
  );
};
