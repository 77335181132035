export const config = {
  brand: {
    name: "Fitrack",
  },
  brandColor: "#1677ff",
  api: {
    url: import.meta.env.VITE_API_URL,
    authUrl: import.meta.env.VITE_AUTH_API_URL,
  },
  graphql: {
    endpoint: import.meta.env.VITE_GRAPHQL_ENDPOINT,
  },
  assets: {
    url: "https://static.fitrack.app",
  },
  defaultTenant: "fitrack",
  language: "pt",
  locale: "pt-PT",
  theme: "light",
  drawerWidth: 250,
  notifications: {
    unreadPollingInterval: 1000 * 60 * 5,
    deviceTokenPollingInterval: 1000 * 60 * 60 * 24 * 7,
  },
  featureFlags: {
    spaces: true,
    events: true,
  },
  timezone: "Europe/Lisbon",
  paymentSandbox: false,
  banana: "",
} satisfies {
  [key: string]: unknown;
};
