import { Card, ConfigProvider, Flex, Grid, theme } from "antd";

import { useAgent } from "@/hooks/app.hooks";

interface AuthLayoutProps {
  children?: React.ReactNode;
}
export const AuthLayout = ({ children }: AuthLayoutProps) => {
  const { token } = theme.useToken();
  const breakpoints = useAgent();
  const screens = Grid.useBreakpoint();

  return (
    <ConfigProvider
      theme={{
        algorithm: [
          theme.defaultAlgorithm,
          ...(breakpoints.isMobile ? [theme.compactAlgorithm] : []),
        ],
      }}
    >
      <video
        muted
        loop
        autoPlay
        playsInline
        id="myVideo"
        style={{
          position: "fixed",
          right: 0,
          bottom: 0,
          minWidth: "100%",
          minHeight: "100%",
          zIndex: 10,
        }}
      >
        <source src="/auth_splash_video.mp4" type="video/mp4" />
      </video>
      <Flex
        align="center"
        justify={breakpoints.isDesktop ? "top" : "center"}
        vertical
        style={{
          height: "100vh",
          zIndex: 1000,
          paddingTop: breakpoints.isDesktop ? "20vh" : 0,
          position: "relative",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <Card
          style={{
            width: screens.lg ? token.screenXSMin : "100%",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
          }}
          type="inner"
        >
          <Flex
            gap="small"
            align="center"
            justify="center"
            style={{ margin: token.marginXL }}
          >
            <img src="/logo.png" alt="Fitrack" style={{ width: "70%" }} />
          </Flex>
          {children}
        </Card>
      </Flex>
    </ConfigProvider>
  );
};
