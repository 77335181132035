import type { ReactNode } from "react";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useBlocker } from "react-router-dom";
import { useLockFn, useMount, useUpdate } from "ahooks";
import type { FormInstance, FormProps } from "antd";
import { Form, Modal } from "antd";

import { useGlobalNotification } from "@/components/apps/common/GlobalNotificationProvider/hooks";
import { formExceptionHandler } from "@/utils/form";

import { SmartFormItem } from "../SmartFormItem/SmartFormItem";

import { useSmartForm } from "./smart-form.hooks";
import { SmartFormActions } from "./SmartFormActions";
import { SmartFormProvider } from "./SmartFormContext";

export interface SmartFormProps<Values = unknown> extends FormProps<Values> {
  children?: ReactNode;
  form?: SmartFormInstance | FormInstance;
  onFinish?: (values: Values) => Promise<unknown> | void;
  onSubmitted?: () => void;
  allowPristine?: boolean;
  autoErrorHandler?: boolean;
  confirmOnLeave?: boolean;
}

export interface SmartFormInstance extends FormInstance {
  isDirty: boolean;
  isSubmitting: boolean;
  allowPristine: boolean;
  hasSubmitted: boolean;
  hasSubmitFailed: boolean;
  hasErrors: boolean;
  leaveConfirm: () => Promise<boolean | undefined>;
}

export const SmartForm = <Values,>({
  children,
  onFinish,
  onFinishFailed,
  onValuesChange,
  onSubmitted,
  form,
  allowPristine = false,
  autoErrorHandler = true,
  confirmOnLeave = true,
  ...props
}: SmartFormProps<Values>) => {
  const notification = useGlobalNotification();
  const [isDirty, setIsDirty] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [hasSubmitFailed, setHasSubmitFailed] = useState(false);
  const [isLeaveConfirming, setIsLeaveConfirming] = useState(false);
  const [internalForm] = useSmartForm(form);
  const [modal, contextHolder] = Modal.useModal();
  const blocker = useBlocker(() => isDirty && !isSubmitting);
  const update = useUpdate();
  const resetFields = internalForm.resetFields;

  const hasErrors = internalForm
    .getFieldsError()
    .some(({ errors }) => errors.length > 0);

  // this is needed to reset the form when the component is mounted inside a modal
  useMount(() => {
    resetFields();
  });

  const smartOnFinish = useCallback(
    async (values: Values) => {
      if (!allowPristine && (isSubmitting || !isDirty)) {
        return;
      }

      setIsSubmitting(true);
      try {
        await onFinish?.(values);
        setHasSubmitted(true);
        onSubmitted?.();
      } catch (error) {
        setHasSubmitFailed(true);
        if (onFinishFailed) {
          onFinishFailed({
            errorFields: [],
            outOfDate: false,
            values,
          });
        } else if (error instanceof Error && autoErrorHandler) {
          formExceptionHandler(error, internalForm, notification);
        }
      } finally {
        form?.setFields(
          Object.entries(form?.getFieldsValue() ?? {}).map(([key, value]) => ({
            name: [key],
            value,
            touched: false,
          }))
        );
        setIsSubmitting(false);
        setIsDirty(false);
      }
    },
    [
      allowPristine,
      autoErrorHandler,
      form,
      internalForm,
      isDirty,
      isSubmitting,
      notification,
      onFinish,
      onFinishFailed,
      onSubmitted,
    ]
  );

  const internalOnValuesChange = useCallback(
    (changedValues: unknown, allValues: Values) => {
      update();
      setHasSubmitFailed(false);
      setHasSubmitted(false);
      setIsDirty(true);
      onValuesChange?.(changedValues, allValues);
    },
    [onValuesChange, update]
  );

  const leaveConfirm = useLockFn(async () => {
    return new Promise<boolean>((resolve) => {
      if (!confirmOnLeave) {
        blocker.proceed?.();
        resolve(true);
        return;
      }
      modal
        .confirm({
          title: <FormattedMessage defaultMessage="Are you sure?" />,
          content: (
            <FormattedMessage defaultMessage="Your changes will be lost" />
          ),
          onOk: () => {
            blocker.proceed?.();
          },
          afterClose: () => {
            blocker.reset?.();
          },
          closable: true,
          maskClosable: true,
        })
        .then(
          (confirmed) => {
            resolve(confirmed);
          },
          () => resolve(false)
        );
    });
  });

  useEffect(() => {
    if (blocker.state === "blocked" && confirmOnLeave) {
      setIsLeaveConfirming(true);
    } else {
      blocker.reset?.();
    }
  }, [blocker, blocker.state, confirmOnLeave]);

  internalForm.isDirty = isDirty;
  internalForm.isSubmitting = isSubmitting;
  internalForm.leaveConfirm = leaveConfirm;
  internalForm.hasSubmitted = hasSubmitted;
  internalForm.resetFields = useCallback(
    (fields) => {
      resetFields(fields);
      setIsDirty(false);
      setHasSubmitted(false);
      setHasSubmitFailed(false);
    },
    [resetFields]
  );

  return (
    <>
      <Modal
        title={<FormattedMessage defaultMessage="Are you sure?" />}
        open={isLeaveConfirming}
        closable
        maskClosable
        onOk={() => {
          blocker.proceed?.();
          return setIsLeaveConfirming(false);
        }}
        onCancel={() => setIsLeaveConfirming(false)}
      >
        <FormattedMessage defaultMessage="Your changes will be lost" />
      </Modal>
      <Form
        {...props}
        form={internalForm}
        layout="vertical"
        onFinish={smartOnFinish}
        validateTrigger={["onBlur", "onSubmit", "onChange"]}
        onValuesChange={internalOnValuesChange}
        scrollToFirstError
        onSubmitCapture={(event) => {
          event.preventDefault();
        }}
      >
        {contextHolder}
        <SmartFormProvider
          form={internalForm}
          hasSubmitted={hasSubmitted}
          hasSubmitFailed={hasSubmitFailed}
          isSubmitting={isSubmitting}
          isDirty={isDirty}
          hasErrors={hasErrors}
          allowPristine={allowPristine}
        >
          {children}
        </SmartFormProvider>
      </Form>
    </>
  );
};

SmartForm.Actions = SmartFormActions;
SmartForm.useForm = useSmartForm;
SmartForm.Item = SmartFormItem;
