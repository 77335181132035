import { createContext, useMemo } from "react";
import { Modal } from "antd";

import type { FormModalApi } from "@/hooks/form.hooks";
import { useFormModal } from "@/hooks/form.hooks";

export type GlobalModalContext = ReturnType<(typeof Modal)["useModal"]>[0] & {
  form: FormModalApi["show"];
};

export const GlobalModalContext = createContext<GlobalModalContext | null>(
  null
);

interface GlobalModalProviderProps {
  children: React.ReactNode;
}

export function GlobalModalProvider({ children }: GlobalModalProviderProps) {
  const [api, contextHolder] = Modal.useModal();
  const [formApi, formContextHolder] = useFormModal();

  const apiWithForm = useMemo(
    () => ({
      ...api,
      form: formApi.show,
    }),
    [api, formApi.show]
  );

  return (
    <GlobalModalContext.Provider value={apiWithForm}>
      <>{contextHolder}</>
      <>{formContextHolder}</>
      {children}
    </GlobalModalContext.Provider>
  );
}
