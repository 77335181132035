import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import { useMutation, useQuery } from "@apollo/client";
import { useEventEmitter, useLocalStorageState } from "ahooks";
import type { MessagePayload } from "firebase/messaging";
import {
  getMessaging,
  getToken as firebaseGetToken,
  isSupported,
  onMessage as firebaseOnMessage,
} from "firebase/messaging";

import { gql } from "@/__generated__";
import { useConfig } from "@/components/apps/common/ConfigProvider/hooks";
import { useGlobalNotification } from "@/components/apps/common/GlobalNotificationProvider/hooks";
import { app } from "@/firebase";

export type PushNotificationContext = {
  enabled: boolean | undefined;
  setEnabled: (enabled: boolean | undefined) => void;
  requestPermission: () => void;
  onMessage: (callback: (payload: MessagePayload) => void) => void;
  unread: number | undefined;
  resetUnread: () => void;
  isSupported: boolean;
};

export const PushNotificationContext =
  createContext<PushNotificationContext | null>(null);

interface PushNotificationProviderProps {
  children: React.ReactNode;
}

export function PushNotificationProvider({
  children,
}: PushNotificationProviderProps) {
  const { config } = useConfig();
  const onMessageEventEmitter = useEventEmitter<MessagePayload>();
  const notification = useGlobalNotification();
  const [supported, setSupported] = useState(false);
  const [enabled, setEnabled] = useLocalStorageState<boolean | undefined>(
    "pushNotificationsEnabled",
    {
      defaultValue:
        "Notification" in window && Notification.permission === "granted"
          ? true
          : undefined,
    }
  );
  const [deviceToken, setDeviceToken] = useLocalStorageState<
    { token: string; date: Date } | undefined
  >("deviceToken", {
    deserializer(value) {
      const parsed = JSON.parse(value);
      return {
        token: parsed.token,
        date: new Date(parsed.date),
      };
    },
  });

  const [updateDeviceToken] = useMutation(
    gql(`
      mutation PushNotificationProvider_updateDeviceToken($token: String!) {
        updateDeviceToken(token: $token)
      }
    `)
  );

  const { data: { notifications } = {}, refetch } = useQuery(
    gql(`
      query NotificationsProvider($filter: NotificationsFilter) {
        notifications(filter: $filter) {
          id
        }
      }
    `),
    {
      pollInterval: config.notifications.unreadPollingInterval,
      variables: {
        filter: {
          read: false,
        },
      },
    }
  );

  const getToken = useCallback(async () => {
    if (!("Notification" in window)) {
      return;
    }
    if ((await isSupported()) && Notification.permission === "granted") {
      const messaging = getMessaging(app);
      const { VITE_APP_VAPID_KEY } = import.meta.env;
      const token = await firebaseGetToken(messaging, {
        vapidKey: VITE_APP_VAPID_KEY,
      });

      if (
        token &&
        (deviceToken?.token !== token ||
          deviceToken.date.getTime() <
            new Date().getTime() -
              config.notifications.deviceTokenPollingInterval)
      ) {
        updateDeviceToken({
          variables: { token },
          onCompleted: () => {
            setDeviceToken({
              token,
              date: new Date(),
            });
          },
        });
      }

      firebaseOnMessage(messaging, (payload) => {
        onMessageEventEmitter.emit(payload);
      });
    }
  }, [
    config.notifications.deviceTokenPollingInterval,
    deviceToken?.date,
    deviceToken?.token,
    onMessageEventEmitter,
    setDeviceToken,
    updateDeviceToken,
  ]);

  const requestPermission = useCallback(async () => {
    if (!("Notification" in window)) {
      return;
    }

    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      setEnabled(true);
    }

    if (permission === "denied") {
      notification.error({
        message: (
          <FormattedMessage defaultMessage="Notifications are blocked" />
        ),
        description: (
          <FormattedMessage defaultMessage="You need to allow this application to receive notifications" />
        ),
      });
    }
  }, [notification, setEnabled]);

  useEffect(() => {
    const init = async () => {
      if (!("Notification" in window) || !(await isSupported())) {
        return;
      }

      setSupported(true);

      if (Notification.permission === "granted") {
        getToken();
      } else {
        if (enabled === true) {
          setEnabled(undefined);
        }
      }
    };
    init();
  }, [enabled, getToken, setEnabled]);

  const value = useMemo(
    () => ({
      enabled,
      setEnabled,
      requestPermission,
      unread: notifications?.length,
      resetUnread: refetch,
      onMessage: onMessageEventEmitter.useSubscription,
      isSupported: supported,
    }),
    [
      enabled,
      notifications?.length,
      onMessageEventEmitter.useSubscription,
      refetch,
      requestPermission,
      setEnabled,
      supported,
    ]
  );

  return (
    <PushNotificationContext.Provider value={value}>
      {children}
    </PushNotificationContext.Provider>
  );
}
