import { isValidElement } from "react";

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export const isStringOrReactNode = (
  value: unknown
): value is string | React.ReactNode => {
  return typeof value === "string" || isValidElement(value);
};
