import { type Permalink, PermalinkType } from "@/__generated__/graphql";

export const arrayToObject = <T>(
  arr: Array<unknown> = [],
  { key = "id", data = "data" }
): T =>
  arr.reduce((acc, item) => {
    // @ts-ignore
    acc[item[key]] = item[data];
    return acc;
  }, {}) as T;

export const objectToArray = <T>(
  obj: Record<string, unknown>,
  { key = "id", data = "data" }
): T[] => {
  return Object.entries(obj).map<T>(
    ([k, v]) =>
      ({
        [key]: k,
        [data]: v,
      } as T)
  );
};

export const generateLink = (params: Permalink) => {
  const { type } = params || {};

  switch (type) {
    case PermalinkType.Class:
      return `/classes/${params.id}`;
    default:
      return "/";
  }
};
