import { useState } from "react";
import type { ImageProps } from "antd";
import { Image } from "antd";

interface FitImageProps extends Omit<ImageProps, "src"> {
  src?: ImageProps["src"] | null;
  fallbackElement?: React.ReactNode;
}

export const FitImage = ({
  fallbackElement,
  onError,
  src,
  ...props
}: FitImageProps) => {
  const [error, setError] = useState(false);

  return fallbackElement !== undefined && (error || !src) ? (
    fallbackElement
  ) : (
    <Image
      preview={false}
      {...props}
      src={src!}
      onError={(...args) => {
        setError(true);
        onError?.(...args);
      }}
    />
  );
};
