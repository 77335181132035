import { useContext } from "react";

import { TenantContext } from "./TenantProvider";

export function useTenant(): TenantContext {
  const tenantContext = useContext<TenantContext | null>(TenantContext);

  if (tenantContext === null) {
    throw new Error("useTenant must be inside a TenantProvider");
  }

  return tenantContext;
}
