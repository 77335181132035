import type { ReactElement } from "react";
import { useCallback, useMemo } from "react";
import type { ModalFuncProps } from "antd";
import { Modal } from "antd";

import type { CancelButtonProps } from "@/components/common/forms/CancelButton/CancelButton";
import { useSmartForm } from "@/components/common/forms/SmartForm/smart-form.hooks";
import type { SmartFormProps } from "@/components/common/forms/SmartForm/SmartForm";
import { SmartForm } from "@/components/common/forms/SmartForm/SmartForm";
import type { SubmitButtonProps } from "@/components/common/forms/SubmitButton/SubmitButton";

import { useAgent } from "./app.hooks";

interface UseFormModalShowOptions<Values = unknown>
  extends Omit<
    ModalFuncProps,
    "form" | "onFinish" | "cancelButtonProps" | "okButtonProps"
  > {
  form?: Omit<SmartFormProps<Values>, "form">;
  onFinish?: SmartFormProps<Values>["onFinish"];
  okButtonProps?: SubmitButtonProps;
  cancelButtonProps?: CancelButtonProps;
}

export interface FormModalApi {
  show: <Values = unknown>(options: UseFormModalShowOptions<Values>) => void;
}

export const useFormModal = (): [FormModalApi, ReactElement] => {
  const breakpoints = useAgent();
  const [api, contextHolder] = Modal.useModal();
  const [formInstance] = useSmartForm();

  const show = useCallback(
    ({
      onFinish,
      form,
      cancelText,
      cancelButtonProps,
      okText,
      okButtonProps,
      ...props
    }: UseFormModalShowOptions) => {
      return new Promise((resolve) => {
        const modal = api.confirm({
          modalRender: (node) => (
            <SmartForm
              form={formInstance}
              onFinish={onFinish}
              onSubmitted={() => {
                modal.destroy();
                resolve(true);
              }}
              {...form}
            >
              {node}
            </SmartForm>
          ),
          icon: null,
          footer: () => (
            <SmartForm.Actions
              style={
                breakpoints.isDesktop
                  ? { flexDirection: "row-reverse" }
                  : undefined
              }
              cancelText={cancelText}
              cancelProps={{
                onClick: () => {
                  if (formInstance.isDirty) {
                    formInstance.leaveConfirm().then((shouldLeave) => {
                      if (shouldLeave) {
                        formInstance.resetFields();
                        modal.destroy();
                      }
                    });
                  } else {
                    return modal.destroy();
                  }
                },
                ...cancelButtonProps,
                style: {
                  marginInlineStart: breakpoints.isMobile ? 0 : undefined,
                  ...cancelButtonProps?.style,
                },
              }}
              submitText={okText}
              submitProps={{ ...okButtonProps }}
            />
          ),
          ...props,
          afterClose: () => {
            formInstance.resetFields();
            modal.destroy();
            resolve(false);
          },
        });
      });
    },
    [api, breakpoints.isDesktop, breakpoints.isMobile, formInstance]
  );

  const actions = useMemo(() => ({ show }), [show]) as FormModalApi;

  return [actions, contextHolder];
};
