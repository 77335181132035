import { createContext, useEffect, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { datadogRum } from "@datadog/browser-rum";
import { useUpdateEffect } from "ahooks";

import { gql } from "@/__generated__";
import type { UserProviderQueryQuery } from "@/__generated__/graphql";
import { AbilityContext } from "@/components/common/Can/Can";
import type { AppAbility } from "@/components/common/common.types";
import { defineAbilityFor } from "@/utils/abilities";

import { useConfig } from "../ConfigProvider/hooks";
import { useSession } from "../SessionProvider/hooks";

interface UserProviderProps {
  children: React.ReactNode;
}

export type UserContext = {
  user: UserProviderQueryQuery["currentUser"];
  ability: AppAbility;
  reload: () => void;
};

export const UserContext = createContext<UserContext | null>(null);

export function UserProvider({ children }: UserProviderProps) {
  const { session } = useSession();
  const { setConfig } = useConfig();

  const {
    data: { currentUser: user, currentAbility } = {},
    refetch,
    error,
  } = useQuery(
    gql(`
        query UserProviderQuery {
          currentUser {
            id
            name
            email
            avatarUrl
            language
            isAndroidTester
            membership {
              id
              data
            }
          }
          currentAbility
        }
      `)
  );

  useUpdateEffect(() => {
    if (session?.access_token) {
      refetch();
    }
  }, [session?.access_token]);

  useEffect(() => {
    if (user) {
      datadogRum.setUser({
        email: user.email,
        id: user.id,
        name: user.name,
      });
    }
    if (user?.language) {
      setConfig({ language: user.language });
    }
  }, [setConfig, user, user?.language]);

  const ability = defineAbilityFor(JSON.parse(currentAbility ?? "{}"));

  const value = useMemo(
    () => ({
      user: user!,
      ability,
      reload: refetch,
    }),
    [ability, refetch, user]
  );

  if (error) {
    throw new Error();
  }

  if (!user) {
    return null;
  }

  return (
    <UserContext.Provider value={value}>
      <AbilityContext.Provider value={ability}>
        {children}
      </AbilityContext.Provider>
    </UserContext.Provider>
  );
}
