import { useContext } from "react";

import { FooterContext } from "./FooterProvider";

export const useFooter = () => {
  const context = useContext(FooterContext);
  if (!context) {
    throw new Error("useFooter must be used within a FooterProvider");
  }
  return context;
};
