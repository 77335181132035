import { subject } from "@casl/ability";
import { createPrismaAbility } from "@casl/prisma";

import { Action, SubjectType } from "@/__generated__/graphql";
import type { AppAbility } from "@/components/common/common.types";

export const defineAbilityFor = (permissions: AppAbility["rules"]) => {
  return createPrismaAbility<AppAbility>(permissions, {
    anyAction: Action.Manage,
    anySubjectType: SubjectType.All,
  });
};

export const canMembersAdmin = (ability: AppAbility) => {
  return ability.can(
    Action.Manage,
    subject(SubjectType.Membership, { userId: "" })
  );
};

export const canClassAdmin = (ability: AppAbility) => {
  return (
    ability.can(Action.Create, SubjectType.Class) ||
    ability.can(Action.Update, SubjectType.Class) ||
    ability.can(Action.Delete, SubjectType.Class)
  );
};

export const canWorkoutAdmin = (ability: AppAbility) => {
  return (
    ability.can(Action.Create, SubjectType.Workout) ||
    ability.can(Action.Update, SubjectType.Workout) ||
    ability.can(Action.Delete, SubjectType.Workout)
  );
};
