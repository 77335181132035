import { useContext } from "react";

import { ConfigContext } from "./ConfigProvider";

export const useConfig = (): ConfigContext => {
  const configContext = useContext<ConfigContext | null>(ConfigContext);

  if (configContext === undefined || configContext === null) {
    throw new Error("useConfig must be inside a ConfigProvider");
  }

  return configContext;
};
