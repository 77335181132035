import type { FitButtonProps } from "../../FitButton/FitButton";
import { FitButton } from "../../FitButton/FitButton";
import { useSmartFormInstance } from "../SmartForm/smart-form.hooks";
import { SmartForm, type SmartFormInstance } from "../SmartForm/SmartForm";

export interface CancelButtonProps extends Omit<FitButtonProps, "form"> {
  submittedText?: string | React.ReactNode;
  onCancel?: () => void;
  form?: SmartFormInstance;
}

export const CancelButton = ({
  children,
  submittedText,
  onCancel,
  onClick,
  form,
  ...props
}: CancelButtonProps) => {
  const instanceForm = useSmartFormInstance();
  const [internalForm] = SmartForm.useForm(form ?? instanceForm ?? undefined);

  const callback = onCancel || onClick;

  return (
    <FitButton
      responsive
      onClick={callback}
      navigate={callback ? undefined : -1}
      {...props}
    >
      <span>
        {internalForm.hasSubmitted && submittedText ? submittedText : children}
      </span>
    </FitButton>
  );
};
