import { useCallback } from "react";
import { Button, Divider, Form, Input, Select, Switch } from "antd";

import type { Config } from "../apps/common/ConfigProvider/ConfigProvider";
import { useConfig } from "../apps/common/ConfigProvider/hooks";

export function DevTools() {
  const { config, setConfig } = useConfig();

  const onChange = useCallback(
    (values: Partial<Config>) => {
      setConfig(values);
      localStorage.setItem("appConfigOverride", JSON.stringify(values));
    },
    [setConfig]
  );

  const reset = useCallback(() => {
    localStorage.removeItem("appConfigOverride");
    window.location.reload();
  }, []);

  return (
    <Form
      initialValues={config}
      onValuesChange={onChange}
      layout="vertical"
      labelCol={{ span: 6 }}
      requiredMark={false}
    >
      <Form.Item label="Theme" name="theme">
        <Select>
          <Select.Option value="light">Light</Select.Option>
          <Select.Option value="dark">Dark</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label="Language" name="locale">
        <Select>
          <Select.Option value="en">English</Select.Option>
          <Select.Option value="pt-PT">Português</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label="Payment sandbox" name="paymentSandbox">
        <Switch />
      </Form.Item>
      <Form.Item label="Banana" name="banana">
        <Input allowClear />
      </Form.Item>
      <Divider>Feature Flags</Divider>
      <Form.Item label="Spaces" name={["featureFlags", "spaces"]}>
        <Switch />
      </Form.Item>
      <Form.Item label="Events" name={["featureFlags", "events"]}>
        <Switch />
      </Form.Item>
      <Form.Item style={{ float: "right", marginBottom: 0 }}>
        <Button type="primary" danger onClick={reset}>
          Reset all overrides
        </Button>
      </Form.Item>
    </Form>
  );
}
