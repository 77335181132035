import { isApolloError } from "@apollo/client";
import type { FormInstance } from "antd";
import type { NotificationInstance } from "antd/es/notification/interface";
import flatten from "lodash/flatten";
import get from "lodash/get";

export type FormItemOptions = boolean | "disabled";

export const isValid = (form: FormInstance) => {
  return !form.getFieldsError().some((item) => item.errors.length > 0);
};

export const setErrorsFromServer = (
  form: FormInstance,
  response: Record<string, string[]>
) => {
  form.setFields(
    Object.keys(response).map((field) => {
      return {
        name: field,
        value: form.getFieldValue(field),
        errors: response[field].filter((error) => typeof error === "string"),
      };
    })
  );
};

export const formExceptionHandler = (
  error: Error,
  form: FormInstance,
  notification: NotificationInstance,
  path?: string
) => {
  if (isApolloError(error)) {
    const constraints = error.graphQLErrors?.[0].extensions?.constraints ?? {};
    setErrorsFromServer(form, path ? get(constraints, path) : constraints);
  }

  if (isValid(form)) {
    notification.error({
      message: "An error occurred",
      description: "Please try again later",
    });
  }

  throw error;
};

export const getFormItemName = (
  ...names: (string | string[] | undefined)[]
): string[] => {
  return flatten(names).filter((name) => name) as string[];
};
