import type { ReactNode } from "react";
import { Link } from "react-router-dom";
import type { MenuProps } from "antd";

export interface MenuEntry {
  key: string;
  label: ReactNode;
  icon?: ReactNode;
  link?: string;
  disabled?: boolean;
  children?: MenuEntry[];
  color?: string;
}

export const convertMenuEntriesToMenuItems = (
  items: MenuEntry[] = []
): NonNullable<MenuProps["items"]> => {
  return items.map((item) => ({
    ...item,
    label: item.link ? <Link to={item.link}>{item.label}</Link> : item.label,
    ...(item.children
      ? { children: convertMenuEntriesToMenuItems(item.children) }
      : {}),
  }));
};
