import { Spin, type SpinProps } from "antd";
import type { LottieComponentProps } from "lottie-react";
import Lottie from "lottie-react";

import pushups from "./pushups.json";

interface LoadingProps extends SpinProps {
  lottie?: Omit<LottieComponentProps, "animationData">;
}

export const Loading = ({ lottie, ...props }: LoadingProps) => {
  return (
    <Spin
      indicator={<Lottie {...lottie} animationData={pushups} />}
      {...props}
    />
  );
};
