import { datadogRum } from "@datadog/browser-rum";

if (import.meta.env.MODE === "production") {
  datadogRum.init({
    applicationId: "8452cb94-0191-4da3-a49e-11c9a111ff47",
    clientToken: "pub9e00408fd39c91c20fc1e317fe437854",
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: "datadoghq.eu",
    service: "fitrack-app",
    env: "prod",
    version: import.meta.env.VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });
}
