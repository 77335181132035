import { createContext, useMemo, useState } from "react";
import type { NormalizedCacheObject } from "@apollo/client";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider as BaseApolloProvider,
  InMemoryCache,
} from "@apollo/client";
import { removeTypenameFromVariables } from "@apollo/client/link/remove-typename";
import { useLocalStorageState } from "ahooks";
import { withScalars } from "apollo-link-scalars";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { buildClientSchema } from "graphql";
import { GraphQLJSON, GraphQLJSONObject } from "graphql-scalars";

import { gql } from "@/__generated__";
import type { config as baseConfig } from "@/config";
import schema from "@/generated/graphql.schema.json";
import { DateTime } from "@/scalars";

import { useConfig } from "../ConfigProvider/hooks";
import { useSession } from "../SessionProvider/hooks";

const removeTypenameLink = removeTypenameFromVariables();

export type Config = typeof baseConfig;

export interface ApolloProviderContext {
  client: ApolloClient<NormalizedCacheObject>;
  tenant?: string;
  setTenant: (tenant: string) => void;
}

export const ApolloContext = createContext<ApolloProviderContext | null>(null);

interface ApolloProviderProps {
  children: React.ReactNode;
  headers?: Record<string, string>;
}

export function ApolloProvider({ children, headers }: ApolloProviderProps) {
  const [activeTenant] = useLocalStorageState<string>("tenant", {
    defaultValue: "fitrack",
  });

  const { config } = useConfig();
  const { session } = useSession();
  const [tenant, setTenant] = useState<string | undefined>(activeTenant);

  const httpLink = useMemo(() => {
    return createUploadLink({
      uri: config.graphql.endpoint,
      headers: {
        ...(tenant ? { tenant } : {}),
        ...(config.paymentSandbox ? { "X-payment-sandbox": "true" } : {}),
        ...(config.banana ? { "X-Banana": config.banana } : {}),
        ...(session?.access_token
          ? { Authorization: `Bearer ${session.access_token}` }
          : {}),
        ...headers,
      },
      ...(import.meta.env.DEV
        ? {
            fetch: (uri, options) => {
              if (options?.body instanceof FormData) {
                return fetch(uri, options);
              }
              const { operationName } = JSON.parse(
                options?.body?.toString() ?? "{}"
              );
              return fetch(`${uri}/${operationName}`, options);
            },
          }
        : {}),
    });
  }, [config.graphql.endpoint, headers, session?.access_token, tenant]);

  const scalarLink = useMemo(() => {
    return withScalars({
      // @ts-ignore
      schema: buildClientSchema(schema),
      typesMap: {
        DateTime,
        JSONObject: GraphQLJSONObject,
        JSON: GraphQLJSON,
      },
    });
  }, []);

  const client = useMemo(() => {
    return new ApolloClient({
      link: ApolloLink.from([removeTypenameLink, scalarLink, httpLink]),
      cache: new InMemoryCache(),
      connectToDevTools: import.meta.env.DEV,
      typeDefs: [
        gql(`
      fragment TranslationFragment on Translation {
        en
        pt
      }
      `),
      ],
      defaultOptions: {
        query: {
          variables: {
            language: "pt",
          },
        },
      },
    });
  }, [httpLink, scalarLink]);

  const value = useMemo(
    () => ({
      client,
      tenant,
      setTenant,
    }),
    [client, tenant]
  );

  return (
    <ApolloContext.Provider value={value}>
      <BaseApolloProvider client={client}>{children}</BaseApolloProvider>
    </ApolloContext.Provider>
  );
}
