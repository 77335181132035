import { useMemo } from "react";
import type { AvatarProps } from "antd";
import { Avatar, Flex } from "antd";

interface UserAvatarProps extends AvatarProps {
  name?: string;
  image?: string | null;
  size?: AvatarProps["size"];
  showName?: boolean | "first" | "short" | "full";
  className?: string;
}

export function UserAvatar({
  name = "",
  image,
  size,
  showName = false,
  className,
  ...props
}: UserAvatarProps) {
  const initials = useMemo(() => {
    const names = name.split(" ");
    return names.length > 1
      ? `${names[0][0]}${names[names.length - 1][0]}`
      : names[0][0];
  }, [name]);

  const nameToShow = useMemo(() => {
    if (showName === false) return null;
    if (showName === "first") return name.split(" ")[0];
    if (showName === "short") {
      const names = name.split(" ");
      return names.length > 1
        ? `${names[0]} ${names[names.length - 1][0]}.`
        : names[0];
    }
    return name;
  }, [name, showName]);

  return (
    <Flex align="center" gap="small">
      <Avatar className={className} size={size} src={image} {...props}>
        {initials}
      </Avatar>
      {showName && (
        <span style={{ textWrap: "wrap", textAlign: "left" }}>
          {nameToShow}
        </span>
      )}
    </Flex>
  );
}
