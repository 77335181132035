/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query AgendaQuery($from: DateTime!, $to: DateTime!) {\n    classes(input: {\n      from: $from\n      to: $to\n      isRegistered: true\n    }) {\n      id\n      name\n      startDate\n      ...ClassCardFragment\n    }\n    spaceBookings(filter: {\n      from: $from\n      to: $to\n    }) {\n      id\n      startDate\n\n      ...BookingCard_booking\n    }\n  }\n": types.AgendaQueryDocument,
    "\n      query HomePageQuery {\n        currentUser {\n          memberships {\n            tenant {\n              id\n              name\n              slug\n              logoUrl\n            }\n          }\n        }\n      }\n    ": types.HomePageQueryDocument,
    "\n      fragment TranslationFragment on Translation {\n        en\n        pt\n      }\n      ": types.TranslationFragmentFragmentDoc,
    "\n      query TenantProviderQuery {\n        currentTenant {\n          id\n          name\n          slug\n          logoUrl\n          languages\n          defaultLanguage\n          inAppInvoices\n          inAppPaymentsEnabled\n          hasBookings\n          hasPlans\n          brandColor\n          timezone\n          classTypes {\n            id\n            name\n            color\n          }\n          locations {\n            id\n            name\n          }\n        }\n      }\n    ": types.TenantProviderQueryDocument,
    "\n        query UserProviderQuery {\n          currentUser {\n            id\n            name\n            email\n            avatarUrl\n            language\n            isAndroidTester\n            membership {\n              id\n              data\n            }\n          }\n          currentAbility\n        }\n      ": types.UserProviderQueryDocument,
    "\n      mutation LoginPage_checkAccount($email: String!) {\n        checkAccount(email: $email)\n      }\n    ": types.LoginPage_CheckAccountDocument,
    "\n      mutation LoginPage_signIn($input: SignInInput!) {\n        signIn(input: $input) {\n          accessToken\n          refreshToken\n        }\n      }\n    ": types.LoginPage_SignInDocument,
    "\n      mutation LoginPage_forgotPassword($email: String!) {\n        forgotPassword(email: $email)\n      }\n    ": types.LoginPage_ForgotPasswordDocument,
    "\n    query LoginPage_invite($id: ID!) {\n      token(id: $id) {\n        id\n        data\n      }\n    }": types.LoginPage_InviteDocument,
    "\n      mutation ResetPasswordPage_resetPassword(\n        $code: String!\n        $password: String!\n      ) {\n        resetPassword(code: $code, password: $password)\n      }\n    ": types.ResetPasswordPage_ResetPasswordDocument,
    "\n      mutation SignUp($input: SignUpInput!) {\n        signUp(input: $input) {\n          result \n          accessToken\n          refreshToken\n        }\n      }\n    ": types.SignUpDocument,
    "\n      fragment ClassCardFragment on Class {\n        id\n        name\n        startDate\n        endDate\n        maxAttendees\n        registered\n        inQueue\n        capacity\n        location {\n          id\n          name\n        }\n        registrations {\n          id\n        }\n        space {\n          name\n        }\n        coaches {\n          id\n          name\n          avatarUrl\n        }\n      }\n    ": types.ClassCardFragmentFragmentDoc,
    "\n      fragment RegistrationsList_cls on Class {\n        id\n        registrations {\n          id\n          user {\n            id\n            name\n            avatarUrl\n          }\n          attendance\n        }\n        ...ClassRegistrationButton_cls\n      }\n    ": types.RegistrationsList_ClsFragmentDoc,
    "\n      fragment RegistrationsList_registrations on ClassRegistration {\n          id\n          user {\n            id\n            name\n            avatarUrl\n          }\n          attendance\n      }\n    ": types.RegistrationsList_RegistrationsFragmentDoc,
    "\n      fragment SeatsList_cls on Class {\n        id\n        registrations {\n          user {\n            id\n            name\n            avatarUrl\n          }\n          seatId\n        }\n        space {\n          seats {\n            id\n          }\n        }\n        ...ClassRegistrationButton_cls\n      }\n    ": types.SeatsList_ClsFragmentDoc,
    "\n      fragment ClassPageRegistrationsFragment on Class {\n        id\n        name\n        startDate\n        endDate\n        maxAttendees\n        registered\n        inQueue\n        capacity\n        space {\n          seats {\n            id\n            name\n          }\n        }\n        registrations {\n          id\n          date\n          user {\n            id\n            name\n            avatarUrl\n          }\n          seatId\n          ...RegistrationsList_registrations\n        }\n        ...ClassRegistrationButton_cls\n        ...SeatsList_cls\n        ...RegistrationsList_cls\n      }\n    ": types.ClassPageRegistrationsFragmentFragmentDoc,
    "\n      fragment ClassWorkout_cls on Class {\n        workouts {\n          id\n          name\n          description\n          scoreType\n        }\n      }\n    ": types.ClassWorkout_ClsFragmentDoc,
    "\n      fragment ClassAttendanceForm on Class {\n        id\n        registrations {\n          id\n          user {\n            id\n            name\n            avatarUrl\n          }\n          seatId\n          attendance \n        }\n      }\n    ": types.ClassAttendanceFormFragmentDoc,
    "\n    mutation UpdateAttendance($input: [UpdateAttendance!]!) {\n      updateAttendance(attendance: $input) {\n        id\n        attendance\n      }\n    }\n  ": types.UpdateAttendanceDocument,
    "\n      query ClassPageQuery($id: ID!, $canSeeWorkout: Boolean!) {\n        class(id: $id) {\n          id\n          name\n          startDate\n          endDate\n          maxAttendees\n          registered\n          typeId\n          registrationStatus {\n            canRegister\n            reason\n            when\n          }\n          coaches {\n            id\n            name\n            avatarUrl\n          }\n          space {\n            seats {\n              id\n              name\n            }\n          }\n          registrations {\n            id\n            attendance\n          }\n          ...ClassRegistrationButton_cls\n          ...ClassPageRegistrationsFragment\n          ...ClassAttendanceForm\n          ...ClassWorkout_cls @include(if: $canSeeWorkout)\n        }\n      }\n    ": types.ClassPageQueryDocument,
    "\n      mutation ClassRegisterOtherButtonRegister(\n        $cls: ID!\n        $user: ID!\n        $seat: String\n      ) {\n        register(cls: $cls, seat: $seat, user: $user) {\n          id\n        }\n      }\n    ": types.ClassRegisterOtherButtonRegisterDocument,
    "\n      mutation ClassRegisterOtherButtonRegisterGuest(\n        $cls: ID!\n        $guest: String!\n        $seat: String\n      ) {\n        registerGuest(cls: $cls, seat: $seat, guest: $guest) {\n          id \n        } \n      }\n    ": types.ClassRegisterOtherButtonRegisterGuestDocument,
    "\n      mutation ClassRegistrationButtonRegisterMutation($cls: ID!, $user: ID!, $seat: String) {\n        register(cls: $cls, user: $user, seat: $seat) {\n          id\n        }\n      }\n    ": types.ClassRegistrationButtonRegisterMutationDocument,
    "\n      mutation ClassRegistrationButtonUnregisterMutation($id: ID!) {\n        unregister(id: $id)\n      }\n    ": types.ClassRegistrationButtonUnregisterMutationDocument,
    "\n      fragment ClassRegistrationButton_cls on Class {\n        id\n        startDate\n        typeId\n        registrationStatus {\n          canRegister\n          reason\n        }\n        registrations {\n          id\n          date\n          seatId\n          user {\n            id\n            name\n          }\n        }\n        space {\n          seats {\n            id\n            name\n          }\n        }\n      }\n    ": types.ClassRegistrationButton_ClsFragmentDoc,
    "\n      query ClassesCalendar($input: FindClassesInput!) {\n        classes(input: $input) {\n          id\n          name\n          startDate\n          endDate\n          typeId\n          scheduleType\n        }\n      }\n    ": types.ClassesCalendarDocument,
    "\n      fragment ClassesListFragment on Class {\n        id\n        startDate\n        ...ClassCardFragment\n      }\n    ": types.ClassesListFragmentFragmentDoc,
    "\n      query ClassesPageQuery($input: FindClassesInput!) {\n        classes(input: $input) {\n          id\n          startDate\n          endDate\n          registrationStatus {\n            canRegister\n          }\n          ...ClassesListFragment\n        }\n      }\n    ": types.ClassesPageQueryDocument,
    "\n    mutation CreateClassPageMutation($input: CreateClassInput!) {\n      createClass(input: $input) {\n        id\n      }\n    }\n  ": types.CreateClassPageMutationDocument,
    "\n      mutation CreateWorkoutCategoryPage_createClassType($input: CreateClassTypeInput!) {\n        createClassType(input: $input) {\n          id\n        }\n      }\n    ": types.CreateWorkoutCategoryPage_CreateClassTypeDocument,
    "\n    mutation ManageClassPageUpdateMutation(\n      $id: String!\n      $input: UpdateClassInput!\n    ) {\n      updateClass(id: $id, input: $input) {\n        id\n      }\n    }\n  ": types.ManageClassPageUpdateMutationDocument,
    "\n    mutation ManageClassPageDeleteMutation($id: String!) {\n      deleteClass(id: $id)\n    }\n  ": types.ManageClassPageDeleteMutationDocument,
    "\n      query ManageClassPageQuery($id: ID!) {\n        class(id: $id) {\n          id\n          typeId\n          name\n          scheduleType\n          startDate\n          endDate\n          spaceId\n          parentId \n          maxAttendees\n          maxQueue\n          registerFrom\n          registerUntil \n          cancelUntil\n          coachIds\n          locationId\n          slots {\n            start \n            end\n            weekdays\n          }\n        }\n      }\n    ": types.ManageClassPageQueryDocument,
    "\n      query ManageClassTypePage($id: ID!) {\n        classType(id: $id) {\n          id\n          name\n          nameTranslations {\n            ...TranslationFragment\n          }\n          color\n        }\n      }\n    ": types.ManageClassTypePageDocument,
    "\n      mutation ManageClassTypePage_updateClassType($id: ID!, $input: UpdateClassTypeInput!) {\n        updateClassType(id: $id, input: $input) {\n          id\n        }\n      }\n    ": types.ManageClassTypePage_UpdateClassTypeDocument,
    "\n      mutation ManageClassTypePage_deleteClassType($id: ID!) {\n        deleteClassType(id: $id)\n      }\n    ": types.ManageClassTypePage_DeleteClassTypeDocument,
    "\n      query ManageClassTypesPage {\n        classTypes {\n          id\n          name\n          color\n        }\n      }\n    ": types.ManageClassTypesPageDocument,
    "\n      query ManageClassesSettingsPage {\n        currentTenant {\n          id\n          classesSettings\n        }\n      }\n    ": types.ManageClassesSettingsPageDocument,
    "\n    mutation ManageClassesSettingsPage_updateTenant($input: UpdateTenantInput!) {\n      updateTenant(input: $input) {\n        id\n        classesSettings\n      }\n    }": types.ManageClassesSettingsPage_UpdateTenantDocument,
    "\n      query ActiveTenantSelector {\n        currentUser {\n          memberships {\n            tenant {\n              id\n              name\n              slug\n              logoUrl\n            }\n          }\n        }\n      }\n    ": types.ActiveTenantSelectorDocument,
    "\n      query BoxSelector {\n        currentUser {\n          memberships {\n            tenant {\n              id\n              name\n              logoUrl\n            }\n          }\n        }\n      }\n    ": types.BoxSelectorDocument,
    "\n      query CoachSelect {\n        currentTenant {\n          coaches {\n            id\n            name\n          }\n        }\n      }\n    ": types.CoachSelectDocument,
    "\n      fragment CommentsCard_comments on Comment {\n        id\n        text\n        createdAt\n\n        ...CommentsList_comments\n      }\n    ": types.CommentsCard_CommentsFragmentDoc,
    "\n      mutation CommentsCard_createCard($input: CreateCommentInput!) {\n        createComment(input: $input) {\n          id\n          text\n          createdAt\n        }\n      }\n    ": types.CommentsCard_CreateCardDocument,
    "\n      query GroupSelect {\n        currentTenant {\n          id\n          groups {\n            id\n            name\n          }\n        }\n      }\n    ": types.GroupSelectDocument,
    "\n      mutation PushNotificationProvider_updateDeviceToken($token: String!) {\n        updateDeviceToken(token: $token)\n      }\n    ": types.PushNotificationProvider_UpdateDeviceTokenDocument,
    "\n      query NotificationsProvider($filter: NotificationsFilter) {\n        notifications(filter: $filter) {\n          id\n        }\n      }\n    ": types.NotificationsProviderDocument,
    "\n      mutation NotificationsPopover_markAsRead($ids: [ID!]!) {\n        notificationsMarkAsRead(ids: $ids) {\n          id\n        }\n      }\n    ": types.NotificationsPopover_MarkAsReadDocument,
    "\n      query NotificationsIcon {\n        notifications {\n          id\n          read\n          date\n          message\n          link\n        }\n      }\n    ": types.NotificationsIconDocument,
    "\n      query UserInput {\n        members {\n          id\n          name\n          avatarUrl\n        }\n      }\n    ": types.UserInputDocument,
    "\n      mutation CreateCollectionPage_create(\n        $input: CreateDirectDebitCollectionInput!\n      ) {\n        createDirectDebitCollection(input: $input) {\n          id\n          transactions\n        }\n      }\n    ": types.CreateCollectionPage_CreateDocument,
    "\n      mutation CreateExpensePage_createExpense($input: CreateExpenseInput!) {\n        createExpense(input: $input) {\n          id\n        }\n      }\n    ": types.CreateExpensePage_CreateExpenseDocument,
    "\n      mutation CreatePaymentPage_createPayment($input: CreatePaymentInput!) {\n        createPayment(input: $input) {\n          id\n        }\n      }\n    ": types.CreatePaymentPage_CreatePaymentDocument,
    "\n    query FinanceSettingsPage_DirectDebitTab {\n        financeSettings {\n            creditors {\n                name\n                creditorId\n                street\n                postalCode\n                city\n                country\n                iban\n                vatNumber\n            }\n        }\n    }": types.FinanceSettingsPage_DirectDebitTabDocument,
    "\n      mutation FinanceSettingsPage_DirectDebitTab_updateFinanceSettings(\n        $input: UpdateFinanceSettingsInput!\n      ) {\n        updateFinanceSettings(input: $input) {\n          creditors {\n            name\n            creditorId\n            street\n            postalCode\n            city\n            country\n            iban\n            vatNumber\n          }\n        }\n      }\n    ": types.FinanceSettingsPage_DirectDebitTab_UpdateFinanceSettingsDocument,
    "\n      fragment InvoiceCard_payment on Payment {\n        id\n        invoice\n        invoiceUrl\n      }\n    ": types.InvoiceCard_PaymentFragmentDoc,
    "\n      mutation InvoiceCard_uploadInvoice(\n        $id: ID!\n        $input: UpdatePaymentInput!\n      ) {\n        updatePayment(id: $id, input: $input) {\n          id\n        }\n      }\n    ": types.InvoiceCard_UploadInvoiceDocument,
    "\n    query ManageExpensePage($id: ID!) {\n      expense(id: $id) {\n        id\n        date\n        amount\n        notes\n        method\n        createdBy {\n          name\n        }\n      }\n    }\n  ": types.ManageExpensePageDocument,
    "\n      mutation ManageExpensePage_updateExpense(\n        $id: ID!\n        $input: UpdateExpenseInput!\n      ) {\n        updateExpense(id: $id, input: $input) {\n          id\n        }\n      }\n    ": types.ManageExpensePage_UpdateExpenseDocument,
    "\n      query ManageExpensesPage {\n        expenses {\n          id\n          createdBy {\n            id\n            name\n          }\n          date\n          amount\n        }\n      }\n    ": types.ManageExpensesPageDocument,
    "\n    query ManagePaymentPage($id: ID!) {\n      payment(id: $id) {\n        id\n        date\n        amount\n        offer\n        notes\n        user {\n          name\n        }\n        method\n        createdBy {\n          name\n        }\n\n        ...InvoiceCard_payment\n      }\n    }\n  ": types.ManagePaymentPageDocument,
    "\n      mutation ManagePaymentPage_updatePayment(\n        $id: ID!\n        $input: UpdatePaymentInput!\n      ) {\n        updatePayment(id: $id, input: $input) {\n          id\n        }\n      }\n    ": types.ManagePaymentPage_UpdatePaymentDocument,
    "\n      query ManagePaymentsPage {\n        payments {\n          ...PaymentsList_payments\n        }\n      }\n    ": types.ManagePaymentsPageDocument,
    "\n      query PaymentCheck($paymentId: ID!) {\n        payment(id: $paymentId) {\n          id\n          status\n        }\n      }\n    ": types.PaymentCheckDocument,
    "\n      fragment PaymentsList_payments on Payment {\n        id\n        date\n        amount\n        offer\n        invoice\n        user {\n          name\n        }\n      }\n    ": types.PaymentsList_PaymentsFragmentDoc,
    "\n      mutation AddMemberPage_inviteMember($input: InviteMemberInput!) {\n        inviteMember(input: $input)\n      }\n    ": types.AddMemberPage_InviteMemberDocument,
    "\n        query InvitesPage {\n          membershipInvites {\n            id\n            email\n            date\n          }\n        }\n      ": types.InvitesPageDocument,
    "\n      mutation InvitesPage_deleteInvite($id: ID!) {\n        deleteMembershipInvite(id: $id)\n      }\n    ": types.InvitesPage_DeleteInviteDocument,
    "\n      query ManageMemberPageQuery($id: ID!, $canManagePlans: Boolean!, $canManagePayments: Boolean!) {\n        member(id: $id) {\n          id\n          name\n          email\n          avatarUrl\n          membership {\n            id\n          }\n          ...MemberDetails_member\n          ...MemberGroups_member\n          ...MemberPlans_member @include(if: $canManagePlans)\n          ...MemberPayments_member @include(if: $canManagePayments)\n        }\n      }\n    ": types.ManageMemberPageQueryDocument,
    "\n      mutation ManageMemberPage_deleteMembership($id: ID!) {\n        deleteMembership(id: $id)\n      }\n    ": types.ManageMemberPage_DeleteMembershipDocument,
    "\n      fragment MemberDetails_member on User {\n        id\n        name\n        membership {\n          id\n          data\n        }\n      }\n    ": types.MemberDetails_MemberFragmentDoc,
    "\n      mutation MemberDetails_updateMembership(\n        $id: ID!\n        $input: UpdateMembershipInput!\n      ) {\n        updateMembership(id: $id, input: $input) {\n          id\n        }\n      }\n    ": types.MemberDetails_UpdateMembershipDocument,
    "\n      mutation MemberGroups_updateMembership(\n        $id: ID!\n        $input: UpdateMembershipInput!\n      ) {\n        updateMembership(id: $id, input: $input) {\n          id\n        }\n      }\n    ": types.MemberGroups_UpdateMembershipDocument,
    "\n      fragment MemberGroups_member on User {\n        id\n        name\n        membership {\n          id\n          groupIds\n          plans {\n            id\n            plan {\n              id\n              tenantGroups {\n                id\n                name\n              }\n            }\n          }\n        }\n      }\n    ": types.MemberGroups_MemberFragmentDoc,
    "\n      fragment MemberPayments_member on User {\n        id\n      }\n    ": types.MemberPayments_MemberFragmentDoc,
    "\n    query MemberPaymentsQuery($filter: FindPaymentsFilter) {\n      payments(filter: $filter) {\n        ...PaymentsList_payments\n      }\n    }\n  ": types.MemberPaymentsQueryDocument,
    "\n      query ChangePlanForm($id: ID!) {\n        plan(id: $id) {\n          periodEnd\n        }\n      }\n    ": types.ChangePlanFormDocument,
    "\n      fragment MemberPlans_member on User {\n        id\n        membership {\n          id\n          plans {\n            id\n            plan {\n              id\n              name\n              group {\n                id\n                name\n              }\n            }\n            validUntil\n            paymentMethod {\n              id\n              method\n            }\n          }\n        }\n      }\n    ": types.MemberPlans_MemberFragmentDoc,
    "\n      mutation MemberPlans_updatePlan(\n        $id: ID!\n        $input: UpdateMembershipPlanInput!\n      ) {\n        updateMembershipPlan(id: $id, input: $input) {\n          id\n        }\n      }\n    ": types.MemberPlans_UpdatePlanDocument,
    "\n      mutation MemberPlans_addPlan($id: ID!, $input: AddMembershipPlanInput!) {\n        addMembershipPlan(id: $id, input: $input) {\n          id\n        }\n      }\n    ": types.MemberPlans_AddPlanDocument,
    "\n      mutation MemberPlans_removePlan($id: ID!) {\n        removeMembershipPlan(id: $id) \n      }\n    ": types.MemberPlans_RemovePlanDocument,
    "\n      query ManageMembersPage($filter: UsersFilter) {\n        members(filter: $filter) {\n          id\n          name\n          membership {\n            groups {\n              id\n              name\n            }\n          }\n        }\n      }\n    ": types.ManageMembersPageDocument,
    "\n      query ManageMembersSettingsPage {\n        currentTenant {\n          id\n          memberPermissions\n        }\n      }\n    ": types.ManageMembersSettingsPageDocument,
    "\n    mutation ManageMembersSettingsPage_updateTenant($input: UpdateTenantInput!) {\n      updateTenant(input: $input) {\n        id\n        memberPermissions\n      }\n    }": types.ManageMembersSettingsPage_UpdateTenantDocument,
    "\n      query MembershipDataForm {\n        currentTenant {\n          membershipForm {\n            configuration\n          }\n        }\n      }\n    ": types.MembershipDataFormDocument,
    "\n      mutation CreatePlanGroupPage_createPlan($input: CreatePlanGroupInput!) {\n        createPlanGroup(input: $input) {\n          id\n        }\n      }\n    ": types.CreatePlanGroupPage_CreatePlanDocument,
    "\n      mutation CreatePlanPage_createPlan($input: CreatePlanInput!) {\n        createPlan(input: $input) {\n          id\n        }\n      }\n    ": types.CreatePlanPage_CreatePlanDocument,
    "\n      query ManagePlanGroupPage($id: ID!) {\n        planGroup(id: $id) {\n          id\n          name\n          nameTranslations {\n            ...TranslationFragment\n          }\n          description\n          descriptionTranslations {\n            ...TranslationFragment\n          }\n        }\n      }\n    ": types.ManagePlanGroupPageDocument,
    "\n      mutation ManagePlanGroupPage_updatePlan($id: ID!, $input: UpdatePlanGroupInput!) {\n        updatePlanGroup(id: $id, input: $input) {\n          id\n        }\n      }\n    ": types.ManagePlanGroupPage_UpdatePlanDocument,
    "\n      mutation ManagePlanGroupsPage_DeleteButton_deletePlan($id: ID!) {\n        deletePlanGroup(id: $id)\n      }\n    ": types.ManagePlanGroupsPage_DeleteButton_DeletePlanDocument,
    "\n      fragment ManagePlanGroupsPage_DeleteButton_planGroup on PlanGroup {\n        id\n        membersCount\n      }\n    ": types.ManagePlanGroupsPage_DeleteButton_PlanGroupFragmentDoc,
    "\n      query ManagePlanGroupsPage {\n        planGroups {\n          id\n          name\n          nameTranslations {\n            ...TranslationFragment\n          }\n          description\n          descriptionTranslations {\n            ...TranslationFragment\n          }\n\n          ...ManagePlanGroupsPage_DeleteButton_planGroup\n        }\n      }\n    ": types.ManagePlanGroupsPageDocument,
    "\n    query ManagePlanPage($id: ID!) {\n      plan(id: $id) {\n        id\n        groupId\n        name\n        nameTranslations {\n          ...TranslationFragment\n        }\n        description\n        descriptionTranslations {\n          ...TranslationFragment\n        }\n        tenantGroupIds\n        price\n        paymentPeriodicity\n        allowPaymentBefore\n        paymentTolerance\n        alignToCalendar\n        chargeFullPeriod\n        allowInAppSubscription\n      }\n    }\n      ": types.ManagePlanPageDocument,
    "\n      mutation ManagePlanPage_updatePlan($id: ID!, $input: UpdatePlanInput!) {\n        updatePlan(id: $id, input: $input) {\n          id\n        }\n      }\n    ": types.ManagePlanPage_UpdatePlanDocument,
    "\n      mutation ManagePlansPage_deletePlan($id: ID!) {\n        deletePlan(id: $id)\n      }\n    ": types.ManagePlansPage_DeletePlanDocument,
    "\n      fragment ManagePlansPage_DeleteButton_plan on Plan {\n        id\n        membersCount\n      }\n    ": types.ManagePlansPage_DeleteButton_PlanFragmentDoc,
    "\n      query ManagePlansPage($filter: FindPlansFilter) {\n        plans(filter: $filter) {\n          id\n          name\n          position\n\n          ...ManagePlansPage_DeleteButton_plan\n        }\n      }\n    ": types.ManagePlansPageDocument,
    "\n      mutation ManagePlansPage_updatePlans($positions: [ID!]!) {\n        updatePlansPosition(positions: $positions) {\n          id\n          position\n        }\n      }\n    ": types.ManagePlansPage_UpdatePlansDocument,
    "\n      query PayPlanPage($id: ID!) {\n        plan(id: $id) {\n          id\n          groupId\n          ...PlanCard_plan\n        }\n        currentUser {\n          id\n          membership {\n            id\n            plans {\n              id\n              planId\n              nextPaymentStart\n              nextPaymentDate\n            }\n          }\n        }\n      }\n    ": types.PayPlanPageDocument,
    "\n      fragment PlanCard_plan on Plan {\n        id\n        name\n        description\n        price\n        paymentPeriodicity\n      }\n    ": types.PlanCard_PlanFragmentDoc,
    "\n      query PlanGroupSelect {\n        planGroups {\n          id\n          name\n        }\n      }\n    ": types.PlanGroupSelectDocument,
    "\n    query PlanPaymentMessage {\n      currentUser {\n        membership {\n          plans {\n            id\n            nextPaymentDate\n            nextPaymentStart\n            nextPaymentEnd\n            paymentMethodId\n            plan {\n              id\n              name\n            }\n          }\n        }\n      }\n    }": types.PlanPaymentMessageDocument,
    "\n      query PlanPicker {\n        planGroups {\n          id\n          name\n          description\n          plans {\n            id\n            ...PlanCard_plan\n          }\n        }\n\n        currentUser {\n          membership {\n            plans {\n              plan {\n                id\n                groupId\n              }\n            }\n          }\n        }\n      }\n    ": types.PlanPickerDocument,
    "\n      query PlanInput($filter: FindPlansFilter) {\n        plans(filter: $filter) {\n          id\n          name\n          group {\n            id\n            name\n          }\n        }\n      }\n    ": types.PlanInputDocument,
    "\n      mutation CheckoutModal_subscribePlan($plan: ID!, $paymentMethod: ID!, $period: DateTime) {\n        subscribePlan(plan: $plan, userPaymentMethod: $paymentMethod, period: $period) {\n          success\n          paymentId\n        }\n      }\n    ": types.CheckoutModal_SubscribePlanDocument,
    "\n      query PlanSubscriptionSummary($id: ID!, $period: DateTime) {\n        plan(id: $id) {\n          id\n          groupId\n          name\n          price\n          priceNow(period: $period)\n          totalPrice(period: $period)\n          paymentPeriodicity\n          periodStart(period: $period)\n          periodEnd(period: $period)\n        }\n      }\n    ": types.PlanSubscriptionSummaryDocument,
    "\n      query PlanSubscriptionSummary_currentUser($period: DateTime) {\n        currentUser {\n          id\n          membership {\n            id\n            plans {\n              id\n              prorationDiscount(period: $period)\n              plan {\n                groupId\n              }\n            }\n          }\n        }\n      }\n    ": types.PlanSubscriptionSummary_CurrentUserDocument,
    "\n      mutation CommentsList_deleteComment($id: ID!) {\n        deleteComment(id: $id)\n      }\n    ": types.CommentsList_DeleteCommentDocument,
    "\n      fragment CommentsList_comments on Comment {\n        id\n        text\n        createdAt\n        user {\n          id\n          name\n          avatarUrl\n        }\n        reactions {\n          id\n          type\n          ...ReactionsBar_reactions\n        }\n      }\n    ": types.CommentsList_CommentsFragmentDoc,
    "\n      fragment ReactionsBar_reactions on Reaction {\n        id\n        type\n        user {\n          id\n        }\n      }\n    ": types.ReactionsBar_ReactionsFragmentDoc,
    "\n      mutation ReactionsBar_createReaction($input: CreateReactionInput!) {\n        createReaction(input: $input) {\n          id\n          type\n        }\n      }\n    ": types.ReactionsBar_CreateReactionDocument,
    "\n      mutation ReactionsBar_deleteReaction($id: ID!) {\n        deleteReaction(id: $id)\n      }\n    ": types.ReactionsBar_DeleteReactionDocument,
    "\n      query ReactionsList($subject: ReactionSubject!, $id: ID!) {\n        reactions(subject: $subject, id: $id) {\n          id\n          user {\n            name\n            avatarUrl\n          }\n        }\n      }\n    ": types.ReactionsListDocument,
    "\n      fragment BookingCard_booking on SpaceBooking {\n        id\n        startDate\n        endDate\n        space {\n          id\n          name\n        }\n      }\n    ": types.BookingCard_BookingFragmentDoc,
    "\n    mutation BookingCard_cancelBooking($id: ID!) {\n      deleteSpaceBooking(id: $id) {\n        id\n      }\n    }\n  ": types.BookingCard_CancelBookingDocument,
    "\n      mutation CreateSpacePageMutation($input: CreateSpaceInput!) {\n        createSpace(input: $input) {\n          id\n        }\n      }\n    ": types.CreateSpacePageMutationDocument,
    "\n      query ManageSpacePageQuery($id: ID!) {\n        space(id: $id) {\n          id\n          name\n          capacity\n          bookable\n          description\n          maxBookingTime\n          slotSize\n          bookableDuringClasses\n          seats {\n            id\n            name \n          }\n          schedule {\n            weekdays\n            start \n            end\n          }\n        }\n      }\n    ": types.ManageSpacePageQueryDocument,
    "\n      mutation ManageSpacePageUpdateMutation(\n        $id: ID!\n        $input: UpdateSpaceInput!\n      ) {\n        updateSpace(id: $id, input: $input) {\n          id\n        }\n      }\n    ": types.ManageSpacePageUpdateMutationDocument,
    "\n      query ManageSpacesPageQuery {\n        spaces {\n          id\n          name\n        }\n      }\n    ": types.ManageSpacesPageQueryDocument,
    "\n      mutation ManageSpacesPageDeleteMutation($id: ID!) {\n        deleteSpace(id: $id)\n      }\n    ": types.ManageSpacesPageDeleteMutationDocument,
    "\n      fragment BookingModal_space on Space {\n        id\n        name\n        slotSize\n\n        ...isSpaceAvailable_space\n        ...maxBookingDuration_space\n      }\n    ": types.BookingModal_SpaceFragmentDoc,
    "\n      mutation SpacePage_bookSpace($input: CreateSpaceBookingInput!) {\n        createSpaceBooking(input: $input) {\n          id\n        }\n      }\n    ": types.SpacePage_BookSpaceDocument,
    "\n      query SpacePage($id: ID!, $from: DateTime!, $to: DateTime!) {\n        space(id: $id) {\n          id\n          name\n        \n          ...BookingModal_space\n        }\n      }\n    ": types.SpacePageDocument,
    "\n      query SpaceSchedule($id: ID!, $from: DateTime!, $to: DateTime!) {\n        space(id: $id) {\n          id\n          capacity\n          slotSize\n          bookable\n          maxBookingTime\n          schedule {\n            weekdays\n            start\n            end\n          }\n          classes {\n            id\n            name\n            startDate\n            endDate\n            type {\n              id\n              name\n            }\n          }\n          bookings(filter:  {\n             from: $from\n             to: $to\n          }) {\n            id\n            startDate\n            endDate\n            user {\n              id\n            }\n          }\n        \n         ...isSpaceAvailable_space\n        }\n      }\n    ": types.SpaceScheduleDocument,
    "\n      query SpaceInput {\n        spaces {\n          id\n          name\n          capacity\n        }\n      }\n    ": types.SpaceInputDocument,
    "\n      query SpacesPage {\n        spaces {\n          id\n          name\n          bookable\n        }\n      }\n    ": types.SpacesPageDocument,
    "\n      fragment isSpaceAvailable_space on Space {\n        id\n        bookable\n        schedule {\n          weekdays\n          start\n          end\n        }\n        classes {\n          startDate\n          endDate\n          capacity\n        }\n        capacity\n        bookableDuringClasses\n        slotSize\n        maxBookingTime\n        bookings(filter: { from: $from, to: $to }) {\n          startDate\n          endDate\n          user {\n            id\n          }\n        }\n      }\n    ": types.IsSpaceAvailable_SpaceFragmentDoc,
    "\n      fragment maxBookingDuration_space on Space {\n        slotSize\n        maxBookingTime\n        ...isSpaceAvailable_space\n      }\n    ": types.MaxBookingDuration_SpaceFragmentDoc,
    "\n    mutation ManageTenant_LogoUploadPage_upload($name: String!, $logo: Upload!) {\n        uploadLogo(name: $name, file: $logo) \n    }": types.ManageTenant_LogoUploadPage_UploadDocument,
    "\n      mutation CreateFormPage_createForm($input: CreateFormInput!) {\n        createForm(input: $input) {\n          id\n        }\n      }\n    ": types.CreateFormPage_CreateFormDocument,
    "\n      mutation CreateTenantPage_create($input: CreateTenantInput!) {\n        createTenant(input: $input) {\n          id\n        }\n      }\n    ": types.CreateTenantPage_CreateDocument,
    "\n      query InvitationPage($id: ID!) {\n        currentUser {\n          memberships {\n            tenantId\n          }\n        }\n        token(id: $id) {\n          id\n          type\n          tenant {\n            id\n            name\n            slug\n            membershipForm {\n              configuration\n            }\n          }\n        }\n      }\n    ": types.InvitationPageDocument,
    "\n      mutation InvitationPage_join($input: JoinInput!) {\n        join(input: $input) {\n          id\n        }\n      }\n    ": types.InvitationPage_JoinDocument,
    "\n      query ManageContentPacksPage {\n        contentPacks {\n          id\n          name\n          description\n          updatedAt\n        }\n\n        currentTenant {\n          id\n          contentPacks {\n            id\n            updatedAt\n          }\n        }\n      }\n    ": types.ManageContentPacksPageDocument,
    "\n      mutation ManageContentPacks_installContentPack($id: ID!) {\n        installContentPack(id: $id)\n      }\n    ": types.ManageContentPacks_InstallContentPackDocument,
    "\n      mutation ManageContentPacks_uninstallContentPack($id: ID!) {\n        uninstallContentPack(id: $id)\n      }\n    ": types.ManageContentPacks_UninstallContentPackDocument,
    "\n      query ManageFormPage($id: ID!) {\n        form(id: $id) {\n          id\n          name\n          configuration\n        }\n      }\n    ": types.ManageFormPageDocument,
    "\n      mutation ManageFormPage_updateForm($id: ID!, $input: UpdateFormInput!) {\n        updateForm(id: $id, input: $input) {\n          id\n        }\n      }\n    ": types.ManageFormPage_UpdateFormDocument,
    "\n      mutation ManageFormsPage_deleteForm($id: ID!) {\n        deleteForm(id: $id)\n      }\n    ": types.ManageFormsPage_DeleteFormDocument,
    "\n      query ManageFormsPage {\n        forms {\n          id\n          name\n        }\n        currentTenant {\n          membershipFormId\n        }\n      } \n    ": types.ManageFormsPageDocument,
    "\n      query ManageGroupPage($id: ID!) {\n        group(id: $id) {\n          id\n          name\n          isProtected\n          permissions\n        }\n      }\n    ": types.ManageGroupPageDocument,
    "\n      mutation ManageGroupPage_updateGroup(\n        $id: ID!\n        $input: UpdateGroupInput!\n      ) {\n        updateGroup(id: $id, input: $input) {\n          id\n        }\n      }\n    ": types.ManageGroupPage_UpdateGroupDocument,
    "\n      mutation ManageGroupsPage_deleteGroup($id: ID!) {\n        deleteGroup(id: $id)\n      }\n    ": types.ManageGroupsPage_DeleteGroupDocument,
    "\n      query ManageGroupsPage {\n        groups {\n          id\n          name\n          isProtected\n        }\n      }\n    ": types.ManageGroupsPageDocument,
    "\n      mutation ManageGroupsPage_createGroup($input: CreateGroupInput!) {\n        createGroup(input: $input) {\n          id\n        }\n      }\n    ": types.ManageGroupsPage_CreateGroupDocument,
    "\n    mutation ManageTenant_LocalizationTab_updateTenant($input: UpdateTenantInput!) {\n        updateTenant(input: $input) {\n            id\n        }\n    }": types.ManageTenant_LocalizationTab_UpdateTenantDocument,
    "\n    mutation ManageTenant_GeneralTab_updateTenant($input: UpdateTenantInput!) {\n        updateTenant(input: $input) {\n            id\n        }\n    }": types.ManageTenant_GeneralTab_UpdateTenantDocument,
    "\n      query ManageTenantsPage($filter: TenantsFilter) {\n        tenants(filter: $filter) {\n          id\n          name\n        }\n      }\n    ": types.ManageTenantsPageDocument,
    "\n      mutation UpdateMembership($id: ID!, $input: UpdateMembershipInput!) {\n        updateMembership(id: $id, input: $input) {\n          id\n        }\n      }\n    ": types.UpdateMembershipDocument,
    "\n      mutation UpdateNotificationSettings_update(\n        $input: UpdateNotificationSettingsInput!\n      ) {\n        updateNotificationSettings(input: $input)\n      }\n    ": types.UpdateNotificationSettings_UpdateDocument,
    "\n    query NotificationSettings {\n      currentUser {\n        id\n        notificationSettings\n      }\n    }": types.NotificationSettingsDocument,
    "\n      query PaymentMethodPage($id: ID!) {\n        userPaymentMethod(id: $id) {\n          id\n          method\n          name\n          email\n          phone\n          iban\n          status\n          membershipPlans {\n            id\n            plan {\n                id\n                name\n            }\n          }\n        }\n      }\n    ": types.PaymentMethodPageDocument,
    "\n      mutation PaymentMethodPage_deletePaymentMethod($id: ID!) {\n        deleteUserPaymentMethod(id: $id) \n      }\n    ": types.PaymentMethodPage_DeletePaymentMethodDocument,
    "\n      mutation PaymentMethodPage_updateMembershipPlan(\n        $id: ID!\n        $input: UpdateMembershipPlanInput!\n      ) {\n        updateMembershipPlan(id: $id, input: $input) {\n          id\n          paymentMethodId\n        }\n      }\n    ": types.PaymentMethodPage_UpdateMembershipPlanDocument,
    "\n      query PaymentsPage($user: ID!, $now: DateTime!) {\n        futurePayments: payments(filter: { date: { gt: $now }, userId: $user }) {\n          id\n          ...PaymentsList_payments\n        }\n\n        payments(filter: { date: { lte: $now }, userId: $user }) {\n          id\n          ...PaymentsList_payments\n        }\n      }\n    ": types.PaymentsPageDocument,
    "\n      query PlansPage {\n        currentUser {\n          membership {\n            plans {\n              details: plan {\n                id\n                name\n                group {\n                  id\n                  name\n                }\n              }\n              id\n              nextPaymentDate\n              nextPaymentStart\n              nextPaymentEnd\n              validUntil\n              paymentMethodId\n            }\n          }\n        }\n      }\n    ": types.PlansPageDocument,
    "\n      mutation PlansPage_updateMembershipPlan(\n        $id: ID!,\n        $input: UpdateMembershipPlanInput!\n      ) {\n        updateMembershipPlan(id: $id, input: $input) {\n          id\n          paymentMethodId\n        }\n      }\n    ": types.PlansPage_UpdateMembershipPlanDocument,
    "\n     query PaymentMethods {\n        userPaymentMethods {\n            id\n            method\n            name\n            email\n            phone\n            iban\n            status\n            membershipPlans {\n              id\n              plan {\n                id\n                name\n                tenant {\n                  id\n                  name\n                }\n              }\n            }\n        }\n     }\n    ": types.PaymentMethodsDocument,
    "\n    mutation PaymentMethods_createUserPaymentMethod($input: CreateUserPaymentMethodInput!) {\n      createUserPaymentMethod(input: $input) {\n        id\n      }\n    }\n  ": types.PaymentMethods_CreateUserPaymentMethodDocument,
    "\n      mutation ProfilePageUpdateUserMutation($input: UpdateUserInput!) {\n        updateUser(input: $input) {\n          id\n          name\n          email\n          avatarUrl\n        }\n      }\n    ": types.ProfilePageUpdateUserMutationDocument,
    "\n      mutation ProfilePage_deleteAccount($password: String!) {\n        deleteAccount(password: $password)\n      }\n    ": types.ProfilePage_DeleteAccountDocument,
    "\n      mutation EmailTab_changeEmail($email: String!) {\n        changeEmail(email: $email)\n      }\n    ": types.EmailTab_ChangeEmailDocument,
    "\n      query EmailTab {\n        currentUser {\n          email\n          hasPassword\n        }\n      }\n    ": types.EmailTabDocument,
    "\n      mutation PasswordTab_forgotPassword($email: String!) {\n        forgotPassword(email: $email)\n      }\n    ": types.PasswordTab_ForgotPasswordDocument,
    "\n      mutation PasswordTab_changePassword($input: ChangePasswordInput!) {\n        changePassword(input: $input)\n      }\n    ": types.PasswordTab_ChangePasswordDocument,
    "\n      query PasswordTab {\n        currentUser {\n          email\n          hasPassword\n        }\n      }\n    ": types.PasswordTabDocument,
    "\n      query CheckoutModal_userPaymentMethods {\n        userPaymentMethods {\n          id\n          method\n          name\n          email\n          phone\n          iban\n          status\n        }\n      }\n    ": types.CheckoutModal_UserPaymentMethodsDocument,
    "\n      mutation UserPaymentMethodSelect_createUserPaymentMethod(\n        $input: CreateUserPaymentMethodInput!\n      ) {\n        createUserPaymentMethod(input: $input) {\n          id\n          status\n        }\n      }\n    ": types.UserPaymentMethodSelect_CreateUserPaymentMethodDocument,
    "\n      mutation VerifyEmail($token: String!, $type: String!) {\n        verifyEmail(token: $token, type: $type) {\n          success\n          token\n        }\n      }\n    ": types.VerifyEmailDocument,
    "\n      mutation CreateScoreCategoryPage_createScoreCategory($input: CreateScoreCategoryInput!) {\n        createScoreCategory(input: $input) {\n          id\n        }\n      }\n    ": types.CreateScoreCategoryPage_CreateScoreCategoryDocument,
    "\n      mutation CreateWorkoutCategoryPage_createWorkoutCategory($input: CreateWorkoutCategoryInput!) {\n        createWorkoutCategory(input: $input) {\n          id\n        }\n      }\n    ": types.CreateWorkoutCategoryPage_CreateWorkoutCategoryDocument,
    "\n      query WorkoutSearch_getWorkouts(\n        $filter: GetWorkoutsFilter!\n        $pagination: PaginationInput!\n      ) {\n        workouts(filter: $filter, pagination: $pagination) {\n          id\n          name\n          description\n          date\n        }\n      }\n    ": types.WorkoutSearch_GetWorkoutsDocument,
    "\n      query CreateWorkoutOccurrencePage {\n        currentTenant {\n          id\n          workoutsSettings\n        }\n      }\n    ": types.CreateWorkoutOccurrencePageDocument,
    "\n      mutation CreateWorkoutOcurrence($input: CreateWorkoutOccurrenceInput!) {\n        createWorkoutOccurrence(input: $input) {\n          id\n        }\n      }\n    ": types.CreateWorkoutOcurrenceDocument,
    "\n      query CreateWorkoutOccurencePage_getWorkout($id: ID!) {\n        workout(id: $id) {\n          id\n          parent {\n            id\n            name\n            date\n            isFeatured\n          }\n          scoreType\n          name\n          date\n          isFeatured\n          coachesNotes\n          nameTranslations {\n            ...TranslationFragment\n          }\n          description\n          descriptionTranslations {\n            ...TranslationFragment\n          }\n          scoreCategoriesIds\n        }\n      }\n    ": types.CreateWorkoutOccurencePage_GetWorkoutDocument,
    "\n      query CreateWorkoutPage {\n        currentTenant {\n          id\n          workoutsSettings\n        }\n      }\n    ": types.CreateWorkoutPageDocument,
    "\n      mutation CreateWorkout($input: CreateWorkoutInput!) {\n        createWorkout(input: $input) {\n          id\n        }\n      }\n    ": types.CreateWorkoutDocument,
    "\n      query ManageScoreCategoriesPage {\n        scoreCategories {\n          id\n          name\n        }\n      }\n    ": types.ManageScoreCategoriesPageDocument,
    "\n      query ManageScoreCategoryPage($id: ID!) {\n        scoreCategory(id: $id) {\n          id\n          name\n          nameTranslations {\n            ...TranslationFragment\n          }\n        }\n      }\n    ": types.ManageScoreCategoryPageDocument,
    "\n      mutation ManageScoreCategoryPage_updateScoreCategory($id: ID!, $input: UpdateScoreCategoryInput!) {\n        updateScoreCategory(id: $id, input: $input) {\n          id\n        }\n      }\n    ": types.ManageScoreCategoryPage_UpdateScoreCategoryDocument,
    "\n      mutation ManageScoreCategoryPage_deleteScoreCategory($id: ID!) {\n        deleteScoreCategory(id: $id)\n      }\n    ": types.ManageScoreCategoryPage_DeleteScoreCategoryDocument,
    "\n      query ManageWorkoutCategoryPage($id: ID!) {\n        workoutCategory(id: $id) {\n          id\n          name\n          nameTranslations {\n            ...TranslationFragment\n          }\n          parentId\n        }\n      }\n    ": types.ManageWorkoutCategoryPageDocument,
    "\n      mutation ManageWorkoutCategoryPage_updateWorkoutCategory($id: ID!, $input: UpdateWorkoutCategoryInput!) {\n        updateWorkoutCategory(id: $id, input: $input) {\n          id\n        }\n      }\n    ": types.ManageWorkoutCategoryPage_UpdateWorkoutCategoryDocument,
    "\n      mutation ManageWorkoutCategoryPage_deleteCategory($id: ID!) {\n        deleteWorkoutCategory(id: $id)\n      }\n    ": types.ManageWorkoutCategoryPage_DeleteCategoryDocument,
    "\n      mutation ManageWorkoutPage_deleteWorkout($id: ID!) {\n        deleteWorkout(id: $id)\n      }\n    ": types.ManageWorkoutPage_DeleteWorkoutDocument,
    "\n      query ManageWorkoutDrawer($id: ID!) {\n        workout(id: $id) {\n          id\n          name\n          nameTranslations {\n            ...TranslationFragment\n          }\n          description\n          descriptionTranslations {\n            ...TranslationFragment\n          }\n          categoryId\n          classTypesIds\n          scoreCategoriesIds\n          usersIds\n          scoreType\n          isFeatured\n          coachesNotes\n          date\n          availability\n        }\n      }\n    ": types.ManageWorkoutDrawerDocument,
    "\n      mutation ManageWorkoutDrawer_createWorkout($input: CreateWorkoutOccurrenceInput!) {\n        createWorkoutOccurrence(input: $input) {\n          id\n        }\n      }\n    ": types.ManageWorkoutDrawer_CreateWorkoutDocument,
    "\n      mutation ManageWorkoutDrawer_updateWorkout($id: ID!, $input: UpdateWorkoutInput!) {\n        updateWorkout(id: $id, input: $input) {\n          id\n        }\n      }\n    ": types.ManageWorkoutDrawer_UpdateWorkoutDocument,
    "\n      query ManageWorkoutPage($id: ID!) {\n        workout(id: $id) {\n          id\n          name\n          nameTranslations {\n            ...TranslationFragment\n          }\n          description\n          descriptionTranslations {\n            ...TranslationFragment\n          }\n          categoryId\n          classTypesIds\n          scoreCategoriesIds\n          usersIds\n          scoreType\n          repetitions\n          isFeatured\n          coachesNotes\n          date\n          availability\n        }\n      }\n    ": types.ManageWorkoutPageDocument,
    "\n      mutation ManageWorkoutPage_updateWorkout($id: ID!, $input: UpdateWorkoutInput!) {\n        updateWorkout(id: $id, input: $input) {\n          id\n        }\n      }\n    ": types.ManageWorkoutPage_UpdateWorkoutDocument,
    "\n      query ManageWorkoutsSettingsPage {\n        currentTenant {\n          id\n          workoutsSettings\n        }\n      }\n    ": types.ManageWorkoutsSettingsPageDocument,
    "\n    mutation ManageWorkoutsSettingsPage_updateTenant($input: UpdateTenantInput!) {\n      updateTenant(input: $input) {\n        id\n        workoutsSettings\n      }\n    }": types.ManageWorkoutsSettingsPage_UpdateTenantDocument,
    "\n      query RecordPage($id: ID!, $user: ID!) {\n        workout(id: $id) {\n          id\n          name\n          description\n          ...WorkoutBestScore_workout\n          ...WorkoutScoreHistory\n        }\n      }\n    ": types.RecordPageDocument,
    "\n      query RegisterScorePage($id: ID!, $user: ID!) {\n        workout(id: $id) {\n          id\n          parentId\n          name\n          description\n          scoreType\n          repetitions\n          scoreCategories {\n            id\n            name\n          }\n          scores(userId: $user) {\n            id\n            categoryId\n            scoreIntA\n            scoreIntB\n            notes\n          }\n        }\n      }\n    ": types.RegisterScorePageDocument,
    "\n    mutation RegisterScorePage_setWorkoutScore($input: SetWorkoutScoreInput!) {\n      setWorkoutScore(input: $input) {\n        id\n      }\n    }\n  ": types.RegisterScorePage_SetWorkoutScoreDocument,
    "\n      query ScoreCategorySelect {\n        scoreCategories {\n          id\n          name\n        }\n      }\n    ": types.ScoreCategorySelectDocument,
    "\n      mutation ScoreCategorySelect_createScoreCategory($input: CreateScoreCategoryInput!) {\n        createScoreCategory(input: $input) {\n          id\n          name\n        }\n      }\n    ": types.ScoreCategorySelect_CreateScoreCategoryDocument,
    "\n      fragment WorkoutBestScore_workout on Workout {\n        scoreType\n        scoreCategories {\n          id\n          name\n        }\n        scores(userId: $user) {\n          id\n          date\n          scoreIntA\n          scoreIntB\n          scoreBool\n          scoreText\n          category {\n            id\n            name\n          }\n        }\n      }\n    ": types.WorkoutBestScore_WorkoutFragmentDoc,
    "\n      query WorkoutCategoriesTable($parent: String) {\n        workoutCategories(filter: { parentId: $parent }) {\n          id\n          name\n          parentId\n          order\n        }\n      }\n    ": types.WorkoutCategoriesTableDocument,
    "\n      mutation WorkoutCategoriesTable_updateCategory($id: ID!, $input: UpdateWorkoutCategoryInput!) {\n        updateWorkoutCategory(id: $id, input: $input) {\n          id\n          parentId\n          order\n        }\n      }\n    ": types.WorkoutCategoriesTable_UpdateCategoryDocument,
    "\n      query WorkoutCategorySelect {\n        workoutCategories {\n          id\n          name\n          order\n          parentId\n        }\n      }\n    ": types.WorkoutCategorySelectDocument,
    "\n      query WorkoutPage($id: ID!, $canReadComments: Boolean!) {\n        workout(id: $id) {\n          id\n          name\n          description\n          scoreType\n          coachesNotes\n          comments @include(if: $canReadComments) {\n            ...CommentsCard_comments\n          }\n\n          ...WorkoutScores_workout\n        \n        }\n      }\n    ": types.WorkoutPageDocument,
    "\n    fragment WorkoutScoreHistory on Workout {\n      scoreType\n      scoreCategories {\n        id\n        name\n      }\n      scores(userId: $user) {\n        id\n        workoutId\n        date\n        scoreIntA\n        scoreIntB\n        category {\n          id\n          name\n        }\n        notes\n        tenant {\n          id\n          name\n        }\n      }\n    }\n    ": types.WorkoutScoreHistoryFragmentDoc,
    "\n      mutation WorkoutScoreHistory_deleteScore($id: ID!) {\n        deleteWorkoutScore(id: $id)\n      }\n    ": types.WorkoutScoreHistory_DeleteScoreDocument,
    "\n      mutation WorkoutScores_deleteScore($id: ID!) {\n        deleteWorkoutScore(id: $id)\n      }\n    ": types.WorkoutScores_DeleteScoreDocument,
    "\n      fragment WorkoutScores_workout on Workout {\n        id\n        scoreType\n        scoreCategories {\n          id\n          name\n        }\n        scores {\n          id\n          categoryId\n          user {\n            id\n            name\n          }\n          scoreIntA\n          reactions {\n            ...ReactionsBar_reactions\n          }\n        }\n      }\n    ": types.WorkoutScores_WorkoutFragmentDoc,
    "\n      query WorkoutSelect {\n        workoutCategories {\n          id\n          name\n          order\n          parentId\n          workouts {\n            id\n            name\n          }\n        }\n      }\n    ": types.WorkoutSelectDocument,
    "\n      query WorkoutsCalendar($filter: GetWorkoutsFilter) {\n        workouts(filter: $filter) {\n          id\n          name\n          date\n        }\n      }\n    ": types.WorkoutsCalendarDocument,
    "\n      query WorkoutsList {\n        workoutCategories {\n          id\n          parentId\n          name\n          order\n        } \n      }\n    ": types.WorkoutsListDocument,
    "\n      query WorkoutCategoryList($categories: [String!], $includeScores: Boolean!) {\n        workouts(filter: { categories: $categories, isFeatured: true }) {\n          id\n          name\n          categoryId\n          scoreType\n          bestScore @include(if: $includeScores) {\n            id\n            category {\n              id\n              name\n            }\n            scoreIntA\n            scoreIntB\n          }\n        }\n      }\n    ": types.WorkoutCategoryListDocument,
    "\n      query WorkoutsPage($filter: GetWorkoutsFilter) {\n        workouts(filter: $filter) {\n          id\n          name\n          description\n          scoreType\n        }\n      }\n    ": types.WorkoutsPageDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AgendaQuery($from: DateTime!, $to: DateTime!) {\n    classes(input: {\n      from: $from\n      to: $to\n      isRegistered: true\n    }) {\n      id\n      name\n      startDate\n      ...ClassCardFragment\n    }\n    spaceBookings(filter: {\n      from: $from\n      to: $to\n    }) {\n      id\n      startDate\n\n      ...BookingCard_booking\n    }\n  }\n"): (typeof documents)["\n  query AgendaQuery($from: DateTime!, $to: DateTime!) {\n    classes(input: {\n      from: $from\n      to: $to\n      isRegistered: true\n    }) {\n      id\n      name\n      startDate\n      ...ClassCardFragment\n    }\n    spaceBookings(filter: {\n      from: $from\n      to: $to\n    }) {\n      id\n      startDate\n\n      ...BookingCard_booking\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query HomePageQuery {\n        currentUser {\n          memberships {\n            tenant {\n              id\n              name\n              slug\n              logoUrl\n            }\n          }\n        }\n      }\n    "): (typeof documents)["\n      query HomePageQuery {\n        currentUser {\n          memberships {\n            tenant {\n              id\n              name\n              slug\n              logoUrl\n            }\n          }\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      fragment TranslationFragment on Translation {\n        en\n        pt\n      }\n      "): (typeof documents)["\n      fragment TranslationFragment on Translation {\n        en\n        pt\n      }\n      "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query TenantProviderQuery {\n        currentTenant {\n          id\n          name\n          slug\n          logoUrl\n          languages\n          defaultLanguage\n          inAppInvoices\n          inAppPaymentsEnabled\n          hasBookings\n          hasPlans\n          brandColor\n          timezone\n          classTypes {\n            id\n            name\n            color\n          }\n          locations {\n            id\n            name\n          }\n        }\n      }\n    "): (typeof documents)["\n      query TenantProviderQuery {\n        currentTenant {\n          id\n          name\n          slug\n          logoUrl\n          languages\n          defaultLanguage\n          inAppInvoices\n          inAppPaymentsEnabled\n          hasBookings\n          hasPlans\n          brandColor\n          timezone\n          classTypes {\n            id\n            name\n            color\n          }\n          locations {\n            id\n            name\n          }\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n        query UserProviderQuery {\n          currentUser {\n            id\n            name\n            email\n            avatarUrl\n            language\n            isAndroidTester\n            membership {\n              id\n              data\n            }\n          }\n          currentAbility\n        }\n      "): (typeof documents)["\n        query UserProviderQuery {\n          currentUser {\n            id\n            name\n            email\n            avatarUrl\n            language\n            isAndroidTester\n            membership {\n              id\n              data\n            }\n          }\n          currentAbility\n        }\n      "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation LoginPage_checkAccount($email: String!) {\n        checkAccount(email: $email)\n      }\n    "): (typeof documents)["\n      mutation LoginPage_checkAccount($email: String!) {\n        checkAccount(email: $email)\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation LoginPage_signIn($input: SignInInput!) {\n        signIn(input: $input) {\n          accessToken\n          refreshToken\n        }\n      }\n    "): (typeof documents)["\n      mutation LoginPage_signIn($input: SignInInput!) {\n        signIn(input: $input) {\n          accessToken\n          refreshToken\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation LoginPage_forgotPassword($email: String!) {\n        forgotPassword(email: $email)\n      }\n    "): (typeof documents)["\n      mutation LoginPage_forgotPassword($email: String!) {\n        forgotPassword(email: $email)\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query LoginPage_invite($id: ID!) {\n      token(id: $id) {\n        id\n        data\n      }\n    }"): (typeof documents)["\n    query LoginPage_invite($id: ID!) {\n      token(id: $id) {\n        id\n        data\n      }\n    }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ResetPasswordPage_resetPassword(\n        $code: String!\n        $password: String!\n      ) {\n        resetPassword(code: $code, password: $password)\n      }\n    "): (typeof documents)["\n      mutation ResetPasswordPage_resetPassword(\n        $code: String!\n        $password: String!\n      ) {\n        resetPassword(code: $code, password: $password)\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation SignUp($input: SignUpInput!) {\n        signUp(input: $input) {\n          result \n          accessToken\n          refreshToken\n        }\n      }\n    "): (typeof documents)["\n      mutation SignUp($input: SignUpInput!) {\n        signUp(input: $input) {\n          result \n          accessToken\n          refreshToken\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      fragment ClassCardFragment on Class {\n        id\n        name\n        startDate\n        endDate\n        maxAttendees\n        registered\n        inQueue\n        capacity\n        location {\n          id\n          name\n        }\n        registrations {\n          id\n        }\n        space {\n          name\n        }\n        coaches {\n          id\n          name\n          avatarUrl\n        }\n      }\n    "): (typeof documents)["\n      fragment ClassCardFragment on Class {\n        id\n        name\n        startDate\n        endDate\n        maxAttendees\n        registered\n        inQueue\n        capacity\n        location {\n          id\n          name\n        }\n        registrations {\n          id\n        }\n        space {\n          name\n        }\n        coaches {\n          id\n          name\n          avatarUrl\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      fragment RegistrationsList_cls on Class {\n        id\n        registrations {\n          id\n          user {\n            id\n            name\n            avatarUrl\n          }\n          attendance\n        }\n        ...ClassRegistrationButton_cls\n      }\n    "): (typeof documents)["\n      fragment RegistrationsList_cls on Class {\n        id\n        registrations {\n          id\n          user {\n            id\n            name\n            avatarUrl\n          }\n          attendance\n        }\n        ...ClassRegistrationButton_cls\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      fragment RegistrationsList_registrations on ClassRegistration {\n          id\n          user {\n            id\n            name\n            avatarUrl\n          }\n          attendance\n      }\n    "): (typeof documents)["\n      fragment RegistrationsList_registrations on ClassRegistration {\n          id\n          user {\n            id\n            name\n            avatarUrl\n          }\n          attendance\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      fragment SeatsList_cls on Class {\n        id\n        registrations {\n          user {\n            id\n            name\n            avatarUrl\n          }\n          seatId\n        }\n        space {\n          seats {\n            id\n          }\n        }\n        ...ClassRegistrationButton_cls\n      }\n    "): (typeof documents)["\n      fragment SeatsList_cls on Class {\n        id\n        registrations {\n          user {\n            id\n            name\n            avatarUrl\n          }\n          seatId\n        }\n        space {\n          seats {\n            id\n          }\n        }\n        ...ClassRegistrationButton_cls\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      fragment ClassPageRegistrationsFragment on Class {\n        id\n        name\n        startDate\n        endDate\n        maxAttendees\n        registered\n        inQueue\n        capacity\n        space {\n          seats {\n            id\n            name\n          }\n        }\n        registrations {\n          id\n          date\n          user {\n            id\n            name\n            avatarUrl\n          }\n          seatId\n          ...RegistrationsList_registrations\n        }\n        ...ClassRegistrationButton_cls\n        ...SeatsList_cls\n        ...RegistrationsList_cls\n      }\n    "): (typeof documents)["\n      fragment ClassPageRegistrationsFragment on Class {\n        id\n        name\n        startDate\n        endDate\n        maxAttendees\n        registered\n        inQueue\n        capacity\n        space {\n          seats {\n            id\n            name\n          }\n        }\n        registrations {\n          id\n          date\n          user {\n            id\n            name\n            avatarUrl\n          }\n          seatId\n          ...RegistrationsList_registrations\n        }\n        ...ClassRegistrationButton_cls\n        ...SeatsList_cls\n        ...RegistrationsList_cls\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      fragment ClassWorkout_cls on Class {\n        workouts {\n          id\n          name\n          description\n          scoreType\n        }\n      }\n    "): (typeof documents)["\n      fragment ClassWorkout_cls on Class {\n        workouts {\n          id\n          name\n          description\n          scoreType\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      fragment ClassAttendanceForm on Class {\n        id\n        registrations {\n          id\n          user {\n            id\n            name\n            avatarUrl\n          }\n          seatId\n          attendance \n        }\n      }\n    "): (typeof documents)["\n      fragment ClassAttendanceForm on Class {\n        id\n        registrations {\n          id\n          user {\n            id\n            name\n            avatarUrl\n          }\n          seatId\n          attendance \n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation UpdateAttendance($input: [UpdateAttendance!]!) {\n      updateAttendance(attendance: $input) {\n        id\n        attendance\n      }\n    }\n  "): (typeof documents)["\n    mutation UpdateAttendance($input: [UpdateAttendance!]!) {\n      updateAttendance(attendance: $input) {\n        id\n        attendance\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query ClassPageQuery($id: ID!, $canSeeWorkout: Boolean!) {\n        class(id: $id) {\n          id\n          name\n          startDate\n          endDate\n          maxAttendees\n          registered\n          typeId\n          registrationStatus {\n            canRegister\n            reason\n            when\n          }\n          coaches {\n            id\n            name\n            avatarUrl\n          }\n          space {\n            seats {\n              id\n              name\n            }\n          }\n          registrations {\n            id\n            attendance\n          }\n          ...ClassRegistrationButton_cls\n          ...ClassPageRegistrationsFragment\n          ...ClassAttendanceForm\n          ...ClassWorkout_cls @include(if: $canSeeWorkout)\n        }\n      }\n    "): (typeof documents)["\n      query ClassPageQuery($id: ID!, $canSeeWorkout: Boolean!) {\n        class(id: $id) {\n          id\n          name\n          startDate\n          endDate\n          maxAttendees\n          registered\n          typeId\n          registrationStatus {\n            canRegister\n            reason\n            when\n          }\n          coaches {\n            id\n            name\n            avatarUrl\n          }\n          space {\n            seats {\n              id\n              name\n            }\n          }\n          registrations {\n            id\n            attendance\n          }\n          ...ClassRegistrationButton_cls\n          ...ClassPageRegistrationsFragment\n          ...ClassAttendanceForm\n          ...ClassWorkout_cls @include(if: $canSeeWorkout)\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ClassRegisterOtherButtonRegister(\n        $cls: ID!\n        $user: ID!\n        $seat: String\n      ) {\n        register(cls: $cls, seat: $seat, user: $user) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation ClassRegisterOtherButtonRegister(\n        $cls: ID!\n        $user: ID!\n        $seat: String\n      ) {\n        register(cls: $cls, seat: $seat, user: $user) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ClassRegisterOtherButtonRegisterGuest(\n        $cls: ID!\n        $guest: String!\n        $seat: String\n      ) {\n        registerGuest(cls: $cls, seat: $seat, guest: $guest) {\n          id \n        } \n      }\n    "): (typeof documents)["\n      mutation ClassRegisterOtherButtonRegisterGuest(\n        $cls: ID!\n        $guest: String!\n        $seat: String\n      ) {\n        registerGuest(cls: $cls, seat: $seat, guest: $guest) {\n          id \n        } \n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ClassRegistrationButtonRegisterMutation($cls: ID!, $user: ID!, $seat: String) {\n        register(cls: $cls, user: $user, seat: $seat) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation ClassRegistrationButtonRegisterMutation($cls: ID!, $user: ID!, $seat: String) {\n        register(cls: $cls, user: $user, seat: $seat) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ClassRegistrationButtonUnregisterMutation($id: ID!) {\n        unregister(id: $id)\n      }\n    "): (typeof documents)["\n      mutation ClassRegistrationButtonUnregisterMutation($id: ID!) {\n        unregister(id: $id)\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      fragment ClassRegistrationButton_cls on Class {\n        id\n        startDate\n        typeId\n        registrationStatus {\n          canRegister\n          reason\n        }\n        registrations {\n          id\n          date\n          seatId\n          user {\n            id\n            name\n          }\n        }\n        space {\n          seats {\n            id\n            name\n          }\n        }\n      }\n    "): (typeof documents)["\n      fragment ClassRegistrationButton_cls on Class {\n        id\n        startDate\n        typeId\n        registrationStatus {\n          canRegister\n          reason\n        }\n        registrations {\n          id\n          date\n          seatId\n          user {\n            id\n            name\n          }\n        }\n        space {\n          seats {\n            id\n            name\n          }\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query ClassesCalendar($input: FindClassesInput!) {\n        classes(input: $input) {\n          id\n          name\n          startDate\n          endDate\n          typeId\n          scheduleType\n        }\n      }\n    "): (typeof documents)["\n      query ClassesCalendar($input: FindClassesInput!) {\n        classes(input: $input) {\n          id\n          name\n          startDate\n          endDate\n          typeId\n          scheduleType\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      fragment ClassesListFragment on Class {\n        id\n        startDate\n        ...ClassCardFragment\n      }\n    "): (typeof documents)["\n      fragment ClassesListFragment on Class {\n        id\n        startDate\n        ...ClassCardFragment\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query ClassesPageQuery($input: FindClassesInput!) {\n        classes(input: $input) {\n          id\n          startDate\n          endDate\n          registrationStatus {\n            canRegister\n          }\n          ...ClassesListFragment\n        }\n      }\n    "): (typeof documents)["\n      query ClassesPageQuery($input: FindClassesInput!) {\n        classes(input: $input) {\n          id\n          startDate\n          endDate\n          registrationStatus {\n            canRegister\n          }\n          ...ClassesListFragment\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation CreateClassPageMutation($input: CreateClassInput!) {\n      createClass(input: $input) {\n        id\n      }\n    }\n  "): (typeof documents)["\n    mutation CreateClassPageMutation($input: CreateClassInput!) {\n      createClass(input: $input) {\n        id\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation CreateWorkoutCategoryPage_createClassType($input: CreateClassTypeInput!) {\n        createClassType(input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation CreateWorkoutCategoryPage_createClassType($input: CreateClassTypeInput!) {\n        createClassType(input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation ManageClassPageUpdateMutation(\n      $id: String!\n      $input: UpdateClassInput!\n    ) {\n      updateClass(id: $id, input: $input) {\n        id\n      }\n    }\n  "): (typeof documents)["\n    mutation ManageClassPageUpdateMutation(\n      $id: String!\n      $input: UpdateClassInput!\n    ) {\n      updateClass(id: $id, input: $input) {\n        id\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation ManageClassPageDeleteMutation($id: String!) {\n      deleteClass(id: $id)\n    }\n  "): (typeof documents)["\n    mutation ManageClassPageDeleteMutation($id: String!) {\n      deleteClass(id: $id)\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query ManageClassPageQuery($id: ID!) {\n        class(id: $id) {\n          id\n          typeId\n          name\n          scheduleType\n          startDate\n          endDate\n          spaceId\n          parentId \n          maxAttendees\n          maxQueue\n          registerFrom\n          registerUntil \n          cancelUntil\n          coachIds\n          locationId\n          slots {\n            start \n            end\n            weekdays\n          }\n        }\n      }\n    "): (typeof documents)["\n      query ManageClassPageQuery($id: ID!) {\n        class(id: $id) {\n          id\n          typeId\n          name\n          scheduleType\n          startDate\n          endDate\n          spaceId\n          parentId \n          maxAttendees\n          maxQueue\n          registerFrom\n          registerUntil \n          cancelUntil\n          coachIds\n          locationId\n          slots {\n            start \n            end\n            weekdays\n          }\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query ManageClassTypePage($id: ID!) {\n        classType(id: $id) {\n          id\n          name\n          nameTranslations {\n            ...TranslationFragment\n          }\n          color\n        }\n      }\n    "): (typeof documents)["\n      query ManageClassTypePage($id: ID!) {\n        classType(id: $id) {\n          id\n          name\n          nameTranslations {\n            ...TranslationFragment\n          }\n          color\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ManageClassTypePage_updateClassType($id: ID!, $input: UpdateClassTypeInput!) {\n        updateClassType(id: $id, input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation ManageClassTypePage_updateClassType($id: ID!, $input: UpdateClassTypeInput!) {\n        updateClassType(id: $id, input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ManageClassTypePage_deleteClassType($id: ID!) {\n        deleteClassType(id: $id)\n      }\n    "): (typeof documents)["\n      mutation ManageClassTypePage_deleteClassType($id: ID!) {\n        deleteClassType(id: $id)\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query ManageClassTypesPage {\n        classTypes {\n          id\n          name\n          color\n        }\n      }\n    "): (typeof documents)["\n      query ManageClassTypesPage {\n        classTypes {\n          id\n          name\n          color\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query ManageClassesSettingsPage {\n        currentTenant {\n          id\n          classesSettings\n        }\n      }\n    "): (typeof documents)["\n      query ManageClassesSettingsPage {\n        currentTenant {\n          id\n          classesSettings\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation ManageClassesSettingsPage_updateTenant($input: UpdateTenantInput!) {\n      updateTenant(input: $input) {\n        id\n        classesSettings\n      }\n    }"): (typeof documents)["\n    mutation ManageClassesSettingsPage_updateTenant($input: UpdateTenantInput!) {\n      updateTenant(input: $input) {\n        id\n        classesSettings\n      }\n    }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query ActiveTenantSelector {\n        currentUser {\n          memberships {\n            tenant {\n              id\n              name\n              slug\n              logoUrl\n            }\n          }\n        }\n      }\n    "): (typeof documents)["\n      query ActiveTenantSelector {\n        currentUser {\n          memberships {\n            tenant {\n              id\n              name\n              slug\n              logoUrl\n            }\n          }\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query BoxSelector {\n        currentUser {\n          memberships {\n            tenant {\n              id\n              name\n              logoUrl\n            }\n          }\n        }\n      }\n    "): (typeof documents)["\n      query BoxSelector {\n        currentUser {\n          memberships {\n            tenant {\n              id\n              name\n              logoUrl\n            }\n          }\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query CoachSelect {\n        currentTenant {\n          coaches {\n            id\n            name\n          }\n        }\n      }\n    "): (typeof documents)["\n      query CoachSelect {\n        currentTenant {\n          coaches {\n            id\n            name\n          }\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      fragment CommentsCard_comments on Comment {\n        id\n        text\n        createdAt\n\n        ...CommentsList_comments\n      }\n    "): (typeof documents)["\n      fragment CommentsCard_comments on Comment {\n        id\n        text\n        createdAt\n\n        ...CommentsList_comments\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation CommentsCard_createCard($input: CreateCommentInput!) {\n        createComment(input: $input) {\n          id\n          text\n          createdAt\n        }\n      }\n    "): (typeof documents)["\n      mutation CommentsCard_createCard($input: CreateCommentInput!) {\n        createComment(input: $input) {\n          id\n          text\n          createdAt\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query GroupSelect {\n        currentTenant {\n          id\n          groups {\n            id\n            name\n          }\n        }\n      }\n    "): (typeof documents)["\n      query GroupSelect {\n        currentTenant {\n          id\n          groups {\n            id\n            name\n          }\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation PushNotificationProvider_updateDeviceToken($token: String!) {\n        updateDeviceToken(token: $token)\n      }\n    "): (typeof documents)["\n      mutation PushNotificationProvider_updateDeviceToken($token: String!) {\n        updateDeviceToken(token: $token)\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query NotificationsProvider($filter: NotificationsFilter) {\n        notifications(filter: $filter) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      query NotificationsProvider($filter: NotificationsFilter) {\n        notifications(filter: $filter) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation NotificationsPopover_markAsRead($ids: [ID!]!) {\n        notificationsMarkAsRead(ids: $ids) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation NotificationsPopover_markAsRead($ids: [ID!]!) {\n        notificationsMarkAsRead(ids: $ids) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query NotificationsIcon {\n        notifications {\n          id\n          read\n          date\n          message\n          link\n        }\n      }\n    "): (typeof documents)["\n      query NotificationsIcon {\n        notifications {\n          id\n          read\n          date\n          message\n          link\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query UserInput {\n        members {\n          id\n          name\n          avatarUrl\n        }\n      }\n    "): (typeof documents)["\n      query UserInput {\n        members {\n          id\n          name\n          avatarUrl\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation CreateCollectionPage_create(\n        $input: CreateDirectDebitCollectionInput!\n      ) {\n        createDirectDebitCollection(input: $input) {\n          id\n          transactions\n        }\n      }\n    "): (typeof documents)["\n      mutation CreateCollectionPage_create(\n        $input: CreateDirectDebitCollectionInput!\n      ) {\n        createDirectDebitCollection(input: $input) {\n          id\n          transactions\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation CreateExpensePage_createExpense($input: CreateExpenseInput!) {\n        createExpense(input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation CreateExpensePage_createExpense($input: CreateExpenseInput!) {\n        createExpense(input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation CreatePaymentPage_createPayment($input: CreatePaymentInput!) {\n        createPayment(input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation CreatePaymentPage_createPayment($input: CreatePaymentInput!) {\n        createPayment(input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query FinanceSettingsPage_DirectDebitTab {\n        financeSettings {\n            creditors {\n                name\n                creditorId\n                street\n                postalCode\n                city\n                country\n                iban\n                vatNumber\n            }\n        }\n    }"): (typeof documents)["\n    query FinanceSettingsPage_DirectDebitTab {\n        financeSettings {\n            creditors {\n                name\n                creditorId\n                street\n                postalCode\n                city\n                country\n                iban\n                vatNumber\n            }\n        }\n    }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation FinanceSettingsPage_DirectDebitTab_updateFinanceSettings(\n        $input: UpdateFinanceSettingsInput!\n      ) {\n        updateFinanceSettings(input: $input) {\n          creditors {\n            name\n            creditorId\n            street\n            postalCode\n            city\n            country\n            iban\n            vatNumber\n          }\n        }\n      }\n    "): (typeof documents)["\n      mutation FinanceSettingsPage_DirectDebitTab_updateFinanceSettings(\n        $input: UpdateFinanceSettingsInput!\n      ) {\n        updateFinanceSettings(input: $input) {\n          creditors {\n            name\n            creditorId\n            street\n            postalCode\n            city\n            country\n            iban\n            vatNumber\n          }\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      fragment InvoiceCard_payment on Payment {\n        id\n        invoice\n        invoiceUrl\n      }\n    "): (typeof documents)["\n      fragment InvoiceCard_payment on Payment {\n        id\n        invoice\n        invoiceUrl\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation InvoiceCard_uploadInvoice(\n        $id: ID!\n        $input: UpdatePaymentInput!\n      ) {\n        updatePayment(id: $id, input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation InvoiceCard_uploadInvoice(\n        $id: ID!\n        $input: UpdatePaymentInput!\n      ) {\n        updatePayment(id: $id, input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query ManageExpensePage($id: ID!) {\n      expense(id: $id) {\n        id\n        date\n        amount\n        notes\n        method\n        createdBy {\n          name\n        }\n      }\n    }\n  "): (typeof documents)["\n    query ManageExpensePage($id: ID!) {\n      expense(id: $id) {\n        id\n        date\n        amount\n        notes\n        method\n        createdBy {\n          name\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ManageExpensePage_updateExpense(\n        $id: ID!\n        $input: UpdateExpenseInput!\n      ) {\n        updateExpense(id: $id, input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation ManageExpensePage_updateExpense(\n        $id: ID!\n        $input: UpdateExpenseInput!\n      ) {\n        updateExpense(id: $id, input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query ManageExpensesPage {\n        expenses {\n          id\n          createdBy {\n            id\n            name\n          }\n          date\n          amount\n        }\n      }\n    "): (typeof documents)["\n      query ManageExpensesPage {\n        expenses {\n          id\n          createdBy {\n            id\n            name\n          }\n          date\n          amount\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query ManagePaymentPage($id: ID!) {\n      payment(id: $id) {\n        id\n        date\n        amount\n        offer\n        notes\n        user {\n          name\n        }\n        method\n        createdBy {\n          name\n        }\n\n        ...InvoiceCard_payment\n      }\n    }\n  "): (typeof documents)["\n    query ManagePaymentPage($id: ID!) {\n      payment(id: $id) {\n        id\n        date\n        amount\n        offer\n        notes\n        user {\n          name\n        }\n        method\n        createdBy {\n          name\n        }\n\n        ...InvoiceCard_payment\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ManagePaymentPage_updatePayment(\n        $id: ID!\n        $input: UpdatePaymentInput!\n      ) {\n        updatePayment(id: $id, input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation ManagePaymentPage_updatePayment(\n        $id: ID!\n        $input: UpdatePaymentInput!\n      ) {\n        updatePayment(id: $id, input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query ManagePaymentsPage {\n        payments {\n          ...PaymentsList_payments\n        }\n      }\n    "): (typeof documents)["\n      query ManagePaymentsPage {\n        payments {\n          ...PaymentsList_payments\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query PaymentCheck($paymentId: ID!) {\n        payment(id: $paymentId) {\n          id\n          status\n        }\n      }\n    "): (typeof documents)["\n      query PaymentCheck($paymentId: ID!) {\n        payment(id: $paymentId) {\n          id\n          status\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      fragment PaymentsList_payments on Payment {\n        id\n        date\n        amount\n        offer\n        invoice\n        user {\n          name\n        }\n      }\n    "): (typeof documents)["\n      fragment PaymentsList_payments on Payment {\n        id\n        date\n        amount\n        offer\n        invoice\n        user {\n          name\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation AddMemberPage_inviteMember($input: InviteMemberInput!) {\n        inviteMember(input: $input)\n      }\n    "): (typeof documents)["\n      mutation AddMemberPage_inviteMember($input: InviteMemberInput!) {\n        inviteMember(input: $input)\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n        query InvitesPage {\n          membershipInvites {\n            id\n            email\n            date\n          }\n        }\n      "): (typeof documents)["\n        query InvitesPage {\n          membershipInvites {\n            id\n            email\n            date\n          }\n        }\n      "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation InvitesPage_deleteInvite($id: ID!) {\n        deleteMembershipInvite(id: $id)\n      }\n    "): (typeof documents)["\n      mutation InvitesPage_deleteInvite($id: ID!) {\n        deleteMembershipInvite(id: $id)\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query ManageMemberPageQuery($id: ID!, $canManagePlans: Boolean!, $canManagePayments: Boolean!) {\n        member(id: $id) {\n          id\n          name\n          email\n          avatarUrl\n          membership {\n            id\n          }\n          ...MemberDetails_member\n          ...MemberGroups_member\n          ...MemberPlans_member @include(if: $canManagePlans)\n          ...MemberPayments_member @include(if: $canManagePayments)\n        }\n      }\n    "): (typeof documents)["\n      query ManageMemberPageQuery($id: ID!, $canManagePlans: Boolean!, $canManagePayments: Boolean!) {\n        member(id: $id) {\n          id\n          name\n          email\n          avatarUrl\n          membership {\n            id\n          }\n          ...MemberDetails_member\n          ...MemberGroups_member\n          ...MemberPlans_member @include(if: $canManagePlans)\n          ...MemberPayments_member @include(if: $canManagePayments)\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ManageMemberPage_deleteMembership($id: ID!) {\n        deleteMembership(id: $id)\n      }\n    "): (typeof documents)["\n      mutation ManageMemberPage_deleteMembership($id: ID!) {\n        deleteMembership(id: $id)\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      fragment MemberDetails_member on User {\n        id\n        name\n        membership {\n          id\n          data\n        }\n      }\n    "): (typeof documents)["\n      fragment MemberDetails_member on User {\n        id\n        name\n        membership {\n          id\n          data\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation MemberDetails_updateMembership(\n        $id: ID!\n        $input: UpdateMembershipInput!\n      ) {\n        updateMembership(id: $id, input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation MemberDetails_updateMembership(\n        $id: ID!\n        $input: UpdateMembershipInput!\n      ) {\n        updateMembership(id: $id, input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation MemberGroups_updateMembership(\n        $id: ID!\n        $input: UpdateMembershipInput!\n      ) {\n        updateMembership(id: $id, input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation MemberGroups_updateMembership(\n        $id: ID!\n        $input: UpdateMembershipInput!\n      ) {\n        updateMembership(id: $id, input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      fragment MemberGroups_member on User {\n        id\n        name\n        membership {\n          id\n          groupIds\n          plans {\n            id\n            plan {\n              id\n              tenantGroups {\n                id\n                name\n              }\n            }\n          }\n        }\n      }\n    "): (typeof documents)["\n      fragment MemberGroups_member on User {\n        id\n        name\n        membership {\n          id\n          groupIds\n          plans {\n            id\n            plan {\n              id\n              tenantGroups {\n                id\n                name\n              }\n            }\n          }\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      fragment MemberPayments_member on User {\n        id\n      }\n    "): (typeof documents)["\n      fragment MemberPayments_member on User {\n        id\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query MemberPaymentsQuery($filter: FindPaymentsFilter) {\n      payments(filter: $filter) {\n        ...PaymentsList_payments\n      }\n    }\n  "): (typeof documents)["\n    query MemberPaymentsQuery($filter: FindPaymentsFilter) {\n      payments(filter: $filter) {\n        ...PaymentsList_payments\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query ChangePlanForm($id: ID!) {\n        plan(id: $id) {\n          periodEnd\n        }\n      }\n    "): (typeof documents)["\n      query ChangePlanForm($id: ID!) {\n        plan(id: $id) {\n          periodEnd\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      fragment MemberPlans_member on User {\n        id\n        membership {\n          id\n          plans {\n            id\n            plan {\n              id\n              name\n              group {\n                id\n                name\n              }\n            }\n            validUntil\n            paymentMethod {\n              id\n              method\n            }\n          }\n        }\n      }\n    "): (typeof documents)["\n      fragment MemberPlans_member on User {\n        id\n        membership {\n          id\n          plans {\n            id\n            plan {\n              id\n              name\n              group {\n                id\n                name\n              }\n            }\n            validUntil\n            paymentMethod {\n              id\n              method\n            }\n          }\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation MemberPlans_updatePlan(\n        $id: ID!\n        $input: UpdateMembershipPlanInput!\n      ) {\n        updateMembershipPlan(id: $id, input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation MemberPlans_updatePlan(\n        $id: ID!\n        $input: UpdateMembershipPlanInput!\n      ) {\n        updateMembershipPlan(id: $id, input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation MemberPlans_addPlan($id: ID!, $input: AddMembershipPlanInput!) {\n        addMembershipPlan(id: $id, input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation MemberPlans_addPlan($id: ID!, $input: AddMembershipPlanInput!) {\n        addMembershipPlan(id: $id, input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation MemberPlans_removePlan($id: ID!) {\n        removeMembershipPlan(id: $id) \n      }\n    "): (typeof documents)["\n      mutation MemberPlans_removePlan($id: ID!) {\n        removeMembershipPlan(id: $id) \n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query ManageMembersPage($filter: UsersFilter) {\n        members(filter: $filter) {\n          id\n          name\n          membership {\n            groups {\n              id\n              name\n            }\n          }\n        }\n      }\n    "): (typeof documents)["\n      query ManageMembersPage($filter: UsersFilter) {\n        members(filter: $filter) {\n          id\n          name\n          membership {\n            groups {\n              id\n              name\n            }\n          }\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query ManageMembersSettingsPage {\n        currentTenant {\n          id\n          memberPermissions\n        }\n      }\n    "): (typeof documents)["\n      query ManageMembersSettingsPage {\n        currentTenant {\n          id\n          memberPermissions\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation ManageMembersSettingsPage_updateTenant($input: UpdateTenantInput!) {\n      updateTenant(input: $input) {\n        id\n        memberPermissions\n      }\n    }"): (typeof documents)["\n    mutation ManageMembersSettingsPage_updateTenant($input: UpdateTenantInput!) {\n      updateTenant(input: $input) {\n        id\n        memberPermissions\n      }\n    }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query MembershipDataForm {\n        currentTenant {\n          membershipForm {\n            configuration\n          }\n        }\n      }\n    "): (typeof documents)["\n      query MembershipDataForm {\n        currentTenant {\n          membershipForm {\n            configuration\n          }\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation CreatePlanGroupPage_createPlan($input: CreatePlanGroupInput!) {\n        createPlanGroup(input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation CreatePlanGroupPage_createPlan($input: CreatePlanGroupInput!) {\n        createPlanGroup(input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation CreatePlanPage_createPlan($input: CreatePlanInput!) {\n        createPlan(input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation CreatePlanPage_createPlan($input: CreatePlanInput!) {\n        createPlan(input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query ManagePlanGroupPage($id: ID!) {\n        planGroup(id: $id) {\n          id\n          name\n          nameTranslations {\n            ...TranslationFragment\n          }\n          description\n          descriptionTranslations {\n            ...TranslationFragment\n          }\n        }\n      }\n    "): (typeof documents)["\n      query ManagePlanGroupPage($id: ID!) {\n        planGroup(id: $id) {\n          id\n          name\n          nameTranslations {\n            ...TranslationFragment\n          }\n          description\n          descriptionTranslations {\n            ...TranslationFragment\n          }\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ManagePlanGroupPage_updatePlan($id: ID!, $input: UpdatePlanGroupInput!) {\n        updatePlanGroup(id: $id, input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation ManagePlanGroupPage_updatePlan($id: ID!, $input: UpdatePlanGroupInput!) {\n        updatePlanGroup(id: $id, input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ManagePlanGroupsPage_DeleteButton_deletePlan($id: ID!) {\n        deletePlanGroup(id: $id)\n      }\n    "): (typeof documents)["\n      mutation ManagePlanGroupsPage_DeleteButton_deletePlan($id: ID!) {\n        deletePlanGroup(id: $id)\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      fragment ManagePlanGroupsPage_DeleteButton_planGroup on PlanGroup {\n        id\n        membersCount\n      }\n    "): (typeof documents)["\n      fragment ManagePlanGroupsPage_DeleteButton_planGroup on PlanGroup {\n        id\n        membersCount\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query ManagePlanGroupsPage {\n        planGroups {\n          id\n          name\n          nameTranslations {\n            ...TranslationFragment\n          }\n          description\n          descriptionTranslations {\n            ...TranslationFragment\n          }\n\n          ...ManagePlanGroupsPage_DeleteButton_planGroup\n        }\n      }\n    "): (typeof documents)["\n      query ManagePlanGroupsPage {\n        planGroups {\n          id\n          name\n          nameTranslations {\n            ...TranslationFragment\n          }\n          description\n          descriptionTranslations {\n            ...TranslationFragment\n          }\n\n          ...ManagePlanGroupsPage_DeleteButton_planGroup\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query ManagePlanPage($id: ID!) {\n      plan(id: $id) {\n        id\n        groupId\n        name\n        nameTranslations {\n          ...TranslationFragment\n        }\n        description\n        descriptionTranslations {\n          ...TranslationFragment\n        }\n        tenantGroupIds\n        price\n        paymentPeriodicity\n        allowPaymentBefore\n        paymentTolerance\n        alignToCalendar\n        chargeFullPeriod\n        allowInAppSubscription\n      }\n    }\n      "): (typeof documents)["\n    query ManagePlanPage($id: ID!) {\n      plan(id: $id) {\n        id\n        groupId\n        name\n        nameTranslations {\n          ...TranslationFragment\n        }\n        description\n        descriptionTranslations {\n          ...TranslationFragment\n        }\n        tenantGroupIds\n        price\n        paymentPeriodicity\n        allowPaymentBefore\n        paymentTolerance\n        alignToCalendar\n        chargeFullPeriod\n        allowInAppSubscription\n      }\n    }\n      "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ManagePlanPage_updatePlan($id: ID!, $input: UpdatePlanInput!) {\n        updatePlan(id: $id, input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation ManagePlanPage_updatePlan($id: ID!, $input: UpdatePlanInput!) {\n        updatePlan(id: $id, input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ManagePlansPage_deletePlan($id: ID!) {\n        deletePlan(id: $id)\n      }\n    "): (typeof documents)["\n      mutation ManagePlansPage_deletePlan($id: ID!) {\n        deletePlan(id: $id)\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      fragment ManagePlansPage_DeleteButton_plan on Plan {\n        id\n        membersCount\n      }\n    "): (typeof documents)["\n      fragment ManagePlansPage_DeleteButton_plan on Plan {\n        id\n        membersCount\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query ManagePlansPage($filter: FindPlansFilter) {\n        plans(filter: $filter) {\n          id\n          name\n          position\n\n          ...ManagePlansPage_DeleteButton_plan\n        }\n      }\n    "): (typeof documents)["\n      query ManagePlansPage($filter: FindPlansFilter) {\n        plans(filter: $filter) {\n          id\n          name\n          position\n\n          ...ManagePlansPage_DeleteButton_plan\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ManagePlansPage_updatePlans($positions: [ID!]!) {\n        updatePlansPosition(positions: $positions) {\n          id\n          position\n        }\n      }\n    "): (typeof documents)["\n      mutation ManagePlansPage_updatePlans($positions: [ID!]!) {\n        updatePlansPosition(positions: $positions) {\n          id\n          position\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query PayPlanPage($id: ID!) {\n        plan(id: $id) {\n          id\n          groupId\n          ...PlanCard_plan\n        }\n        currentUser {\n          id\n          membership {\n            id\n            plans {\n              id\n              planId\n              nextPaymentStart\n              nextPaymentDate\n            }\n          }\n        }\n      }\n    "): (typeof documents)["\n      query PayPlanPage($id: ID!) {\n        plan(id: $id) {\n          id\n          groupId\n          ...PlanCard_plan\n        }\n        currentUser {\n          id\n          membership {\n            id\n            plans {\n              id\n              planId\n              nextPaymentStart\n              nextPaymentDate\n            }\n          }\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      fragment PlanCard_plan on Plan {\n        id\n        name\n        description\n        price\n        paymentPeriodicity\n      }\n    "): (typeof documents)["\n      fragment PlanCard_plan on Plan {\n        id\n        name\n        description\n        price\n        paymentPeriodicity\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query PlanGroupSelect {\n        planGroups {\n          id\n          name\n        }\n      }\n    "): (typeof documents)["\n      query PlanGroupSelect {\n        planGroups {\n          id\n          name\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query PlanPaymentMessage {\n      currentUser {\n        membership {\n          plans {\n            id\n            nextPaymentDate\n            nextPaymentStart\n            nextPaymentEnd\n            paymentMethodId\n            plan {\n              id\n              name\n            }\n          }\n        }\n      }\n    }"): (typeof documents)["\n    query PlanPaymentMessage {\n      currentUser {\n        membership {\n          plans {\n            id\n            nextPaymentDate\n            nextPaymentStart\n            nextPaymentEnd\n            paymentMethodId\n            plan {\n              id\n              name\n            }\n          }\n        }\n      }\n    }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query PlanPicker {\n        planGroups {\n          id\n          name\n          description\n          plans {\n            id\n            ...PlanCard_plan\n          }\n        }\n\n        currentUser {\n          membership {\n            plans {\n              plan {\n                id\n                groupId\n              }\n            }\n          }\n        }\n      }\n    "): (typeof documents)["\n      query PlanPicker {\n        planGroups {\n          id\n          name\n          description\n          plans {\n            id\n            ...PlanCard_plan\n          }\n        }\n\n        currentUser {\n          membership {\n            plans {\n              plan {\n                id\n                groupId\n              }\n            }\n          }\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query PlanInput($filter: FindPlansFilter) {\n        plans(filter: $filter) {\n          id\n          name\n          group {\n            id\n            name\n          }\n        }\n      }\n    "): (typeof documents)["\n      query PlanInput($filter: FindPlansFilter) {\n        plans(filter: $filter) {\n          id\n          name\n          group {\n            id\n            name\n          }\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation CheckoutModal_subscribePlan($plan: ID!, $paymentMethod: ID!, $period: DateTime) {\n        subscribePlan(plan: $plan, userPaymentMethod: $paymentMethod, period: $period) {\n          success\n          paymentId\n        }\n      }\n    "): (typeof documents)["\n      mutation CheckoutModal_subscribePlan($plan: ID!, $paymentMethod: ID!, $period: DateTime) {\n        subscribePlan(plan: $plan, userPaymentMethod: $paymentMethod, period: $period) {\n          success\n          paymentId\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query PlanSubscriptionSummary($id: ID!, $period: DateTime) {\n        plan(id: $id) {\n          id\n          groupId\n          name\n          price\n          priceNow(period: $period)\n          totalPrice(period: $period)\n          paymentPeriodicity\n          periodStart(period: $period)\n          periodEnd(period: $period)\n        }\n      }\n    "): (typeof documents)["\n      query PlanSubscriptionSummary($id: ID!, $period: DateTime) {\n        plan(id: $id) {\n          id\n          groupId\n          name\n          price\n          priceNow(period: $period)\n          totalPrice(period: $period)\n          paymentPeriodicity\n          periodStart(period: $period)\n          periodEnd(period: $period)\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query PlanSubscriptionSummary_currentUser($period: DateTime) {\n        currentUser {\n          id\n          membership {\n            id\n            plans {\n              id\n              prorationDiscount(period: $period)\n              plan {\n                groupId\n              }\n            }\n          }\n        }\n      }\n    "): (typeof documents)["\n      query PlanSubscriptionSummary_currentUser($period: DateTime) {\n        currentUser {\n          id\n          membership {\n            id\n            plans {\n              id\n              prorationDiscount(period: $period)\n              plan {\n                groupId\n              }\n            }\n          }\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation CommentsList_deleteComment($id: ID!) {\n        deleteComment(id: $id)\n      }\n    "): (typeof documents)["\n      mutation CommentsList_deleteComment($id: ID!) {\n        deleteComment(id: $id)\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      fragment CommentsList_comments on Comment {\n        id\n        text\n        createdAt\n        user {\n          id\n          name\n          avatarUrl\n        }\n        reactions {\n          id\n          type\n          ...ReactionsBar_reactions\n        }\n      }\n    "): (typeof documents)["\n      fragment CommentsList_comments on Comment {\n        id\n        text\n        createdAt\n        user {\n          id\n          name\n          avatarUrl\n        }\n        reactions {\n          id\n          type\n          ...ReactionsBar_reactions\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      fragment ReactionsBar_reactions on Reaction {\n        id\n        type\n        user {\n          id\n        }\n      }\n    "): (typeof documents)["\n      fragment ReactionsBar_reactions on Reaction {\n        id\n        type\n        user {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ReactionsBar_createReaction($input: CreateReactionInput!) {\n        createReaction(input: $input) {\n          id\n          type\n        }\n      }\n    "): (typeof documents)["\n      mutation ReactionsBar_createReaction($input: CreateReactionInput!) {\n        createReaction(input: $input) {\n          id\n          type\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ReactionsBar_deleteReaction($id: ID!) {\n        deleteReaction(id: $id)\n      }\n    "): (typeof documents)["\n      mutation ReactionsBar_deleteReaction($id: ID!) {\n        deleteReaction(id: $id)\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query ReactionsList($subject: ReactionSubject!, $id: ID!) {\n        reactions(subject: $subject, id: $id) {\n          id\n          user {\n            name\n            avatarUrl\n          }\n        }\n      }\n    "): (typeof documents)["\n      query ReactionsList($subject: ReactionSubject!, $id: ID!) {\n        reactions(subject: $subject, id: $id) {\n          id\n          user {\n            name\n            avatarUrl\n          }\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      fragment BookingCard_booking on SpaceBooking {\n        id\n        startDate\n        endDate\n        space {\n          id\n          name\n        }\n      }\n    "): (typeof documents)["\n      fragment BookingCard_booking on SpaceBooking {\n        id\n        startDate\n        endDate\n        space {\n          id\n          name\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation BookingCard_cancelBooking($id: ID!) {\n      deleteSpaceBooking(id: $id) {\n        id\n      }\n    }\n  "): (typeof documents)["\n    mutation BookingCard_cancelBooking($id: ID!) {\n      deleteSpaceBooking(id: $id) {\n        id\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation CreateSpacePageMutation($input: CreateSpaceInput!) {\n        createSpace(input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation CreateSpacePageMutation($input: CreateSpaceInput!) {\n        createSpace(input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query ManageSpacePageQuery($id: ID!) {\n        space(id: $id) {\n          id\n          name\n          capacity\n          bookable\n          description\n          maxBookingTime\n          slotSize\n          bookableDuringClasses\n          seats {\n            id\n            name \n          }\n          schedule {\n            weekdays\n            start \n            end\n          }\n        }\n      }\n    "): (typeof documents)["\n      query ManageSpacePageQuery($id: ID!) {\n        space(id: $id) {\n          id\n          name\n          capacity\n          bookable\n          description\n          maxBookingTime\n          slotSize\n          bookableDuringClasses\n          seats {\n            id\n            name \n          }\n          schedule {\n            weekdays\n            start \n            end\n          }\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ManageSpacePageUpdateMutation(\n        $id: ID!\n        $input: UpdateSpaceInput!\n      ) {\n        updateSpace(id: $id, input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation ManageSpacePageUpdateMutation(\n        $id: ID!\n        $input: UpdateSpaceInput!\n      ) {\n        updateSpace(id: $id, input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query ManageSpacesPageQuery {\n        spaces {\n          id\n          name\n        }\n      }\n    "): (typeof documents)["\n      query ManageSpacesPageQuery {\n        spaces {\n          id\n          name\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ManageSpacesPageDeleteMutation($id: ID!) {\n        deleteSpace(id: $id)\n      }\n    "): (typeof documents)["\n      mutation ManageSpacesPageDeleteMutation($id: ID!) {\n        deleteSpace(id: $id)\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      fragment BookingModal_space on Space {\n        id\n        name\n        slotSize\n\n        ...isSpaceAvailable_space\n        ...maxBookingDuration_space\n      }\n    "): (typeof documents)["\n      fragment BookingModal_space on Space {\n        id\n        name\n        slotSize\n\n        ...isSpaceAvailable_space\n        ...maxBookingDuration_space\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation SpacePage_bookSpace($input: CreateSpaceBookingInput!) {\n        createSpaceBooking(input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation SpacePage_bookSpace($input: CreateSpaceBookingInput!) {\n        createSpaceBooking(input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query SpacePage($id: ID!, $from: DateTime!, $to: DateTime!) {\n        space(id: $id) {\n          id\n          name\n        \n          ...BookingModal_space\n        }\n      }\n    "): (typeof documents)["\n      query SpacePage($id: ID!, $from: DateTime!, $to: DateTime!) {\n        space(id: $id) {\n          id\n          name\n        \n          ...BookingModal_space\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query SpaceSchedule($id: ID!, $from: DateTime!, $to: DateTime!) {\n        space(id: $id) {\n          id\n          capacity\n          slotSize\n          bookable\n          maxBookingTime\n          schedule {\n            weekdays\n            start\n            end\n          }\n          classes {\n            id\n            name\n            startDate\n            endDate\n            type {\n              id\n              name\n            }\n          }\n          bookings(filter:  {\n             from: $from\n             to: $to\n          }) {\n            id\n            startDate\n            endDate\n            user {\n              id\n            }\n          }\n        \n         ...isSpaceAvailable_space\n        }\n      }\n    "): (typeof documents)["\n      query SpaceSchedule($id: ID!, $from: DateTime!, $to: DateTime!) {\n        space(id: $id) {\n          id\n          capacity\n          slotSize\n          bookable\n          maxBookingTime\n          schedule {\n            weekdays\n            start\n            end\n          }\n          classes {\n            id\n            name\n            startDate\n            endDate\n            type {\n              id\n              name\n            }\n          }\n          bookings(filter:  {\n             from: $from\n             to: $to\n          }) {\n            id\n            startDate\n            endDate\n            user {\n              id\n            }\n          }\n        \n         ...isSpaceAvailable_space\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query SpaceInput {\n        spaces {\n          id\n          name\n          capacity\n        }\n      }\n    "): (typeof documents)["\n      query SpaceInput {\n        spaces {\n          id\n          name\n          capacity\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query SpacesPage {\n        spaces {\n          id\n          name\n          bookable\n        }\n      }\n    "): (typeof documents)["\n      query SpacesPage {\n        spaces {\n          id\n          name\n          bookable\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      fragment isSpaceAvailable_space on Space {\n        id\n        bookable\n        schedule {\n          weekdays\n          start\n          end\n        }\n        classes {\n          startDate\n          endDate\n          capacity\n        }\n        capacity\n        bookableDuringClasses\n        slotSize\n        maxBookingTime\n        bookings(filter: { from: $from, to: $to }) {\n          startDate\n          endDate\n          user {\n            id\n          }\n        }\n      }\n    "): (typeof documents)["\n      fragment isSpaceAvailable_space on Space {\n        id\n        bookable\n        schedule {\n          weekdays\n          start\n          end\n        }\n        classes {\n          startDate\n          endDate\n          capacity\n        }\n        capacity\n        bookableDuringClasses\n        slotSize\n        maxBookingTime\n        bookings(filter: { from: $from, to: $to }) {\n          startDate\n          endDate\n          user {\n            id\n          }\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      fragment maxBookingDuration_space on Space {\n        slotSize\n        maxBookingTime\n        ...isSpaceAvailable_space\n      }\n    "): (typeof documents)["\n      fragment maxBookingDuration_space on Space {\n        slotSize\n        maxBookingTime\n        ...isSpaceAvailable_space\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation ManageTenant_LogoUploadPage_upload($name: String!, $logo: Upload!) {\n        uploadLogo(name: $name, file: $logo) \n    }"): (typeof documents)["\n    mutation ManageTenant_LogoUploadPage_upload($name: String!, $logo: Upload!) {\n        uploadLogo(name: $name, file: $logo) \n    }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation CreateFormPage_createForm($input: CreateFormInput!) {\n        createForm(input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation CreateFormPage_createForm($input: CreateFormInput!) {\n        createForm(input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation CreateTenantPage_create($input: CreateTenantInput!) {\n        createTenant(input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation CreateTenantPage_create($input: CreateTenantInput!) {\n        createTenant(input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query InvitationPage($id: ID!) {\n        currentUser {\n          memberships {\n            tenantId\n          }\n        }\n        token(id: $id) {\n          id\n          type\n          tenant {\n            id\n            name\n            slug\n            membershipForm {\n              configuration\n            }\n          }\n        }\n      }\n    "): (typeof documents)["\n      query InvitationPage($id: ID!) {\n        currentUser {\n          memberships {\n            tenantId\n          }\n        }\n        token(id: $id) {\n          id\n          type\n          tenant {\n            id\n            name\n            slug\n            membershipForm {\n              configuration\n            }\n          }\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation InvitationPage_join($input: JoinInput!) {\n        join(input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation InvitationPage_join($input: JoinInput!) {\n        join(input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query ManageContentPacksPage {\n        contentPacks {\n          id\n          name\n          description\n          updatedAt\n        }\n\n        currentTenant {\n          id\n          contentPacks {\n            id\n            updatedAt\n          }\n        }\n      }\n    "): (typeof documents)["\n      query ManageContentPacksPage {\n        contentPacks {\n          id\n          name\n          description\n          updatedAt\n        }\n\n        currentTenant {\n          id\n          contentPacks {\n            id\n            updatedAt\n          }\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ManageContentPacks_installContentPack($id: ID!) {\n        installContentPack(id: $id)\n      }\n    "): (typeof documents)["\n      mutation ManageContentPacks_installContentPack($id: ID!) {\n        installContentPack(id: $id)\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ManageContentPacks_uninstallContentPack($id: ID!) {\n        uninstallContentPack(id: $id)\n      }\n    "): (typeof documents)["\n      mutation ManageContentPacks_uninstallContentPack($id: ID!) {\n        uninstallContentPack(id: $id)\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query ManageFormPage($id: ID!) {\n        form(id: $id) {\n          id\n          name\n          configuration\n        }\n      }\n    "): (typeof documents)["\n      query ManageFormPage($id: ID!) {\n        form(id: $id) {\n          id\n          name\n          configuration\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ManageFormPage_updateForm($id: ID!, $input: UpdateFormInput!) {\n        updateForm(id: $id, input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation ManageFormPage_updateForm($id: ID!, $input: UpdateFormInput!) {\n        updateForm(id: $id, input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ManageFormsPage_deleteForm($id: ID!) {\n        deleteForm(id: $id)\n      }\n    "): (typeof documents)["\n      mutation ManageFormsPage_deleteForm($id: ID!) {\n        deleteForm(id: $id)\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query ManageFormsPage {\n        forms {\n          id\n          name\n        }\n        currentTenant {\n          membershipFormId\n        }\n      } \n    "): (typeof documents)["\n      query ManageFormsPage {\n        forms {\n          id\n          name\n        }\n        currentTenant {\n          membershipFormId\n        }\n      } \n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query ManageGroupPage($id: ID!) {\n        group(id: $id) {\n          id\n          name\n          isProtected\n          permissions\n        }\n      }\n    "): (typeof documents)["\n      query ManageGroupPage($id: ID!) {\n        group(id: $id) {\n          id\n          name\n          isProtected\n          permissions\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ManageGroupPage_updateGroup(\n        $id: ID!\n        $input: UpdateGroupInput!\n      ) {\n        updateGroup(id: $id, input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation ManageGroupPage_updateGroup(\n        $id: ID!\n        $input: UpdateGroupInput!\n      ) {\n        updateGroup(id: $id, input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ManageGroupsPage_deleteGroup($id: ID!) {\n        deleteGroup(id: $id)\n      }\n    "): (typeof documents)["\n      mutation ManageGroupsPage_deleteGroup($id: ID!) {\n        deleteGroup(id: $id)\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query ManageGroupsPage {\n        groups {\n          id\n          name\n          isProtected\n        }\n      }\n    "): (typeof documents)["\n      query ManageGroupsPage {\n        groups {\n          id\n          name\n          isProtected\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ManageGroupsPage_createGroup($input: CreateGroupInput!) {\n        createGroup(input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation ManageGroupsPage_createGroup($input: CreateGroupInput!) {\n        createGroup(input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation ManageTenant_LocalizationTab_updateTenant($input: UpdateTenantInput!) {\n        updateTenant(input: $input) {\n            id\n        }\n    }"): (typeof documents)["\n    mutation ManageTenant_LocalizationTab_updateTenant($input: UpdateTenantInput!) {\n        updateTenant(input: $input) {\n            id\n        }\n    }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation ManageTenant_GeneralTab_updateTenant($input: UpdateTenantInput!) {\n        updateTenant(input: $input) {\n            id\n        }\n    }"): (typeof documents)["\n    mutation ManageTenant_GeneralTab_updateTenant($input: UpdateTenantInput!) {\n        updateTenant(input: $input) {\n            id\n        }\n    }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query ManageTenantsPage($filter: TenantsFilter) {\n        tenants(filter: $filter) {\n          id\n          name\n        }\n      }\n    "): (typeof documents)["\n      query ManageTenantsPage($filter: TenantsFilter) {\n        tenants(filter: $filter) {\n          id\n          name\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation UpdateMembership($id: ID!, $input: UpdateMembershipInput!) {\n        updateMembership(id: $id, input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation UpdateMembership($id: ID!, $input: UpdateMembershipInput!) {\n        updateMembership(id: $id, input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation UpdateNotificationSettings_update(\n        $input: UpdateNotificationSettingsInput!\n      ) {\n        updateNotificationSettings(input: $input)\n      }\n    "): (typeof documents)["\n      mutation UpdateNotificationSettings_update(\n        $input: UpdateNotificationSettingsInput!\n      ) {\n        updateNotificationSettings(input: $input)\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query NotificationSettings {\n      currentUser {\n        id\n        notificationSettings\n      }\n    }"): (typeof documents)["\n    query NotificationSettings {\n      currentUser {\n        id\n        notificationSettings\n      }\n    }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query PaymentMethodPage($id: ID!) {\n        userPaymentMethod(id: $id) {\n          id\n          method\n          name\n          email\n          phone\n          iban\n          status\n          membershipPlans {\n            id\n            plan {\n                id\n                name\n            }\n          }\n        }\n      }\n    "): (typeof documents)["\n      query PaymentMethodPage($id: ID!) {\n        userPaymentMethod(id: $id) {\n          id\n          method\n          name\n          email\n          phone\n          iban\n          status\n          membershipPlans {\n            id\n            plan {\n                id\n                name\n            }\n          }\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation PaymentMethodPage_deletePaymentMethod($id: ID!) {\n        deleteUserPaymentMethod(id: $id) \n      }\n    "): (typeof documents)["\n      mutation PaymentMethodPage_deletePaymentMethod($id: ID!) {\n        deleteUserPaymentMethod(id: $id) \n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation PaymentMethodPage_updateMembershipPlan(\n        $id: ID!\n        $input: UpdateMembershipPlanInput!\n      ) {\n        updateMembershipPlan(id: $id, input: $input) {\n          id\n          paymentMethodId\n        }\n      }\n    "): (typeof documents)["\n      mutation PaymentMethodPage_updateMembershipPlan(\n        $id: ID!\n        $input: UpdateMembershipPlanInput!\n      ) {\n        updateMembershipPlan(id: $id, input: $input) {\n          id\n          paymentMethodId\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query PaymentsPage($user: ID!, $now: DateTime!) {\n        futurePayments: payments(filter: { date: { gt: $now }, userId: $user }) {\n          id\n          ...PaymentsList_payments\n        }\n\n        payments(filter: { date: { lte: $now }, userId: $user }) {\n          id\n          ...PaymentsList_payments\n        }\n      }\n    "): (typeof documents)["\n      query PaymentsPage($user: ID!, $now: DateTime!) {\n        futurePayments: payments(filter: { date: { gt: $now }, userId: $user }) {\n          id\n          ...PaymentsList_payments\n        }\n\n        payments(filter: { date: { lte: $now }, userId: $user }) {\n          id\n          ...PaymentsList_payments\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query PlansPage {\n        currentUser {\n          membership {\n            plans {\n              details: plan {\n                id\n                name\n                group {\n                  id\n                  name\n                }\n              }\n              id\n              nextPaymentDate\n              nextPaymentStart\n              nextPaymentEnd\n              validUntil\n              paymentMethodId\n            }\n          }\n        }\n      }\n    "): (typeof documents)["\n      query PlansPage {\n        currentUser {\n          membership {\n            plans {\n              details: plan {\n                id\n                name\n                group {\n                  id\n                  name\n                }\n              }\n              id\n              nextPaymentDate\n              nextPaymentStart\n              nextPaymentEnd\n              validUntil\n              paymentMethodId\n            }\n          }\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation PlansPage_updateMembershipPlan(\n        $id: ID!,\n        $input: UpdateMembershipPlanInput!\n      ) {\n        updateMembershipPlan(id: $id, input: $input) {\n          id\n          paymentMethodId\n        }\n      }\n    "): (typeof documents)["\n      mutation PlansPage_updateMembershipPlan(\n        $id: ID!,\n        $input: UpdateMembershipPlanInput!\n      ) {\n        updateMembershipPlan(id: $id, input: $input) {\n          id\n          paymentMethodId\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n     query PaymentMethods {\n        userPaymentMethods {\n            id\n            method\n            name\n            email\n            phone\n            iban\n            status\n            membershipPlans {\n              id\n              plan {\n                id\n                name\n                tenant {\n                  id\n                  name\n                }\n              }\n            }\n        }\n     }\n    "): (typeof documents)["\n     query PaymentMethods {\n        userPaymentMethods {\n            id\n            method\n            name\n            email\n            phone\n            iban\n            status\n            membershipPlans {\n              id\n              plan {\n                id\n                name\n                tenant {\n                  id\n                  name\n                }\n              }\n            }\n        }\n     }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation PaymentMethods_createUserPaymentMethod($input: CreateUserPaymentMethodInput!) {\n      createUserPaymentMethod(input: $input) {\n        id\n      }\n    }\n  "): (typeof documents)["\n    mutation PaymentMethods_createUserPaymentMethod($input: CreateUserPaymentMethodInput!) {\n      createUserPaymentMethod(input: $input) {\n        id\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ProfilePageUpdateUserMutation($input: UpdateUserInput!) {\n        updateUser(input: $input) {\n          id\n          name\n          email\n          avatarUrl\n        }\n      }\n    "): (typeof documents)["\n      mutation ProfilePageUpdateUserMutation($input: UpdateUserInput!) {\n        updateUser(input: $input) {\n          id\n          name\n          email\n          avatarUrl\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ProfilePage_deleteAccount($password: String!) {\n        deleteAccount(password: $password)\n      }\n    "): (typeof documents)["\n      mutation ProfilePage_deleteAccount($password: String!) {\n        deleteAccount(password: $password)\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation EmailTab_changeEmail($email: String!) {\n        changeEmail(email: $email)\n      }\n    "): (typeof documents)["\n      mutation EmailTab_changeEmail($email: String!) {\n        changeEmail(email: $email)\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query EmailTab {\n        currentUser {\n          email\n          hasPassword\n        }\n      }\n    "): (typeof documents)["\n      query EmailTab {\n        currentUser {\n          email\n          hasPassword\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation PasswordTab_forgotPassword($email: String!) {\n        forgotPassword(email: $email)\n      }\n    "): (typeof documents)["\n      mutation PasswordTab_forgotPassword($email: String!) {\n        forgotPassword(email: $email)\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation PasswordTab_changePassword($input: ChangePasswordInput!) {\n        changePassword(input: $input)\n      }\n    "): (typeof documents)["\n      mutation PasswordTab_changePassword($input: ChangePasswordInput!) {\n        changePassword(input: $input)\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query PasswordTab {\n        currentUser {\n          email\n          hasPassword\n        }\n      }\n    "): (typeof documents)["\n      query PasswordTab {\n        currentUser {\n          email\n          hasPassword\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query CheckoutModal_userPaymentMethods {\n        userPaymentMethods {\n          id\n          method\n          name\n          email\n          phone\n          iban\n          status\n        }\n      }\n    "): (typeof documents)["\n      query CheckoutModal_userPaymentMethods {\n        userPaymentMethods {\n          id\n          method\n          name\n          email\n          phone\n          iban\n          status\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation UserPaymentMethodSelect_createUserPaymentMethod(\n        $input: CreateUserPaymentMethodInput!\n      ) {\n        createUserPaymentMethod(input: $input) {\n          id\n          status\n        }\n      }\n    "): (typeof documents)["\n      mutation UserPaymentMethodSelect_createUserPaymentMethod(\n        $input: CreateUserPaymentMethodInput!\n      ) {\n        createUserPaymentMethod(input: $input) {\n          id\n          status\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation VerifyEmail($token: String!, $type: String!) {\n        verifyEmail(token: $token, type: $type) {\n          success\n          token\n        }\n      }\n    "): (typeof documents)["\n      mutation VerifyEmail($token: String!, $type: String!) {\n        verifyEmail(token: $token, type: $type) {\n          success\n          token\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation CreateScoreCategoryPage_createScoreCategory($input: CreateScoreCategoryInput!) {\n        createScoreCategory(input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation CreateScoreCategoryPage_createScoreCategory($input: CreateScoreCategoryInput!) {\n        createScoreCategory(input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation CreateWorkoutCategoryPage_createWorkoutCategory($input: CreateWorkoutCategoryInput!) {\n        createWorkoutCategory(input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation CreateWorkoutCategoryPage_createWorkoutCategory($input: CreateWorkoutCategoryInput!) {\n        createWorkoutCategory(input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query WorkoutSearch_getWorkouts(\n        $filter: GetWorkoutsFilter!\n        $pagination: PaginationInput!\n      ) {\n        workouts(filter: $filter, pagination: $pagination) {\n          id\n          name\n          description\n          date\n        }\n      }\n    "): (typeof documents)["\n      query WorkoutSearch_getWorkouts(\n        $filter: GetWorkoutsFilter!\n        $pagination: PaginationInput!\n      ) {\n        workouts(filter: $filter, pagination: $pagination) {\n          id\n          name\n          description\n          date\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query CreateWorkoutOccurrencePage {\n        currentTenant {\n          id\n          workoutsSettings\n        }\n      }\n    "): (typeof documents)["\n      query CreateWorkoutOccurrencePage {\n        currentTenant {\n          id\n          workoutsSettings\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation CreateWorkoutOcurrence($input: CreateWorkoutOccurrenceInput!) {\n        createWorkoutOccurrence(input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation CreateWorkoutOcurrence($input: CreateWorkoutOccurrenceInput!) {\n        createWorkoutOccurrence(input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query CreateWorkoutOccurencePage_getWorkout($id: ID!) {\n        workout(id: $id) {\n          id\n          parent {\n            id\n            name\n            date\n            isFeatured\n          }\n          scoreType\n          name\n          date\n          isFeatured\n          coachesNotes\n          nameTranslations {\n            ...TranslationFragment\n          }\n          description\n          descriptionTranslations {\n            ...TranslationFragment\n          }\n          scoreCategoriesIds\n        }\n      }\n    "): (typeof documents)["\n      query CreateWorkoutOccurencePage_getWorkout($id: ID!) {\n        workout(id: $id) {\n          id\n          parent {\n            id\n            name\n            date\n            isFeatured\n          }\n          scoreType\n          name\n          date\n          isFeatured\n          coachesNotes\n          nameTranslations {\n            ...TranslationFragment\n          }\n          description\n          descriptionTranslations {\n            ...TranslationFragment\n          }\n          scoreCategoriesIds\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query CreateWorkoutPage {\n        currentTenant {\n          id\n          workoutsSettings\n        }\n      }\n    "): (typeof documents)["\n      query CreateWorkoutPage {\n        currentTenant {\n          id\n          workoutsSettings\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation CreateWorkout($input: CreateWorkoutInput!) {\n        createWorkout(input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation CreateWorkout($input: CreateWorkoutInput!) {\n        createWorkout(input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query ManageScoreCategoriesPage {\n        scoreCategories {\n          id\n          name\n        }\n      }\n    "): (typeof documents)["\n      query ManageScoreCategoriesPage {\n        scoreCategories {\n          id\n          name\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query ManageScoreCategoryPage($id: ID!) {\n        scoreCategory(id: $id) {\n          id\n          name\n          nameTranslations {\n            ...TranslationFragment\n          }\n        }\n      }\n    "): (typeof documents)["\n      query ManageScoreCategoryPage($id: ID!) {\n        scoreCategory(id: $id) {\n          id\n          name\n          nameTranslations {\n            ...TranslationFragment\n          }\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ManageScoreCategoryPage_updateScoreCategory($id: ID!, $input: UpdateScoreCategoryInput!) {\n        updateScoreCategory(id: $id, input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation ManageScoreCategoryPage_updateScoreCategory($id: ID!, $input: UpdateScoreCategoryInput!) {\n        updateScoreCategory(id: $id, input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ManageScoreCategoryPage_deleteScoreCategory($id: ID!) {\n        deleteScoreCategory(id: $id)\n      }\n    "): (typeof documents)["\n      mutation ManageScoreCategoryPage_deleteScoreCategory($id: ID!) {\n        deleteScoreCategory(id: $id)\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query ManageWorkoutCategoryPage($id: ID!) {\n        workoutCategory(id: $id) {\n          id\n          name\n          nameTranslations {\n            ...TranslationFragment\n          }\n          parentId\n        }\n      }\n    "): (typeof documents)["\n      query ManageWorkoutCategoryPage($id: ID!) {\n        workoutCategory(id: $id) {\n          id\n          name\n          nameTranslations {\n            ...TranslationFragment\n          }\n          parentId\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ManageWorkoutCategoryPage_updateWorkoutCategory($id: ID!, $input: UpdateWorkoutCategoryInput!) {\n        updateWorkoutCategory(id: $id, input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation ManageWorkoutCategoryPage_updateWorkoutCategory($id: ID!, $input: UpdateWorkoutCategoryInput!) {\n        updateWorkoutCategory(id: $id, input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ManageWorkoutCategoryPage_deleteCategory($id: ID!) {\n        deleteWorkoutCategory(id: $id)\n      }\n    "): (typeof documents)["\n      mutation ManageWorkoutCategoryPage_deleteCategory($id: ID!) {\n        deleteWorkoutCategory(id: $id)\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ManageWorkoutPage_deleteWorkout($id: ID!) {\n        deleteWorkout(id: $id)\n      }\n    "): (typeof documents)["\n      mutation ManageWorkoutPage_deleteWorkout($id: ID!) {\n        deleteWorkout(id: $id)\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query ManageWorkoutDrawer($id: ID!) {\n        workout(id: $id) {\n          id\n          name\n          nameTranslations {\n            ...TranslationFragment\n          }\n          description\n          descriptionTranslations {\n            ...TranslationFragment\n          }\n          categoryId\n          classTypesIds\n          scoreCategoriesIds\n          usersIds\n          scoreType\n          isFeatured\n          coachesNotes\n          date\n          availability\n        }\n      }\n    "): (typeof documents)["\n      query ManageWorkoutDrawer($id: ID!) {\n        workout(id: $id) {\n          id\n          name\n          nameTranslations {\n            ...TranslationFragment\n          }\n          description\n          descriptionTranslations {\n            ...TranslationFragment\n          }\n          categoryId\n          classTypesIds\n          scoreCategoriesIds\n          usersIds\n          scoreType\n          isFeatured\n          coachesNotes\n          date\n          availability\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ManageWorkoutDrawer_createWorkout($input: CreateWorkoutOccurrenceInput!) {\n        createWorkoutOccurrence(input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation ManageWorkoutDrawer_createWorkout($input: CreateWorkoutOccurrenceInput!) {\n        createWorkoutOccurrence(input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ManageWorkoutDrawer_updateWorkout($id: ID!, $input: UpdateWorkoutInput!) {\n        updateWorkout(id: $id, input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation ManageWorkoutDrawer_updateWorkout($id: ID!, $input: UpdateWorkoutInput!) {\n        updateWorkout(id: $id, input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query ManageWorkoutPage($id: ID!) {\n        workout(id: $id) {\n          id\n          name\n          nameTranslations {\n            ...TranslationFragment\n          }\n          description\n          descriptionTranslations {\n            ...TranslationFragment\n          }\n          categoryId\n          classTypesIds\n          scoreCategoriesIds\n          usersIds\n          scoreType\n          repetitions\n          isFeatured\n          coachesNotes\n          date\n          availability\n        }\n      }\n    "): (typeof documents)["\n      query ManageWorkoutPage($id: ID!) {\n        workout(id: $id) {\n          id\n          name\n          nameTranslations {\n            ...TranslationFragment\n          }\n          description\n          descriptionTranslations {\n            ...TranslationFragment\n          }\n          categoryId\n          classTypesIds\n          scoreCategoriesIds\n          usersIds\n          scoreType\n          repetitions\n          isFeatured\n          coachesNotes\n          date\n          availability\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ManageWorkoutPage_updateWorkout($id: ID!, $input: UpdateWorkoutInput!) {\n        updateWorkout(id: $id, input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation ManageWorkoutPage_updateWorkout($id: ID!, $input: UpdateWorkoutInput!) {\n        updateWorkout(id: $id, input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query ManageWorkoutsSettingsPage {\n        currentTenant {\n          id\n          workoutsSettings\n        }\n      }\n    "): (typeof documents)["\n      query ManageWorkoutsSettingsPage {\n        currentTenant {\n          id\n          workoutsSettings\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation ManageWorkoutsSettingsPage_updateTenant($input: UpdateTenantInput!) {\n      updateTenant(input: $input) {\n        id\n        workoutsSettings\n      }\n    }"): (typeof documents)["\n    mutation ManageWorkoutsSettingsPage_updateTenant($input: UpdateTenantInput!) {\n      updateTenant(input: $input) {\n        id\n        workoutsSettings\n      }\n    }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query RecordPage($id: ID!, $user: ID!) {\n        workout(id: $id) {\n          id\n          name\n          description\n          ...WorkoutBestScore_workout\n          ...WorkoutScoreHistory\n        }\n      }\n    "): (typeof documents)["\n      query RecordPage($id: ID!, $user: ID!) {\n        workout(id: $id) {\n          id\n          name\n          description\n          ...WorkoutBestScore_workout\n          ...WorkoutScoreHistory\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query RegisterScorePage($id: ID!, $user: ID!) {\n        workout(id: $id) {\n          id\n          parentId\n          name\n          description\n          scoreType\n          repetitions\n          scoreCategories {\n            id\n            name\n          }\n          scores(userId: $user) {\n            id\n            categoryId\n            scoreIntA\n            scoreIntB\n            notes\n          }\n        }\n      }\n    "): (typeof documents)["\n      query RegisterScorePage($id: ID!, $user: ID!) {\n        workout(id: $id) {\n          id\n          parentId\n          name\n          description\n          scoreType\n          repetitions\n          scoreCategories {\n            id\n            name\n          }\n          scores(userId: $user) {\n            id\n            categoryId\n            scoreIntA\n            scoreIntB\n            notes\n          }\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation RegisterScorePage_setWorkoutScore($input: SetWorkoutScoreInput!) {\n      setWorkoutScore(input: $input) {\n        id\n      }\n    }\n  "): (typeof documents)["\n    mutation RegisterScorePage_setWorkoutScore($input: SetWorkoutScoreInput!) {\n      setWorkoutScore(input: $input) {\n        id\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query ScoreCategorySelect {\n        scoreCategories {\n          id\n          name\n        }\n      }\n    "): (typeof documents)["\n      query ScoreCategorySelect {\n        scoreCategories {\n          id\n          name\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation ScoreCategorySelect_createScoreCategory($input: CreateScoreCategoryInput!) {\n        createScoreCategory(input: $input) {\n          id\n          name\n        }\n      }\n    "): (typeof documents)["\n      mutation ScoreCategorySelect_createScoreCategory($input: CreateScoreCategoryInput!) {\n        createScoreCategory(input: $input) {\n          id\n          name\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      fragment WorkoutBestScore_workout on Workout {\n        scoreType\n        scoreCategories {\n          id\n          name\n        }\n        scores(userId: $user) {\n          id\n          date\n          scoreIntA\n          scoreIntB\n          scoreBool\n          scoreText\n          category {\n            id\n            name\n          }\n        }\n      }\n    "): (typeof documents)["\n      fragment WorkoutBestScore_workout on Workout {\n        scoreType\n        scoreCategories {\n          id\n          name\n        }\n        scores(userId: $user) {\n          id\n          date\n          scoreIntA\n          scoreIntB\n          scoreBool\n          scoreText\n          category {\n            id\n            name\n          }\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query WorkoutCategoriesTable($parent: String) {\n        workoutCategories(filter: { parentId: $parent }) {\n          id\n          name\n          parentId\n          order\n        }\n      }\n    "): (typeof documents)["\n      query WorkoutCategoriesTable($parent: String) {\n        workoutCategories(filter: { parentId: $parent }) {\n          id\n          name\n          parentId\n          order\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation WorkoutCategoriesTable_updateCategory($id: ID!, $input: UpdateWorkoutCategoryInput!) {\n        updateWorkoutCategory(id: $id, input: $input) {\n          id\n          parentId\n          order\n        }\n      }\n    "): (typeof documents)["\n      mutation WorkoutCategoriesTable_updateCategory($id: ID!, $input: UpdateWorkoutCategoryInput!) {\n        updateWorkoutCategory(id: $id, input: $input) {\n          id\n          parentId\n          order\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query WorkoutCategorySelect {\n        workoutCategories {\n          id\n          name\n          order\n          parentId\n        }\n      }\n    "): (typeof documents)["\n      query WorkoutCategorySelect {\n        workoutCategories {\n          id\n          name\n          order\n          parentId\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query WorkoutPage($id: ID!, $canReadComments: Boolean!) {\n        workout(id: $id) {\n          id\n          name\n          description\n          scoreType\n          coachesNotes\n          comments @include(if: $canReadComments) {\n            ...CommentsCard_comments\n          }\n\n          ...WorkoutScores_workout\n        \n        }\n      }\n    "): (typeof documents)["\n      query WorkoutPage($id: ID!, $canReadComments: Boolean!) {\n        workout(id: $id) {\n          id\n          name\n          description\n          scoreType\n          coachesNotes\n          comments @include(if: $canReadComments) {\n            ...CommentsCard_comments\n          }\n\n          ...WorkoutScores_workout\n        \n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment WorkoutScoreHistory on Workout {\n      scoreType\n      scoreCategories {\n        id\n        name\n      }\n      scores(userId: $user) {\n        id\n        workoutId\n        date\n        scoreIntA\n        scoreIntB\n        category {\n          id\n          name\n        }\n        notes\n        tenant {\n          id\n          name\n        }\n      }\n    }\n    "): (typeof documents)["\n    fragment WorkoutScoreHistory on Workout {\n      scoreType\n      scoreCategories {\n        id\n        name\n      }\n      scores(userId: $user) {\n        id\n        workoutId\n        date\n        scoreIntA\n        scoreIntB\n        category {\n          id\n          name\n        }\n        notes\n        tenant {\n          id\n          name\n        }\n      }\n    }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation WorkoutScoreHistory_deleteScore($id: ID!) {\n        deleteWorkoutScore(id: $id)\n      }\n    "): (typeof documents)["\n      mutation WorkoutScoreHistory_deleteScore($id: ID!) {\n        deleteWorkoutScore(id: $id)\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation WorkoutScores_deleteScore($id: ID!) {\n        deleteWorkoutScore(id: $id)\n      }\n    "): (typeof documents)["\n      mutation WorkoutScores_deleteScore($id: ID!) {\n        deleteWorkoutScore(id: $id)\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      fragment WorkoutScores_workout on Workout {\n        id\n        scoreType\n        scoreCategories {\n          id\n          name\n        }\n        scores {\n          id\n          categoryId\n          user {\n            id\n            name\n          }\n          scoreIntA\n          reactions {\n            ...ReactionsBar_reactions\n          }\n        }\n      }\n    "): (typeof documents)["\n      fragment WorkoutScores_workout on Workout {\n        id\n        scoreType\n        scoreCategories {\n          id\n          name\n        }\n        scores {\n          id\n          categoryId\n          user {\n            id\n            name\n          }\n          scoreIntA\n          reactions {\n            ...ReactionsBar_reactions\n          }\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query WorkoutSelect {\n        workoutCategories {\n          id\n          name\n          order\n          parentId\n          workouts {\n            id\n            name\n          }\n        }\n      }\n    "): (typeof documents)["\n      query WorkoutSelect {\n        workoutCategories {\n          id\n          name\n          order\n          parentId\n          workouts {\n            id\n            name\n          }\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query WorkoutsCalendar($filter: GetWorkoutsFilter) {\n        workouts(filter: $filter) {\n          id\n          name\n          date\n        }\n      }\n    "): (typeof documents)["\n      query WorkoutsCalendar($filter: GetWorkoutsFilter) {\n        workouts(filter: $filter) {\n          id\n          name\n          date\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query WorkoutsList {\n        workoutCategories {\n          id\n          parentId\n          name\n          order\n        } \n      }\n    "): (typeof documents)["\n      query WorkoutsList {\n        workoutCategories {\n          id\n          parentId\n          name\n          order\n        } \n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query WorkoutCategoryList($categories: [String!], $includeScores: Boolean!) {\n        workouts(filter: { categories: $categories, isFeatured: true }) {\n          id\n          name\n          categoryId\n          scoreType\n          bestScore @include(if: $includeScores) {\n            id\n            category {\n              id\n              name\n            }\n            scoreIntA\n            scoreIntB\n          }\n        }\n      }\n    "): (typeof documents)["\n      query WorkoutCategoryList($categories: [String!], $includeScores: Boolean!) {\n        workouts(filter: { categories: $categories, isFeatured: true }) {\n          id\n          name\n          categoryId\n          scoreType\n          bestScore @include(if: $includeScores) {\n            id\n            category {\n              id\n              name\n            }\n            scoreIntA\n            scoreIntB\n          }\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query WorkoutsPage($filter: GetWorkoutsFilter) {\n        workouts(filter: $filter) {\n          id\n          name\n          description\n          scoreType\n        }\n      }\n    "): (typeof documents)["\n      query WorkoutsPage($filter: GetWorkoutsFilter) {\n        workouts(filter: $filter) {\n          id\n          name\n          description\n          scoreType\n        }\n      }\n    "];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;