import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { GraphQLScalarType, Kind } from "graphql";

export type DateTime = Dayjs;

export const DateTime = new GraphQLScalarType({
  name: "DateTime",
  description: "Date scalar type",
  parseValue(value: unknown) {
    return dayjs(value as string);
  },
  serialize(value: unknown) {
    if (dayjs.isDayjs(value)) {
      return value.toISOString();
    }
  },
  parseLiteral(ast) {
    if (ast.kind === Kind.STRING) {
      return dayjs(ast.value);
    }
    return null;
  },
});

export const JSONValue = new GraphQLScalarType({
  name: "JSONValue",
  description: "JSON scalar type",
  parseValue(value: unknown) {
    return JSON.parse(value as string);
  },
  serialize(value: unknown) {
    return JSON.stringify(value);
  },
  parseLiteral(ast) {
    if (ast.kind === Kind.STRING) {
      return JSON.parse(ast.value);
    }
    return null;
  },
});
