import { useContext } from "react";

import { PushNotificationContext } from "./NotificationProvider";

export function usePushNotification(): PushNotificationContext {
  const pushNotificationContext = useContext<PushNotificationContext | null>(
    PushNotificationContext
  );

  if (pushNotificationContext === null) {
    throw new Error(
      "usePushNotification must be inside a PushNotificationProvider"
    );
  }

  return pushNotificationContext;
}
