import { createContext, useRef, useState } from "react";

interface FooterContextType {
  footerRef: React.RefObject<HTMLDivElement>;
  hasFooter: boolean;
  setHasFooter: (hasFooter: boolean) => void;
}

export const FooterContext = createContext<FooterContextType | undefined>(
  undefined
);

interface FooterProviderProps {
  children: React.ReactNode;
}

export const FooterProvider = ({ children }: FooterProviderProps) => {
  const footerRef = useRef<HTMLDivElement>(null);
  const [hasFooter, setHasFooter] = useState(false);

  return (
    <FooterContext.Provider
      value={{
        footerRef,
        hasFooter,
        setHasFooter,
      }}
    >
      {children}
    </FooterContext.Provider>
  );
};
