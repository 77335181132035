import { useEffect, useState } from "react";
import type { Session } from "@supabase/supabase-js";

import { supabase } from "@/supabase";

interface AuthGuardProps {
  isAuthenticated?: boolean;
  fallback?: JSX.Element;
  children?: JSX.Element;
}

export const AuthGuard = ({
  isAuthenticated = true,
  fallback,
  children,
}: AuthGuardProps) => {
  const [session, setSession] = useState<Session | null>();

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
    return () => subscription.unsubscribe();
  }, []);

  if (session === undefined) {
    return null;
  }

  return (isAuthenticated && session) || (!isAuthenticated && !session)
    ? children
    : fallback;
};
