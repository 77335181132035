import type { ReactNode } from "react";
import { createContext, useMemo } from "react";
import type { FormInstance } from "antd";

export interface SmartFormContext extends FormInstance {
  hasSubmitted: boolean;
  hasSubmitFailed: boolean;
  isSubmitting: boolean;
  isDirty: boolean;
  hasErrors: boolean;
  allowPristine: boolean;
}

export const SmartFormContext = createContext<SmartFormContext | null>(null);

interface SmartFormProviderProps {
  children?: ReactNode;
  form: FormInstance;
  hasSubmitted: boolean;
  hasSubmitFailed: boolean;
  isSubmitting: boolean;
  isDirty: boolean;
  hasErrors: boolean;
  allowPristine: boolean;
}

export const SmartFormProvider = ({
  children,
  form,
  ...rest
}: SmartFormProviderProps) => {
  const smartFormContext = useMemo(
    () => ({
      ...form,
      ...rest,
    }),
    [form, rest]
  );

  return (
    <SmartFormContext.Provider value={smartFormContext}>
      {children}
    </SmartFormContext.Provider>
  );
};
