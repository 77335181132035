export const isForbiddenError = (error: unknown) => {
  return isError(error, { httpCode: 403, graphQLCode: "FORBIDDEN" });
};

export const isNotFoundError = (error: unknown) => {
  return isError(error, {
    httpCode: 404,
    graphQLCode: "NOT_FOUND",
    type: NotFoundError,
  });
};

export const isError = (
  error: unknown,
  {
    graphQLCode,
    httpCode,
    type,
  }: { graphQLCode?: string; httpCode?: number; type?: unknown }
) => {
  if (typeof error !== "object" || error === null) {
    return false;
  }

  if (type && typeof type === "function" && error instanceof type) {
    return true;
  }

  if ("graphQLErrors" in error) {
    const err = error as {
      graphQLErrors?: Array<{
        extensions?: { code?: string; status?: number };
      }>;
    };

    return err.graphQLErrors?.some(
      (e) =>
        e.extensions?.code === graphQLCode || e.extensions?.status === httpCode
    );
  }

  if ("status" in error) {
    const err = error as { status?: number };

    return err.status === httpCode;
  }

  if ("response" in error) {
    const err = error as { response?: { status?: number } };

    return err.response?.status === httpCode;
  }

  return false;
};

export class NotFoundError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "NotFoundError";
  }
}
