import {
  createBrowserRouter,
  Navigate,
  Outlet,
  type RouteObject,
  RouterProvider,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { AuthLayout } from "@/components/auth/AuthLayout/AuthLayout";
import { AuthGuard } from "@/components/common/AuthGuard/AuthGuard";
import { generateLink } from "@/components/common/common.utils";
import { Loading } from "@/components/common/Loading/Loading";
import { PushNotificationProvider } from "@/components/common/NotificationProvider/NotificationProvider";
import ErrorBoundary from "@/components/pages/Error";

import { GlobalModalProvider } from "../common/GlobalModalProvider/GlobalModalProvider";
import { UserProvider } from "../common/UserProvider/UserProvider";

import { Layout } from "./Layout";

const routes: RouteObject[] = [
  {
    path: "/logo-upload",
    lazy: async () => {
      const { AuthLayout } = await import(
        "@/components/auth/AuthLayout/AuthLayout"
      );
      return { Component: AuthLayout };
    },
    children: [
      {
        path: ":id?",
        lazy: async () => {
          const { LogoUploadPage } = await import(
            "@/components/temp/LogoUploadPage"
          );
          return { Component: LogoUploadPage };
        },
      },
    ],
  },
  {
    path: "/auth",
    Component: () => (
      <GlobalModalProvider>
        <AuthGuard isAuthenticated={false} fallback={<Navigate to="/" />}>
          <AuthLayout>
            <Outlet />
          </AuthLayout>
        </AuthGuard>
      </GlobalModalProvider>
    ),
    children: [
      {
        index: true,
        lazy: async () => {
          const { LoginPage } = await import(
            "@/components/auth/LoginPage/LoginPage"
          );
          return { Component: LoginPage };
        },
      },
      {
        path: "signup",
        lazy: async () => {
          const { SignUpPage } = await import(
            "@/components/auth/SignUpPage/SignUpPage"
          );
          return { Component: SignUpPage };
        },
      },
      {
        path: "reset-password",
        lazy: async () => {
          const { ResetPasswordPage } = await import(
            "@/components/auth/ResetPasswordPage/ResetPasswordPage"
          );
          return { Component: ResetPasswordPage };
        },
      },
      {
        path: "verify-email",
        lazy: async () => {
          const { VerifyEmailPage } = await import(
            "@/components/user/VerifyEmailPage/VerifyEmailPage"
          );
          return { Component: VerifyEmailPage };
        },
        handle: {
          requireEmailVerified: false,
        },
      },
    ],
  },
  {
    id: "root",
    path: "/",
    errorElement: (
      <AuthGuard fallback={<Navigate to="/auth" />}>
        <UserProvider>
          <PushNotificationProvider>
            <Layout navigation>
              <ErrorBoundary />
            </Layout>
          </PushNotificationProvider>
        </UserProvider>
      </AuthGuard>
    ),
    Component: () => {
      const location = useLocation();
      const params = useParams();

      return (
        <AuthGuard
          fallback={
            <Navigate
              to="/auth"
              state={{
                from: location.pathname,
                ...params,
              }}
            />
          }
        >
          <UserProvider>
            <GlobalModalProvider>
              <PushNotificationProvider>
                <Layout navigation />
              </PushNotificationProvider>
            </GlobalModalProvider>
          </UserProvider>
        </AuthGuard>
      );
    },
    children: [
      {
        errorElement: <ErrorBoundary />,
        children: [
          {
            id: "invitation",
            path: "invitation/:invite",
            errorElement: <ErrorBoundary />,
            lazy: async () => {
              const { InvitationPage } = await import(
                "@/components/tenant/InvitationPage/InvitationPage"
              );
              return { Component: InvitationPage };
            },
            handle: {
              hideMenu: true,
              requireEmailVerified: false,
            },
          },
          {
            index: true,
            lazy: async () => {
              const { HomePage: Component } = await import(
                "@/components/HomePage/HomePage"
              );
              return { Component };
            },
            handle: {
              selectedMenuKey: "home",
            },
          },
          {
            path: "browse",
            lazy: async () => {
              const { TenantSelectPage: Component } = await import(
                "@/components/user/TenantSelectPage/TenantSelectPage"
              );
              return { Component };
            },
          },
          {
            lazy: async () => {
              const { HomePage: Component } = await import(
                "@/components/HomePage/HomePage"
              );
              return { Component };
            },
            handle: {
              selectedMenuKey: "home",
            },
          },
          {
            path: "classes",
            children: [
              {
                index: true,
                lazy: async () => {
                  const { ClassesPage } = await import(
                    "@/components/classes/ClassesPage/ClassesPage"
                  );
                  return { Component: ClassesPage };
                },
              },
              {
                path: ":id",
                children: [
                  {
                    index: true,
                    lazy: async () => {
                      const { ClassPage } = await import(
                        "@/components/classes/ClassPage/ClassPage"
                      );
                      return { Component: ClassPage };
                    },
                    handle: {
                      back: {
                        to: -1,
                      },
                    },
                  },
                  {
                    path: "manage",
                    lazy: async () => {
                      const { ManageClassPage } = await import(
                        "@/components/classes/ManageClassPage/ManageClassPage"
                      );
                      return {
                        Component: () => {
                          return <ManageClassPage />;
                        },
                      };
                    },
                    handle: {
                      back: {
                        to: -1,
                      },
                    },
                  },
                ],
                handle: {
                  selectedMenuKey: "classes",
                },
              },
            ],
            handle: {
              selectedMenuKey: "classes",
            },
          },
          {
            path: "bookings",
            lazy: async () => {
              const { SpacesPage } = await import(
                "@/components/spaces/SpacesPage/SpacesPage"
              );
              return { Component: SpacesPage };
            },
            handle: {
              selectedMenuKey: "bookings",
            },
          },
          {
            path: "bookings/:id",
            lazy: async () => {
              const { SpacePage } = await import(
                "@/components/spaces/SpacePage/SpacePage"
              );
              return { Component: SpacePage };
            },
            handle: {
              selectedMenuKey: "bookings",
            },
          },
          {
            path: "workouts",
            lazy: async () => {
              const { WorkoutsPage } = await import(
                "@/components/workouts/WorkoutsPage/WorkoutsPage"
              );
              return { Component: WorkoutsPage };
            },
            handle: {
              selectedMenuKey: "workouts",
            },
          },
          {
            path: "workouts/:id",
            lazy: async () => {
              const { WorkoutPage } = await import(
                "@/components/workouts/WorkoutPage/WorkoutPage"
              );
              return { Component: WorkoutPage };
            },
            handle: {
              selectedMenuKey: "workouts",
            },
          },
          {
            path: "workouts/:workoutId/scores/:userId?/register",
            lazy: async () => {
              const { RegisterScorePage } = await import(
                "@/components/workouts/RegisterScorePage/RegisterScorePage"
              );
              return { Component: RegisterScorePage };
            },
            handle: {
              selectedMenuKey: "workouts",
            },
          },
          {
            path: "records/:workoutId/scores/:userId?/register",
            lazy: async () => {
              const { RegisterScorePage } = await import(
                "@/components/workouts/RegisterScorePage/RegisterScorePage"
              );
              return { Component: RegisterScorePage };
            },
            handle: {
              selectedMenuKey: "records",
            },
          },
          {
            path: "records",
            lazy: async () => {
              const { RecordsPage } = await import(
                "@/components/workouts/RecordsPage/RecordsPage"
              );
              return { Component: RecordsPage };
            },
            handle: {
              selectedMenuKey: "records",
            },
          },
          {
            path: "records/:id",
            lazy: async () => {
              const { RecordPage } = await import(
                "@/components/workouts/RecordPage/RecordPage"
              );
              return { Component: RecordPage };
            },
            handle: {
              selectedMenuKey: "records",
            },
          },
          /* Postponed          {
            path: "events/:event/registrations/:registration",
            lazy: async () => {
              const { ManageRegistrationPage } = await import(
                "@/components/events/ManageRegistrationPage/ManageRegistrationPage"
              );
              return { Component: ManageRegistrationPage };
            },
            handle: {
              selectedMenuKey: "events",
            },
          },
          {
            path: "events/:id/sign-up",
            lazy: async () => {
              const { SignUpPage } = await import(
                "@/components/events/SignUpPage/SignUpPage"
              );
              return { Component: SignUpPage };
            },
            handle: {
              selectedMenuKey: "events",
            },
          },
          {
            path: "events/:id",
            lazy: async () => {
              const { EventPage } = await import(
                "@/components/events/EventPage/EventPage"
              );
              return { Component: EventPage };
            },
            handle: {
              selectedMenuKey: "events",
            },
          },
          {
            path: "events",
            lazy: async () => {
              const { EventsPage } = await import(
                "@/components/events/EventsPage/EventsPage"
              );
              return { Component: EventsPage };
            },
            handle: {
              selectedMenuKey: "events",
            },
          }, */
          {
            path: "admin",
            handle: {
              selectedMenuKey: "admin",
            },
            children: [
              {
                path: "members",
                handle: {
                  selectedMenuKey: "admin/members",
                },
                children: [
                  {
                    index: true,
                    lazy: async () => {
                      const { ManageMembersPage } = await import(
                        "@/components/members/ManageMembersPage/ManageMembersPage"
                      );
                      return { Component: ManageMembersPage };
                    },
                    handle: {
                      selectedMenuKey: "admin/members/list",
                    },
                  },
                  {
                    path: "add",
                    lazy: async () => {
                      const { AddMemberPage } = await import(
                        "@/components/members/AddMemberPage/AddMemberPage"
                      );
                      return { Component: AddMemberPage };
                    },
                    handle: {
                      selectedMenuKey: "admin/members",
                    },
                  },
                  {
                    path: "invites",
                    lazy: async () => {
                      const { InvitesPage } = await import(
                        "@/components/members/InvitesPage/InvitesPage"
                      );
                      return { Component: InvitesPage };
                    },
                    handle: {
                      selectedMenuKey: "admin/members/invites",
                    },
                  },
                  {
                    path: "groups",
                    handle: {
                      selectedMenuKey: "admin/members/groups",
                    },
                    children: [
                      {
                        index: true,
                        lazy: async () => {
                          const { ManageGroupsPage } = await import(
                            "@/components/tenant/ManageGroupsPage/ManageGroupsPage"
                          );
                          return { Component: ManageGroupsPage };
                        },
                      },
                      {
                        path: ":id",
                        lazy: async () => {
                          const { ManageGroupPage } = await import(
                            "@/components/tenant/ManageGroupPage/ManageGroupPage"
                          );
                          return { Component: ManageGroupPage };
                        },
                      },
                    ],
                  },
                  {
                    path: "settings",
                    lazy: async () => {
                      const { ManageMembersSettingsPage } = await import(
                        "@/components/members/ManageMembersSettingsPage/ManageMembersSettingsPage"
                      );
                      return { Component: ManageMembersSettingsPage };
                    },
                    handle: {
                      selectedMenuKey: "admin/members/settings",
                    },
                  },
                ],
              },
              {
                path: "members/:id/payments/new",
                lazy: async () => {
                  const { CreatePaymentPage } = await import(
                    "@/components/finance/CreatePaymentPage/CreatePaymentPage"
                  );
                  return {
                    Component: () => {
                      const params = useParams();

                      return <CreatePaymentPage userId={params.id} />;
                    },
                  };
                },
                handle: {
                  selectedMenuKey: "admin/members",
                },
              },
              {
                path: "members/:id/:tab?",
                lazy: async () => {
                  const { ManageMemberPage } = await import(
                    "@/components/members/ManageMemberPage/ManageMemberPage"
                  );
                  return { Component: ManageMemberPage };
                },
                handle: {
                  selectedMenuKey: "admin/members",
                },
              },
              {
                path: "classes",
                handle: {
                  selectedMenuKey: "admin/classes",
                },
                children: [
                  {
                    index: true,
                    Component: () => <Navigate to="schedule" />,
                  },
                  {
                    path: "create",
                    lazy: async () => {
                      const { CreateClassPage } = await import(
                        "@/components/classes/CreateClassPage/CreateClassPage"
                      );
                      return { Component: CreateClassPage };
                    },
                    handle: {
                      selectedMenuKey: "admin/classes",
                    },
                  },
                  {
                    path: ":id",
                    lazy: async () => {
                      const { ManageClassPage } = await import(
                        "@/components/classes/ManageClassPage/ManageClassPage"
                      );
                      return { Component: ManageClassPage };
                    },
                    handle: {
                      selectedMenuKey: "admin/classes",
                    },
                  },
                  {
                    path: "schedule",
                    lazy: async () => {
                      const { ManageClassesPage } = await import(
                        "@/components/classes/ManageClassesPage/ManageClassesPage"
                      );
                      return { Component: ManageClassesPage };
                    },
                    handle: {
                      selectedMenuKey: "admin/classes/schedule",
                    },
                  },
                  {
                    path: "types",
                    handle: {
                      selectedMenuKey: "admin/classes/types",
                    },
                    children: [
                      {
                        index: true,
                        lazy: async () => {
                          const { ManageClassTypesPage } = await import(
                            "@/components/classes/ManageClassTypesPage/ManageClassTypesPage"
                          );
                          return { Component: ManageClassTypesPage };
                        },
                      },
                      {
                        path: ":id",
                        lazy: async () => {
                          const { ManageClassTypePage } = await import(
                            "@/components/classes/ManageClassTypePage/ManageClassTypePage"
                          );
                          return { Component: ManageClassTypePage };
                        },
                      },
                      {
                        path: "create",
                        lazy: async () => {
                          const { CreateClassTypePage } = await import(
                            "@/components/classes/CreateClassTypePage/CreateClassTypePage"
                          );
                          return { Component: CreateClassTypePage };
                        },
                      },
                    ],
                  },
                  {
                    path: "settings",
                    lazy: async () => {
                      const { ManageClassesSettingsPage } = await import(
                        "@/components/classes/ManageClassesSettingsPage/ManageClassesSettingsPage"
                      );
                      return { Component: ManageClassesSettingsPage };
                    },
                    handle: {
                      selectedMenuKey: "admin/classes/settings",
                    },
                  },
                ],
              },
              {
                path: "workouts",
                handle: {
                  selectedMenuKey: "admin/workouts",
                },
                children: [
                  {
                    path: "planning",
                    handle: {
                      selectedMenuKey: "admin/workouts/planning",
                    },
                    children: [
                      {
                        index: true,
                        lazy: async () => {
                          const { WorkoutPlanningPage } = await import(
                            "@/components/workouts/WorkoutPlanningPage/WorkoutsPlanningPage"
                          );
                          return { Component: WorkoutPlanningPage };
                        },
                      },
                      {
                        path: "create",
                        lazy: async () => {
                          const { CreateWorkoutOccurrencePage } = await import(
                            "@/components/workouts/CreateWorkoutOccurrencePage/CreateWorkoutOccurrencePage"
                          );
                          return { Component: CreateWorkoutOccurrencePage };
                        },
                      },
                    ],
                  },
                  {
                    path: "featured",
                    handle: {
                      selectedMenuKey: "admin/workouts/featured",
                    },
                    children: [
                      {
                        index: true,
                        lazy: async () => {
                          const { ManageWorkoutsPage } = await import(
                            "@/components/workouts/ManageWorkoutsPage/ManageWorkoutsPage"
                          );
                          return { Component: ManageWorkoutsPage };
                        },
                      },
                      {
                        path: "create",
                        lazy: async () => {
                          const { CreateWorkoutPage } = await import(
                            "@/components/workouts/CreateWorkoutPage/CreateWorkoutPage"
                          );
                          return { Component: CreateWorkoutPage };
                        },
                      },
                      {
                        path: ":id",
                        lazy: async () => {
                          const { ManageWorkoutPage } = await import(
                            "@/components/workouts/ManageWorkoutPage/ManageWorkoutPage"
                          );
                          return { Component: ManageWorkoutPage };
                        },
                      },
                    ],
                  },
                  {
                    path: "categories",
                    handle: {
                      selectedMenuKey: "admin/workouts/categories",
                    },
                    children: [
                      {
                        index: true,
                        lazy: async () => {
                          const { ManageWorkoutCategoriesPage } = await import(
                            "@/components/workouts/ManageWorkoutCategoriesPage/ManageWorkoutCategoriesPage"
                          );
                          return { Component: ManageWorkoutCategoriesPage };
                        },
                      },
                      {
                        path: "create",
                        lazy: async () => {
                          const { CreateWorkoutCategoryPage } = await import(
                            "@/components/workouts/CreateWorkoutCategoryPage/CreateWorkoutCategoryPage"
                          );
                          return { Component: CreateWorkoutCategoryPage };
                        },
                      },
                      {
                        path: ":id",
                        lazy: async () => {
                          const { ManageWorkoutCategoryPage } = await import(
                            "@/components/workouts/ManageWorkoutCategoryPage/ManageWorkoutCategoryPage"
                          );
                          return { Component: ManageWorkoutCategoryPage };
                        },
                      },
                      {
                        path: ":id/create",
                        lazy: async () => {
                          const { CreateWorkoutCategoryPage } = await import(
                            "@/components/workouts/CreateWorkoutCategoryPage/CreateWorkoutCategoryPage"
                          );
                          return { Component: CreateWorkoutCategoryPage };
                        },
                      },
                    ],
                  },
                  {
                    path: "score-categories",
                    handle: {
                      selectedMenuKey: "admin/workouts/score-categories",
                    },
                    children: [
                      {
                        index: true,
                        lazy: async () => {
                          const { ManageScoreCategoriesPage } = await import(
                            "@/components/workouts/ManageScoreCategoriesPage/ManageScoreCategoriesPage"
                          );
                          return { Component: ManageScoreCategoriesPage };
                        },
                      },
                      {
                        path: "new",
                        lazy: async () => {
                          const { CreateScoreCategoryPage } = await import(
                            "@/components/workouts/CreateScoreCategoryPage/CreateScoreCategoryPage"
                          );
                          return { Component: CreateScoreCategoryPage };
                        },
                      },
                      {
                        path: ":id",
                        lazy: async () => {
                          const { ManageScoreCategoryPage } = await import(
                            "@/components/workouts/ManageScoreCategoryPage/ManageScoreCategoryPage"
                          );
                          return { Component: ManageScoreCategoryPage };
                        },
                      },
                    ],
                  },
                  {
                    path: "settings",
                    lazy: async () => {
                      const { ManageWorkoutsSettingsPage } = await import(
                        "@/components/workouts/ManageWorkoutsSettingsPage/ManageWorkoutsSettingsPage"
                      );
                      return { Component: ManageWorkoutsSettingsPage };
                    },
                    handle: {
                      selectedMenuKey: "admin/workouts/settings",
                    },
                  },
                  {
                    path: ":id",
                    lazy: async () => {
                      const { ManageWorkoutPage } = await import(
                        "@/components/workouts/ManageWorkoutPage/ManageWorkoutPage"
                      );
                      return { Component: ManageWorkoutPage };
                    },
                    handle: {
                      selectedMenuKey: "admin/workouts",
                    },
                  },
                ],
              },
              {
                path: "spaces/create",
                lazy: async () => {
                  const { CreateSpacePage } = await import(
                    "@/components/spaces/CreateSpacePage/CreateSpacePage"
                  );
                  return { Component: CreateSpacePage };
                },
                handle: {
                  selectedMenuKey: "admin/spaces",
                },
              },
              {
                path: "spaces/:id",
                lazy: async () => {
                  const { ManageSpacePage } = await import(
                    "@/components/spaces/ManageSpacePage/ManageSpacePage"
                  );
                  return { Component: ManageSpacePage };
                },
                handle: {
                  selectedMenuKey: "admin/spaces",
                },
              },
              {
                path: "spaces",
                lazy: async () => {
                  const { ManageSpacesPage } = await import(
                    "@/components/spaces/ManageSpacesPage/ManageSpacesPage"
                  );
                  return { Component: ManageSpacesPage };
                },
                handle: {
                  selectedMenuKey: "admin/spaces",
                },
              },
              {
                path: "finance",
                handle: {
                  selectedMenuKey: "admin/finance",
                },
                children: [
                  {
                    path: "payments",
                    children: [
                      {
                        index: true,
                        lazy: async () => {
                          const { ManagePaymentsPage } = await import(
                            "@/components/finance/ManagePaymentsPage/ManagePaymentsPage"
                          );
                          return { Component: ManagePaymentsPage };
                        },
                      },
                      {
                        path: "new",
                        lazy: async () => {
                          const { CreatePaymentPage } = await import(
                            "@/components/finance/CreatePaymentPage/CreatePaymentPage"
                          );
                          return { Component: CreatePaymentPage };
                        },
                      },
                      {
                        path: ":id",
                        lazy: async () => {
                          const { ManagePaymentPage } = await import(
                            "@/components/finance/ManagePaymentPage/ManagePaymentPage"
                          );
                          return { Component: ManagePaymentPage };
                        },
                      },
                    ],
                    handle: {
                      selectedMenuKey: "admin/finance/payments",
                    },
                  },
                  {
                    path: "expenses",
                    children: [
                      {
                        index: true,
                        lazy: async () => {
                          const { ManageExpensesPage } = await import(
                            "@/components/finance/ManageExpensesPage/ManageExpensesPage"
                          );
                          return { Component: ManageExpensesPage };
                        },
                      },
                      {
                        path: "new",
                        lazy: async () => {
                          const { CreateExpensePage } = await import(
                            "@/components/finance/CreateExpensePage/CreateExpensePage"
                          );
                          return { Component: CreateExpensePage };
                        },
                      },
                      {
                        path: ":id",
                        lazy: async () => {
                          const { ManageExpensePage } = await import(
                            "@/components/finance/ManageExpensePage/ManageExpensePage"
                          );
                          return { Component: ManageExpensePage };
                        },
                      },
                    ],
                    handle: {
                      selectedMenuKey: "admin/finance/expenses",
                    },
                  },
                  {
                    path: "direct-debits",
                    children: [
                      {
                        path: "create-collection",
                        lazy: async () => {
                          const { CreateCollectionPage } = await import(
                            "@/components/finance/CreateCollectionPage/CreateCollectionPage"
                          );
                          return { Component: CreateCollectionPage };
                        },
                      },
                      {
                        path: ":tab?",
                        lazy: async () => {
                          const { ManageDirectDebitsPage } = await import(
                            "@/components/finance/ManageDirectDebitsPage/ManageDirectDebitsPage"
                          );
                          return { Component: ManageDirectDebitsPage };
                        },
                      },
                    ],
                  },
                  {
                    path: "settings/:tab?",
                    lazy: async () => {
                      const { FinanceSettingsPage } = await import(
                        "@/components/finance/FinanceSettingsPage/FinanceSettingsPage"
                      );
                      return { Component: FinanceSettingsPage };
                    },
                    handle: {
                      selectedMenuKey: "admin/finance/settings",
                    },
                  },
                ],
              },
              {
                path: "content-packs",
                handle: {
                  selectedMenuKey: "admin/tenant/content-packs",
                },
                children: [
                  {
                    index: true,
                    lazy: async () => {
                      const { ManageContentPacksPage } = await import(
                        "@/components/tenant/ManageContentPacksPage/ManageContentPacksPage"
                      );
                      return { Component: ManageContentPacksPage };
                    },
                  },
                ],
              },
              {
                path: "tenants",
                handle: {
                  selectedMenuKey: "admin/tenants",
                },
                children: [
                  {
                    index: true,
                    lazy: async () => {
                      const { ManageTenantsPage } = await import(
                        "@/components/tenant/ManageTenantsPage/ManageTenantsPage"
                      );
                      return { Component: ManageTenantsPage };
                    },
                  },
                  {
                    path: "create",
                    lazy: async () => {
                      const { CreateTenantPage } = await import(
                        "@/components/tenant/CreateTenantPage/CreateTenantPage"
                      );
                      return { Component: CreateTenantPage };
                    },
                  },
                ],
              },
              {
                path: "forms/new",
                lazy: async () => {
                  const { CreateFormPage } = await import(
                    "@/components/tenant/CreateFormPage/CreateFormPage"
                  );
                  return { Component: CreateFormPage };
                },
                handle: {
                  selectedMenuKey: "admin/tenant/forms",
                },
              },
              {
                path: "forms/:id",
                lazy: async () => {
                  const { ManageFormPage } = await import(
                    "@/components/tenant/ManageFormPage/ManageFormPage"
                  );
                  return { Component: ManageFormPage };
                },
                handle: {
                  selectedMenuKey: "admin/tenant/forms",
                },
              },
              {
                path: "forms",
                lazy: async () => {
                  const { ManageFormsPage } = await import(
                    "@/components/tenant/ManageFormsPage/ManageFormsPage"
                  );
                  return { Component: ManageFormsPage };
                },
                handle: {
                  selectedMenuKey: "admin/tenant/forms",
                },
              },
              {
                path: "plans",
                handle: {
                  selectedMenuKey: "admin/plans",
                },
                children: [
                  {
                    index: true,
                    lazy: async () => {
                      const { ManagePlanGroupsPage } = await import(
                        "@/components/plans/ManagePlanGroupsPage/ManagePlanGroupsPage"
                      );
                      return { Component: ManagePlanGroupsPage };
                    },
                  },
                  {
                    path: "new",
                    lazy: async () => {
                      const { CreatePlanGroupPage } = await import(
                        "@/components/plans/CreatePlanGroupPage/CreatePlanGroupPage"
                      );
                      return { Component: CreatePlanGroupPage };
                    },
                  },
                  {
                    path: ":groupId",
                    children: [
                      {
                        index: true,
                        lazy: async () => {
                          const { ManagePlanGroupPage } = await import(
                            "@/components/plans/ManagePlanGroupPage/ManagePlanGroupPage"
                          );
                          return { Component: ManagePlanGroupPage };
                        },
                      },
                      {
                        path: "options/new",
                        lazy: async () => {
                          const { CreatePlanPage } = await import(
                            "@/components/plans/CreatePlanPage/CreatePlanPage"
                          );
                          return { Component: CreatePlanPage };
                        },
                      },
                      {
                        path: "options",
                        lazy: async () => {
                          const { ManagePlansPage } = await import(
                            "@/components/plans/ManagePlansPage/ManagePlansPage"
                          );
                          return { Component: ManagePlansPage };
                        },
                      },
                      {
                        path: "options/:id",
                        lazy: async () => {
                          const { ManagePlanPage } = await import(
                            "@/components/plans/ManagePlanPage/ManagePlanPage"
                          );
                          return { Component: ManagePlanPage };
                        },
                      },
                    ],
                  },
                ],
              },
              {
                path: "tenant",
                handle: {
                  selectedMenuKey: "admin/tenant",
                },
                children: [
                  {
                    path: "settings/:tab?",
                    lazy: async () => {
                      const { ManageTenantPage } = await import(
                        "@/components/tenant/ManageTenantPage/ManageTenantPage"
                      );
                      return { Component: ManageTenantPage };
                    },
                  },
                ],
              },
              {
                path: "invoicing",
                lazy: async () => {
                  const { InvoicingConfigurationPage } = await import(
                    "@/components/tenant/InvoicingConfigurationPage/InvoicingConfigurationPage"
                  );
                  return { Component: InvoicingConfigurationPage };
                },
              },
            ],
          },
          {
            path: "membership",
            lazy: async () => {
              const { MembershipPage } = await import(
                "@/components/user/MembershipPage/MembershipPage"
              );
              return { Component: MembershipPage };
            },
            handle: {
              selectedMenuKey: "home",
            },
          },
          {
            path: "notifications",
            lazy: async () => {
              const { NotificationsPage } = await import(
                "@/components/user/NotificationsPage/NotificationsPage"
              );
              return { Component: NotificationsPage };
            },
            handle: {
              selectedMenuKey: "notifications",
            },
          },
          {
            path: "plans",
            handle: {
              selectedMenuKey: "home",
            },
            children: [
              {
                index: true,
                lazy: async () => {
                  const { PlansPage } = await import(
                    "@/components/user/PlansPage/PlansPage"
                  );
                  return { Component: PlansPage };
                },
              },
              {
                path: "choose/:id?",
                lazy: async () => {
                  const { ChoosePlanPage } = await import(
                    "@/components/plans/ChoosePlanPage/ChoosePlanPage"
                  );
                  return { Component: ChoosePlanPage };
                },
              },
              {
                path: "pay/:id",
                lazy: async () => {
                  const { PayPlanPage } = await import(
                    "@/components/plans/PayPlanPage/PayPlanPage"
                  );
                  return { Component: PayPlanPage };
                },
              },
            ],
          },
          {
            path: "profile/:tab?",
            lazy: async () => {
              const { ProfilePage } = await import(
                "@/components/user/ProfilePage/ProfilePage"
              );
              return { Component: ProfilePage };
            },
            handle: {
              selectedMenuKey: "profile",
            },
          },
          {
            path: "payments",
            handle: {
              selectedMenuKey: "payments",
            },
            children: [
              {
                path: ":tab?",
                lazy: async () => {
                  const { PaymentsPage } = await import(
                    "@/components/user/PaymentsPage/PaymentsPage"
                  );
                  return { Component: PaymentsPage };
                },
              },
              {
                path: "methods",
                children: [
                  {
                    path: ":id",
                    lazy: async () => {
                      const { PaymentMethodPage } = await import(
                        "@/components/user/PaymentMethodPage/PaymentMethodPage"
                      );
                      return { Component: PaymentMethodPage };
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "profile/complete",
        lazy: async () => {
          const { ProfileCompletionPage } = await import(
            "@/components/user/ProfileCompletionPage/ProfileCompletionPage"
          );
          return { Component: ProfileCompletionPage };
        },
        handle: {
          hideMenu: true,
        },
      },
      {
        path: "authentication/*",
        Component: () => <Loading />,
      },
      {
        path: "permalink",
        Component: () => {
          const [searchParams] = useSearchParams();

          if (searchParams) {
            const search = location.search.substring(1);
            const params = JSON.parse(
              '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
              function (key, value) {
                return key === "" ? value : decodeURIComponent(value);
              }
            );
            return <Navigate to={generateLink(params)} replace />;
          }

          return <Navigate to="/" replace />;
        },
      },
    ],
  },
];

const router = createBrowserRouter(routes);

export const Router = () => <RouterProvider router={router} />;
