import { createContext } from "react";
import { notification } from "antd";

export type GlobalNotificationContext = ReturnType<
  (typeof notification)["useNotification"]
>[0];

export const GlobalNotificationContext =
  createContext<GlobalNotificationContext | null>(null);

interface GlobalNotificationProviderProps {
  children: React.ReactNode;
}

export function GlobalNotificationProvider({
  children,
}: GlobalNotificationProviderProps) {
  const [api, contextHolder] = notification.useNotification();

  return (
    <GlobalNotificationContext.Provider value={api}>
      {contextHolder}
      {children}
    </GlobalNotificationContext.Provider>
  );
}
