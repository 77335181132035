import { createContext, useEffect, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { datadogRum } from "@datadog/browser-rum";
import { useLocalStorageState } from "ahooks";

import { gql } from "@/__generated__";
import type { TenantProviderQueryQuery } from "@/__generated__/graphql";
import { config } from "@/config";

import { useApolloProvider } from "../ApolloProvider/hooks";
import { useConfig } from "../ConfigProvider/hooks";

export class TenantNotFoundError extends Error {
  constructor() {
    super("Tenant not found");
  }
}

export interface TenantContext {
  tenant: TenantProviderQueryQuery["currentTenant"];
  set: (tenant: string) => void;
  reload: () => void;
}

export const TenantContext = createContext<TenantContext | null>(null);

interface TenantProviderProps {
  children: React.ReactNode;
}

export function TenantProvider({ children }: TenantProviderProps) {
  const { setConfig } = useConfig();
  const [activeTenant, setActiveTenant] = useLocalStorageState<string>(
    "tenant",
    { defaultValue: "fitrack" }
  );

  const { setTenant } = useApolloProvider();

  const {
    data: { currentTenant: tenant } = {},
    loading,
    refetch,
  } = useQuery(
    gql(`
      query TenantProviderQuery {
        currentTenant {
          id
          name
          slug
          logoUrl
          languages
          defaultLanguage
          inAppInvoices
          inAppPaymentsEnabled
          hasBookings
          hasPlans
          brandColor
          timezone
          classTypes {
            id
            name
            color
          }
          locations {
            id
            name
          }
        }
      }
    `),
    {}
  );

  const value = useMemo(
    () => ({
      tenant,
      set: setActiveTenant,
      reload: refetch,
    }),
    [refetch, setActiveTenant, tenant]
  );

  useEffect(() => {
    if (activeTenant) {
      if (activeTenant) {
        datadogRum.setGlobalContextProperty("tenant", {
          id: tenant?.id,
          name: tenant?.name,
        });
      }
      setTenant(activeTenant);
    }
  }, [activeTenant, setTenant, tenant?.id, tenant?.name]);

  useEffect(() => {
    setConfig({
      brandColor: tenant?.brandColor || config.brandColor,
    });
  }, [setConfig, tenant]);

  if (loading) {
    return null;
  }

  if (!tenant) {
    if (activeTenant === config.defaultTenant) {
      throw new Error();
    } else {
      setActiveTenant(config.defaultTenant);
    }
  }

  return (
    <TenantContext.Provider value={value as TenantContext}>
      {children}
    </TenantContext.Provider>
  );
}
