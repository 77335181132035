import { createContext, useEffect, useMemo, useState } from "react";
import type { Session } from "@supabase/supabase-js";

import { supabase } from "@/supabase";

interface SessionProviderProps {
  children: React.ReactNode;
}

export type SessionContext = {
  session?: Session | null;
  logout: () => void;
  reload: () => void;
};

export const SessionContext = createContext<SessionContext | null>(null);

export function SessionProvider({ children }: SessionProviderProps) {
  const [session, setSession] = useState<Session | null>();

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
    return () => subscription.unsubscribe();
  }, []);

  const value = useMemo(
    () => ({
      session,
      logout: () => {
        localStorage.clear();
        return supabase.auth.signOut();
      },
      reload: () =>
        supabase.auth.refreshSession({
          refresh_token: session?.refresh_token ?? "",
        }),
    }),
    [session]
  );

  if (session === undefined) {
    return null;
  }

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
}
