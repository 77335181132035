import { type ComponentProps, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useRouteError } from "react-router-dom";
import { Button, Result } from "antd";

import { isForbiddenError, isNotFoundError } from "@/utils/error";

import { ForbiddenError } from "../apps/errors/ForbiddenError";
import { NotFoundError } from "../apps/errors/NotFoundError";

export class AuthenticationFailed extends Error {}

const ErrorBoundary = () => {
  const error = useRouteError();

  if (import.meta.env.DEV) {
    console.error(error);
  }

  const navigate = useNavigate();
  const intl = useIntl();
  const [redirectTo, setRedirectTo] = useState<string>();

  let title = "";
  let subtitle: ComponentProps<typeof Result>["subTitle"] = "";

  useEffect(() => {
    if (redirectTo) {
      navigate(redirectTo);
    }
  }, [navigate, redirectTo]);

  // @ts-ignore
  switch (error?.response?.status) {
    case 401:
      // @ts-ignore
      if (error.response?.data.message === "MembershipIncomplete") {
        setRedirectTo("/profile/complete");
      }

      title = intl.formatMessage({ defaultMessage: "Not authorized" });
      subtitle = "You are not authorized to see this page";
      break;
    case 404:
      title = "Not found";
      subtitle = "The page you tried to open doesn't exist";
      break;
    default:
      title = "Oops";
      subtitle = <FormattedMessage defaultMessage="Something went wrong" />;
  }

  if (isForbiddenError(error)) {
    return <ForbiddenError />;
  }

  if (isNotFoundError(error)) {
    return <NotFoundError />;
  }

  const refresh = () => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (const registration of registrations) {
          registration.unregister();
        }
      });
    }

    if ("caches" in window) {
      caches.keys().then((keyList) => {
        return Promise.all(
          keyList.map((key) => {
            return caches.delete(key);
          })
        );
      });
    }
    setTimeout(() => {
      window.location.pathname = "/";
    }, 1000);
  };

  return (
    <Result
      status="error"
      title={title}
      subTitle={subtitle}
      extra={[
        <Button key="home" onClick={refresh}>
          <FormattedMessage defaultMessage="Go Home" />
        </Button>,
      ]}
    />
  );
};

export default ErrorBoundary;
