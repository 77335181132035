import { message, Tooltip } from "antd";
import type { TooltipPropsWithTitle } from "antd/es/tooltip";

import { useAgent } from "@/hooks/app.hooks";
import { isStringOrReactNode } from "@/utils/types";

interface FitTooltipProps extends Omit<TooltipPropsWithTitle, "title"> {
  title?: TooltipPropsWithTitle["title"];
  responsive?: boolean;
}

export const FitTooltip = ({
  responsive = true,
  title,
  ...props
}: FitTooltipProps) => {
  const [messageApi, contextHolder] = message.useMessage();

  const { isMobile } = useAgent();

  if (responsive && isMobile && isStringOrReactNode(title)) {
    return (
      <div
        onContextMenu={(e) => {
          messageApi.open({
            content: title,
            duration: 2,
          });
          return e.preventDefault();
        }}
      >
        {contextHolder}
        {props.children}
      </div>
    );
  }
  return <Tooltip {...props} title={title} />;
};
