import type { ButtonProps } from "antd";
import { Button } from "antd";

import { useAgent } from "@/hooks/app.hooks";

export function ResponsiveButton(props: ButtonProps) {
  const breakpoints = useAgent();

  return <Button block={breakpoints.isMobile} {...props} />;
}
