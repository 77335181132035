import { useEffect, useState } from "react";

import "./preview-banner.less";

interface VersionInfo {
  version: string;
}

function usePreview() {
  const [isLoading, setIsLoading] = useState(true);
  const [versionInfo, setVersionInfo] = useState<VersionInfo>();

  useEffect(() => {
    function loadVersionInfo() {
      fetch("/version.json")
        .then((response) => response.json())
        .then((versionInfo) => {
          setVersionInfo(versionInfo);
          setIsLoading(false);
        });
    }

    const previewQueryParam = new URLSearchParams(window.location.search).get(
      "_preview"
    );

    if (previewQueryParam) {
      document.cookie = `_preview=${previewQueryParam}; path=/`;
    }

    const previewCookieValue = document.cookie
      .split(";")
      .find((cookie) => cookie.includes("_preview="))
      ?.split("=")[1];

    if (previewCookieValue) {
      loadVersionInfo();
    } else {
      setIsLoading(false);
    }
  }, []);

  return {
    isLoading,
    isPreview: !!versionInfo,
    versionInfo,
  };
}

export function PreviewBanner() {
  const { isLoading, versionInfo } = usePreview();

  if (isLoading || !versionInfo) {
    return null;
  }

  return (
    <div className="preview-banner">
      <p className="preview-banner__text">Preview: {versionInfo.version}</p>
    </div>
  );
}
