import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Outlet, useLocation } from "react-router-dom";
import {
  AppstoreOutlined,
  HomeOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useLocalStorageState } from "ahooks";
import {
  Badge,
  Col,
  Flex,
  Layout as AntLayout,
  Modal,
  notification,
  Row,
  theme,
} from "antd";

import { FitButton } from "@/components/common/FitButton/FitButton";
import { FitDrawer } from "@/components/common/FitDrawer/FitDrawer";
import { FitImage } from "@/components/common/FitImage/FitImage";
import { usePushNotification } from "@/components/common/NotificationProvider/hooks";
import { Menu } from "@/components/Menu/Menu";
import { useAgent } from "@/hooks/app.hooks";
import { useFooter } from "@/providers/FooterProvider/hooks";

import { useConfig } from "../common/ConfigProvider/hooks";
import { useGlobalNotification } from "../common/GlobalNotificationProvider/hooks";
import { useTenant } from "../common/TenantProvider/hooks";
import { useUser } from "../common/UserProvider/hooks";

interface LayoutProps {
  children?: React.ReactNode;
  navigation?: boolean;
}

const AndroidAppMessage = () => {
  const [dismissed, setDismissed] = useLocalStorageState(
    "androidAppMessageDismissed",
    {
      defaultValue: false,
    }
  );
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (!dismissed) {
      api.info({
        message: (
          <FormattedMessage defaultMessage="Android app is available for you" />
        ),
        description: (
          <Flex gap="small" vertical>
            <FormattedMessage
              defaultMessage="Install our Android app for a better experience."
              tagName="p"
            />
            <FitButton
              type="primary"
              href="https://play.google.com/store/apps/details?id=app.fitrack"
              target="_blank"
              block
            >
              <FormattedMessage defaultMessage="Install" />
            </FitButton>
            <FitButton
              type="link"
              onClick={() => {
                setDismissed(true);
                api.destroy("androidAppMessage");
              }}
            >
              <FormattedMessage defaultMessage="Not interested" />
            </FitButton>
          </Flex>
        ),
        duration: 0,
        closable: false,
        key: "androidAppMessage",
      });
    }
  }, [api, dismissed, setDismissed]);

  return contextHolder;
};

export const Layout = ({ children }: LayoutProps) => {
  const { unread } = usePushNotification();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const { isDesktop, isMobile, isAndroid, isApp } = useAgent();
  const { config } = useConfig();
  const { token } = theme.useToken();
  const { tenant } = useTenant();
  const { user } = useUser();
  const { footerRef, hasFooter } = useFooter();

  const notification = useGlobalNotification();
  const { onMessage } = usePushNotification();

  useEffect(() => {
    Modal.destroyAll();
  }, [location.pathname]);

  onMessage((payload) => {
    notification.info({
      message: payload.notification?.title,
      description: payload.notification?.body,
    });
  });

  return (
    <AntLayout
      className="layout"
      style={{
        padding:
          "env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)",
        paddingBottom: isMobile
          ? "calc(58px + env(safe-area-inset-bottom))"
          : undefined,
      }}
    >
      {isAndroid && !isApp && user.isAndroidTester && <AndroidAppMessage />}
      {!isDesktop && (
        <FitDrawer
          open={menuOpen || isDesktop}
          placement="bottom"
          onClose={() => setMenuOpen(false)}
          styles={{ body: { padding: 0 } }}
          width={config.drawerWidth}
          mask={true}
          push={false}
          closable={false}
        >
          <Menu onSelect={() => setMenuOpen(false)} />
        </FitDrawer>
      )}
      <AntLayout className="site-layout">
        {isDesktop && (
          <AntLayout.Sider
            theme="light"
            style={{
              borderRight: "1px solid var(--ant-color-border)",
              position: "fixed",
              zIndex: 200,
            }}
            width={config.drawerWidth}
          >
            <Row
              style={{
                flexDirection: "column",
                height: "100vh",
                overflow: "auto",
              }}
              wrap={false}
            >
              <Menu onSelect={() => setMenuOpen(false)} />
            </Row>
          </AntLayout.Sider>
        )}
        <AntLayout
          style={{
            paddingLeft: isDesktop ? config.drawerWidth : undefined,
          }}
        >
          <AntLayout.Content
            className="site-layout-background"
            style={{
              padding: "var(--ant-padding-md) var(--ant-padding-xs)",
            }}
          >
            <Flex justify="center">
              <Row>
                <Col style={{ width: token.screenLG }}>
                  {children ?? <Outlet />}
                </Col>
              </Row>
            </Flex>
          </AntLayout.Content>
          {hasFooter ? (
            <div
              style={{
                backgroundColor: "var(--ant-color-bg-elevated)",
                borderTop: "1px solid var(--ant-color-border)",
                padding:
                  "var(--ant-padding-xs) 0 calc(var(--ant-padding-xs) + env(safe-area-inset-bottom)) 0",
                position: "fixed",
                bottom: 0,
                left: 0,
                width: "100%",
                zIndex: 200,
              }}
              ref={footerRef}
            />
          ) : (
            <div ref={footerRef} />
          )}
          {isMobile && (
            <AntLayout.Header
              style={{
                position: "fixed",
                bottom: 0,
                left: 0,
                zIndex: 100,
                width: "100%",
                padding:
                  "var(--ant-padding-xs) 0 calc(var(--ant-padding-xs) + env(safe-area-inset-bottom)) 0",
                height: "unset",
                backgroundColor: "var(--ant-color-bg-elevated)",
                boxShadow: "0 -6px 16px 0 var(--ant-color-fill-tertiary)",
              }}
            >
              <Flex
                align="center"
                justify="space-around"
                style={{ height: "100%" }}
              >
                <Flex
                  vertical
                  justify="center"
                  align="center"
                  flex={1}
                  style={{ lineHeight: 1 }}
                >
                  <FitButton
                    type="text"
                    navigate="/"
                    style={{
                      height: "unset",
                      padding: "var(--ant-padding-xs)",
                    }}
                  >
                    <FitImage
                      src={tenant.logoUrl}
                      preview={false}
                      style={{
                        height: "var(--ant-font-size-heading-2)",
                        borderRadius: "var(--ant-border-radius)",
                        border: "1px solid var(--ant-color-border)",
                        width: "unset",
                      }}
                      fallbackElement={
                        <HomeOutlined
                          style={{ fontSize: "var(--ant-font-size-heading-2)" }}
                        />
                      }
                    />
                  </FitButton>
                  <FormattedMessage defaultMessage="Home" tagName="span" />
                </Flex>
                <Flex
                  vertical
                  justify="center"
                  align="center"
                  flex={1}
                  style={{ lineHeight: 1 }}
                >
                  <Badge count={unread}>
                    <FitButton
                      type="text"
                      onClick={() => setMenuOpen(true)}
                      style={{
                        height: "unset",
                        padding: "var(--ant-padding-xs)",
                      }}
                    >
                      <AppstoreOutlined
                        onClick={() => setMenuOpen(true)}
                        style={{ fontSize: "var(--ant-font-size-heading-2)" }}
                      />
                    </FitButton>
                  </Badge>
                  <FormattedMessage defaultMessage="Menu" tagName="span" />
                </Flex>
                <Flex
                  vertical
                  justify="center"
                  align="center"
                  flex={1}
                  style={{ lineHeight: 1 }}
                >
                  <FitButton
                    type="text"
                    navigate="/profile"
                    style={{
                      height: "unset",
                      padding: "var(--ant-padding-xs)",
                    }}
                  >
                    <FitImage
                      src={user.avatarUrl}
                      preview={false}
                      style={{
                        height: "var(--ant-font-size-heading-2)",
                        borderRadius: "var(--ant-border-radius)",
                        border: "1px solid var(--ant-color-border)",
                        width: "unset",
                      }}
                      fallbackElement={
                        <UserOutlined
                          style={{ fontSize: "var(--ant-font-size-heading-2)" }}
                        />
                      }
                    />
                  </FitButton>
                  <FormattedMessage defaultMessage="Profile" tagName="span" />
                </Flex>
              </Flex>
            </AntLayout.Header>
          )}
        </AntLayout>
      </AntLayout>
    </AntLayout>
  );
};
