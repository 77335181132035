import { useCallback, useState } from "react";
import { useMount } from "ahooks";
import type { FormItemProps } from "antd";
import { Flex, Form, Switch } from "antd";

interface SmartFormItemProps extends FormItemProps {
  optional?: boolean;
  optionalValue?: unknown;
}

export const SmartFormItem = ({
  optional,
  optionalValue = null,
  ...props
}: SmartFormItemProps) => {
  const form = Form.useFormInstance();
  const value = form.getFieldValue(props.name);
  const [optionalChecked, setOptionalChecked] = useState(
    value !== optionalValue
  );

  const onSwitchChange = useCallback((checked: boolean) => {
    setOptionalChecked(checked);
  }, []);

  const internalProps: SmartFormItemProps = {};

  if (optional) {
    internalProps.label = (
      <Flex gap="small">
        {props.label}
        <Switch checked={optionalChecked} onChange={onSwitchChange} />
      </Flex>
    );
    internalProps.required = true;
    if (!optionalChecked) {
      internalProps.wrapperCol = { span: 0 };
    }
  }

  return (
    <Form.Item {...props} {...internalProps}>
      {optionalChecked ? (
        props.children
      ) : (
        <OptionalValue optionalValue={optionalValue} />
      )}
    </Form.Item>
  );
};

const OptionalValue = ({
  value,
  optionalValue,
  onChange,
}: {
  value?: unknown;
  optionalValue?: unknown;
  onChange?: (value: unknown) => void;
}) => {
  useMount(() => {
    if (value !== optionalValue) {
      onChange?.(optionalValue);
    }
  });

  return null;
};
