import { useRef, useState } from "react";
import type { DrawerProps } from "antd";
import { Drawer } from "antd";

import { useAgent } from "@/hooks/app.hooks";
import { FooterProvider } from "@/providers/FooterProvider/FooterProvider";
import { useFooter } from "@/providers/FooterProvider/hooks";

export interface FitDrawerProps extends DrawerProps {
  responsive?: boolean;
  trigger?: React.ReactNode;
}

const FitDrawerComponent = ({
  responsive = true,
  trigger,
  footer,
  ...props
}: FitDrawerProps) => {
  const { isMobile } = useAgent();
  const { footerRef, hasFooter } = useFooter();
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const internalProps: DrawerProps = responsive
    ? isMobile
      ? {
          height: "min-content",
          style: {
            maxHeight: "calc(100vh - calc(var(--ant-margin-xxl) * 2))",
            display: "flex",
          },
          placement: props.placement ?? "bottom",
        }
      : {
          width: props.width ?? "60vw",
        }
    : {
        placement: props.placement,
      };

  const drawer = (
    <Drawer
      open={isOpen}
      onClose={() => setIsOpen(false)}
      drawerRender={(container) => (
        <div
          ref={ref}
          style={
            !internalProps.placement ||
            internalProps.placement === "left" ||
            internalProps.placement === "right"
              ? {
                  display: "flex",
                  flexDirection: "row",
                  height: "100%",
                }
              : undefined
          }
        >
          {container}
        </div>
      )}
        styles={{
          ...props.styles,
          content: {
            paddingTop:
              internalProps.placement === "bottom"
                ? undefined
                : "env(safe-area-inset-top)",
            paddingRight:
              internalProps.placement === "left"
                ? undefined
                : "env(safe-area-inset-right)",
            paddingBottom:
              internalProps.placement === "top"
                ? undefined
                : "env(safe-area-inset-bottom)",
            paddingLeft:
              internalProps.placement === "right"
                ? undefined
                : "env(safe-area-inset-left)",
            ...props.styles?.content,
          },
        }}
      {...internalProps}
      {...props}
      style={{
        ...internalProps.style,
        ...props.style,
      }}
      children={props.children}
      footer={footer ?? (hasFooter ? <div ref={footerRef} /> : undefined)}
    />
  );

  if (trigger) {
    return (
      <>
        <div onClick={(e) => e.stopPropagation()}>{drawer}</div>
        <div
          onClick={() => {
            return setIsOpen(!isOpen);
          }}
        >
          {trigger}
        </div>
      </>
    );
  }

  return drawer;
};

export const FitDrawer = (props: FitDrawerProps) => (
  <FooterProvider>
    <FitDrawerComponent {...props} />
  </FooterProvider>
);
