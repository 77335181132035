import { useContext } from "react";

import { ApolloContext, type ApolloProviderContext } from "./ApolloProvider";

export const useApolloProvider = (): ApolloProviderContext => {
  const apolloProviderContext = useContext<ApolloProviderContext | null>(
    ApolloContext
  );

  if (apolloProviderContext === undefined || apolloProviderContext === null) {
    throw new Error("useApolloProvider must be inside a ApolloProvider");
  }

  return apolloProviderContext;
};
